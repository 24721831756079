.p-message-warn-orange {
  display: block;
  border-radius: 15px;
  padding: 15px 10px;
  margin-top: 10px;
}

.p-message-warn-orange p {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 5px !important;
  color: #de9a5e;
}

.p-message-warn-risk {
  display: block;
  border-radius: 15px;
  padding: 15px 10px;
  margin-top: 10px;
}

.p-message-warn-risk p {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 5px !important;

  color: #a4132f;
}

.p-message-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.warn-button-risk {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
}
.do-not-dispense-tags {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
}
.warn-button-orange {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
}
.p-message-warn p {
  font-size: 14px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 8px;
}

.p-message-warn .item-description-orange {
  font-size: 14px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 8px;
  color: #de9a5e;
}

.p-message-warn-orange-checked {
  border-width: 1px 1px 1px 1px;

  padding: 10px;
  display: block;
  border-radius: 15px;
  margin-top: 10px;
}
.p-message-warn-risk-checked {
  border-width: 1px 1px 1px 1px;

  padding: 10px;
  display: block;
  border-radius: 15px;
  margin-top: 10px;
}
.warn-button-orange-checked {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
  background-color: #00ca69;
  text-transform: uppercase;
}
.warn-button-risk-checked {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
  background-color: #00ca69;
  text-transform: uppercase;
}
.p-message-warn-checked p {
  font-size: 14px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 8px;
}
