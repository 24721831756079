.modal-overlay {
  position: absolute;
  top: 14%;
  left: 22%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 5;
}
.x_mark {
  cursor: pointer;
}

@media screen and (min-width: 1300px) {
  .modal-overlay {
    top: 150px !important;
    left: 420px !important;
  }
}
.filter_actions {
  display: flex;
  justify-content: flex-end;
}
