/* Style for the user profile card */

.nms .content-div {
  padding: 10px;
  height: fit-content;
  padding-top: 0;
}

.content-div.patient-info-container {
  border-left: none;
  padding: 10px;
}

/* Style for the user's name */

/* Style for links */

.content-data {
  width: 100%;
  border-radius: 11px;
  background-color: #f2f2f2;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
}

.allergies-para {
  font-size: 12px;
  padding: 1px;
  margin: 0;
  margin-left: 10px;
}

.general-info {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px;

  p.card-inner-value {
    margin-top: -5px !important;
  }

  p.card-inner-value.phone-field {
    margin-top: -1px !important;
  }
}

.info-box {
  width: 100%;
  border-radius: 11px;
  height: 53px;
  padding: 6px 15px;
  display: flex;
  align-items: center;

  .MuiTextField-root {
    width: 100%;
  }

  span {
    width: 100%;
  }

  .d-flex {
    width: 100%;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }
}

.travel-info-box {
  width: 100%;
  border-radius: 11px;
  height: 53px;
  padding: 6px 15px;
  display: flex;
  align-items: center;

  .MuiTextField-root {
    width: 100%;
  }

  span {
    width: 100%;
  }

  .d-flex {
    width: 100%;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }
}

.info_input {
  .MuiInputLabel-root {
    line-height: 14px !important;
    font-size: 13x;
  }

  .MuiOutlinedInput-input {
    height: 7px !important;
  }
}

.allergies-box {
  width: 100%;
  border-radius: 11px;
  height: 47px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
}

.nms .card-heading {
  font-size: 16px !important;
  margin: 10px 0px 10px 0px;
}

.acc_heading {
  font-size: 14px;
}

.dose-drugs {
  background: #ffff;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 5px 0;
  display: flex;
  width: 100%;
  gap: 8px;
}

.drugs-data {
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

.t_h_panel {
  padding: 15px 0px;
}

.card-inner-value {
  font-size: 12px !important;
  line-height: 1rem;
  margin: 0;
}

.general-inner-boxes {
  padding: 5px !important;
}

.therapies-data {
  padding: 0 5px;
}

.patient-tab {
  .MuiButtonBase-root {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-weight: 400;
    text-transform: capitalize;
  }

  .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  .MuiBox-root {
    padding: 0;
  }

  .travel-info-box > span > p {
    margin: 0;
  }
}

.nms .action-footer {
  padding: 10px 30px;
  position: fixed;
  bottom: 0;
  z-index: 20;
}

.inline-boxes {
  display: flex;
  gap: 13px;
  margin: 9px 4px 0px 4px;
  height: 55px;
}

.action-footer-cont {
  display: flex;
  justify-content: right;
}
.ps-action-footer-cont {
  display: flex;
  justify-content: right;
  gap: 10px;
}
.nms-do-another,
.nms-scr,
.nms-pds-sync {
  transition: 0.2s linear;
  background-color: #00a3da !important;
}

.nms-do-another:hover,
.nms-scr:hover,
.nms-pds-sync:hover {
  transition: 0.2s linear;
  background-color: #0080ab !important;
}

.nms-complete {
  background-color: #eee !important;
  color: #999 !important;
  border: 1px solid #eee !important;
}

.patient-tab .action-footer {
  padding: 10px 0;
}

.inline-info {
  width: 100%;
  border-radius: 11px;
  padding: 3px 13px;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.general-inner-boxes .MuiPaper-root {
  /* background-color: #fff !important; */
}

/* .general-inner-boxes .acc-heading{
  background-color: #fff;
}
.general-inner-boxes .event-info{
  background-color: #fff !important;
} */

.description_container {
  margin-left: 30px;
  margin-right: 30px;

  textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    border: 1px solid #afafaf;
    border-radius: 10px;
    padding: 10px;
  }

  .notes_save_btn {
    display: flex;
    justify-content: flex-end;
  }
}

.activity_feed_stepper {
  h6 {
    font-size: 11px;
  }

  .patientName {
    color: #9e40e3;
    font-size: 11px;
    font-weight: 500;
  }

  .type {
    font-size: 13px;
  }
}

.pds_button {
  display: flex;
  justify-content: flex-end;
  padding: 0px 5px;
}

.genaral_pds {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.general-info.grids-2 {
  display: grid;
  grid-template-columns: auto auto;
}

.grids-2 {
  .info-box:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }
}

.grids-2 {
  .travel-info-box:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }
}