.patient-dashboard-details-area {
  height: calc(100% - 150px);
}

.patient-details-modal h3 {
  font-weight: 500;
}

.patient-dashboard-col-2 {
  flex: 2;
  padding: 300px 50px 15px 50px;
}

.tasks_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
}

.patient-detail-box {
  align-items: start;
}

.patient-detail-box:last-child {
  grid-column: span 3;
  width: 100%;
}

.patient-detail-box .box-label {
  display: block;
}

.patient-detail-box .box-label .patient-detail-label {
  font-weight: 600;
  margin-bottom: 5px;
}

.patient-detail-box .value {
  font-weight: 300;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.patient-detail-box:last-child .value {
  max-width: unset;
}

.allergies {
  display: flex;
  gap: 15px;
}

.allergies p {
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 600;
}

.contact-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-detail-box {
  border: 1px solid #eee;
  border-radius: 14px;
  padding: 16px;

  display: flex;
  justify-content: space-between;
  align-items: start;
}

.contact-detail-box .contact-label {
  font-weight: 600;
}
.contact-detail-box .contact-value {
  font-weight: 300;
}

.patient-detail-buttons {
  display: flex;
  gap: 5px;
  margin-top: 15px;
}

.patient-detail-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 8px 14px;
  border-radius: 10px;

  width: 100%;
}

.tasks-remove {
  background-color: white;
}

.tasks-remove p {
  color: #000;
}

.tasks-done {
  background-color: #000;
}

.tasks-done p,
.tasks-done svg {
  color: #fff;
}

.patient_name {
  margin-left: 5px !important;
}

.header_appointment {
  margin-bottom: unset;
  margin-bottom: 10px;
}

.patient-detail-appointments {
  position: relative;
}

.patient-detail-appointments .wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 0 0 35px;
}

.patient-detail-appointments .wrapper .appointment {
  padding: 15px 16px 15px 10px;
  box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s linear;
  position: relative;
}

.patient-detail-appointments .wrapper .appointment .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.patient-detail-appointments .wrapper .appointment .appointment-info .name {
  font-size: 16px;
  margin-bottom: 8px;
  max-width: unset;
}

.patient-detail-appointments
  .wrapper
  .appointment
  .appointment-info
  .dateandtime {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* .patient-detail-appointments .wrapper .appointment .appointment-info .date {
  background-color: white !important;
} */
.patient-detail-appointments .wrapper .appointment .appointment-info .time {
  font-size: 14px;
}

svg.beforeIcon {
  position: absolute;
  left: -45px;
  top: 25px;
}

.patient-detail-appointments .wrapper::before {
  content: "";
  width: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 30px;
  bottom: 30px;
}

.patient-detail-appointments .wrapper .status {
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.patient-detail-appointments .wrapper .status p {
  font-size: 12px;
  text-transform: capitalize;
}

.patient-detail-appointments .wrapper .appointment .right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.lifetimeValue_container {
  padding: 50px 0;
}

.lifetimeValue_attributes {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.lifetimeValue_attributes .box,
.lifetimeValue_chart {
  padding: 15px 16px 15px 10px;
  box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;

  border-radius: 16px;
  border: unset;

  transition: 0.2s linear;
  position: relative;
}

.lifetimeValue_attributes .box {
  height: unset;
  width: 100%;
}

.lifetimeValue_attributes .box .label {
  font-weight: 600;
  margin-bottom: 5px;
}

.lifetimeValue_chart {
  padding: 20px;
}

.lifetimeValue_content p {
  color: rgba(102, 102, 102, 1);
  margin-bottom: 20px;
}

.lifetimeValue_bottom {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.lifetimeValue_bottom .box-label {
  width: 100%;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 20px;
}

.lifetimeValue_bottom .box-label .count {
  background-color: black !important;
  color: #fff;
  width: 50px;
  height: 50px;
}

.summary_details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.summary_container .left,
.summary_container .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summary_header {
  justify-content: space-between;
}

.card-shaped-box {
  padding: 16px;
  box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
  border-radius: 16px;
  transition: 0.2s linear;

  min-height: 98px;
}

.card-shaped-content {
  margin-top: 10px;
}

.card-shaped-content .description {
  font-weight: 300;
}

.card-shaped-content .inner_container {
  margin-top: 10px;
  padding: 10px 12px;
  border-radius: 10px;
}

.card-shaped-content .inner_container .title {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.card-shaped-content .inner_container .pain,
.card-shaped-content .inner_container .calender {
  margin-top: 6px;
  font-weight: 300;
}

.card-shaped-content .inner_container .calender {
  display: flex;
  gap: 5px;
}

.card-shaped-content .inner_container .title.family_title {
  justify-content: unset;
  gap: 5px;
}

.card-shaped-content .inner_container .input_wrapper .top {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  margin-top: 10px;
}

.card-shaped-content .inner_container .input_wrapper .bottom {
  margin-top: 8px;
}

.card-shaped-content .inner_container .input_wrapper .top > div,
.card-shaped-content .inner_container .input_wrapper .bottom > div {
  border-radius: 10px;
  padding: 6px 8px;
  width: 100%;
  font-size: 12px;
}

.card-shaped-content .inner_container .input_wrapper .top > div .label,
.card-shaped-content .inner_container .input_wrapper .bottom > div .label {
  font-weight: 600;
  font-size: 12px;
}
.card-shaped-content .inner_container .input_wrapper .top > div .text,
.card-shaped-content .inner_container .input_wrapper .bottom > div .text {
  color: #999;
  font-weight: 300;
  font-size: 12px;
}

.in-store-money {
  background-color: #f2f2f2;
  border-radius: 30px;
  padding: 2px 5px;
}

.in-store-money p {
  font-size: 12px;
  font-weight: 600;
}

.in-store-tabs-wrapper .first,
.in-store-tabs-wrapper .second {
  margin: 15px 0;
}

.in-store-tabs-wrapper .day-date,
.in-store-tabs-wrapper .medicine-box .medicine-type {
  font-size: 12px;
  font-weight: 300;
}

.in-store-tabs-wrapper .medicine-box {
  margin-top: 10px;
  border-radius: 12px;
  padding: 10px;
}

.in-store-tabs-wrapper .medicine-box .medicine-title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.card-shaped-box .medications-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-shaped-box .medications-wrapper .status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-shaped-box .medications-wrapper .status .circle {
  width: 10px;
  height: 10px;
}

.card-shaped-box .medications-wrapper .status .circle.green {
  background-color: green;
}
.card-shaped-box .medications-wrapper .status .circle.red {
  background-color: red;
}

.card-shaped-box .three-cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.card-shaped-content .inner_container .title.medication-title {
  justify-content: unset;
  gap: 12px;
}

.card-shaped-content .inner_container .last-dispensed {
  font-size: 12px;
  font-weight: 300;
}

.appleHealth_container {
  padding: 50px 0;
}

.appleHealth_container .last_updated {
  border-radius: 30px;
  font-size: 12px;
  padding: 3px 10px;
}

.appleHealth_details {
  margin-top: 25px;
  display: flex;
  gap: 20px;
}

.appleHealth_details .left,
.appleHealth_details .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.heart-boxes,
.ecg-boxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.heart-boxes .heart_box,
.ecg-boxes .ecg_box {
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 16px;

  gap: 10px;
}

.heart-boxes .heart_box .heart_count {
  background-color: #000;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heart-boxes .heart_box .heart_count.green {
  background-color: green;
}
.heart-boxes .heart_box .heart_count.yellow {
  background-color: #f5a623;
}

.heart-boxes .heart_box .heart_count p {
  color: #fff;
}

.heart-boxes .heart_box .heart_count p:first-child {
  font-size: 20px;
}
.heart-boxes .heart_box .heart_count p:nth-child(2) {
  font-size: 10px;
}

.heart-boxes .heart_box .heart_desc p:first-child {
  font-weight: 600;
}

.heart-boxes .heart_box .heart_desc p:nth-child(2) {
  color: #999;
  font-weight: 300;
}

.ecg-boxes .ecg_box {
  justify-content: space-between;
  align-items: start;
}

.ecg-boxes .ecg_box .ecg_desc {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ecg-boxes .ecg_box .ecg_title p:first-child {
  font-weight: 600;
}
.ecg-boxes .ecg_box .ecg_title p:nth-child(2) {
  font-weight: 300;
  color: #999;
  font-size: 12px;
}

.ecg-boxes .ecg_box .ecg_desc p {
  font-size: 9px;
}

/* @media only screen and (max-width: 1900px) {
  .overview_col-2 {
    flex: 1;
  }
  .patient-detail-box:last-child {
    grid-column: span 2;
  }

  .card-shaped-box {
    min-height: 75px;
  }
}

@media only screen and (max-width: 1200px) {
  .patient-detail-box:last-child {
    grid-column: unset;
  }
} */

@media only screen and (max-width: 600px) {
  .patient-dashboard-col-2 {
    padding: 10px 50px 15px 20px;
  }
  .overview_col-2 .wrapper
  {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding:unset;
  }
  .patient-detail-box .value
  {
    width:190px;
  }
  .tasks_header
  {
    padding-top: 20px !important;
  }
  .lifetimeValue_bottom
  {
    flex-direction: column;
  }
  /* .box-label
  {
    flex-direction: column;
  } */
  .lifetimeValue_attributes
  {
    flex-direction: column;
  }
  .lifetimeValue_container
  {
    width: 300px !important;
  }
  .overview_col-2
  {
    width: 320px !important;
  }
  .bottom
  {
    display: flex !important;
    flex-direction: column;
  }
  
}
