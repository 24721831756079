.calender_div_main {
  /* overflow: auto; */
  width: 100%;
  height: 500px;
  position: relative;
}

.calender_div .calender_div_main .fc {
  border: none;
}

.delete-event-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.delete-event-popup {
  background-color: rgb(220, 216, 216);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10000;

  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;

    #btn_cancel {
      width: 100px;
      height: 35px;
      background-color: rgb(8, 9, 9);
      color: aliceblue;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }

    #btn_delete {
      width: 100px;
      height: 35px;
      background-color: rgb(113, 11, 11);
      color: aliceblue;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }
  }
}

.close-button {
  /* position: absolute; */
  color: black;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 500px;
}

.event_title {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.fc .fc-button-primary {
  margin: 10px;
  background-color: rgb(230, 230, 233) !important;
  color: black !important;

  &:hover {
    background-color: rgb(199, 195, 195) !important;
  }
}

.fc-icon {
  color: black !important;

  :hover {
    color: aliceblue !important;
  }
}

.fc .fc-button {
  border-radius: 10px;
  border: 0px solid rgb(245, 245, 248) !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-radius: 5px !important;
}

.fc {
  th,
  tr {
    text-align: center !important;
    height: 100px !important;
  }

  &.fc-theme-standard .fc-toolbar .fc-button {
    color: #ffffff;
    background: #fbf8f8 !important;
    border: #ffffff !important;
  }

  .fc-button-primary:not(:disabled) {
    &.fc-button-active,
    &:active {
      background-color: black !important;
      color: #ffffff !important;
      border-radius: 8px !important;
    }
  }

  .fc-button .fc-icon {
    color: red;
  }
}

a {
  font-size: 16px;
  color: #545556 !important;
}

.fc-daygrid-dot-event {
  font-size: 16px;
  color: rgb(130, 86, 31) !important;
}

.fc-timegrid-event {
  font-size: 16px;
}

.fc-v-event {
  background-color: #d6d8ea;
  border: 0px !important;

  /* border-left: 5px solid #162196 !important; */

  .fc-event-time,
  .fc-event-title {
    color: #162196;
  }
}

span.event_title {
  line-height: 11px !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}

.event-content span {
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #162196;
}

.event_start_time {
  padding: 5px;
  display: flex;
  font-size: 10px;
}

.event_icon {
  padding: 5px;
  size: 15px;
}

.add-event-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.fc {
  .fc-col-header-cell-cushion {
    display: table-caption !important;
    align-items: center !important;
    text-decoration: none !important;
    margin-top: 20px;
  }

  &.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
  }
}

.event-content {
  position: relative;
  text-align: left;
}

.icons-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.delete_icon {
  padding: 3px;
  opacity: 0.5;
  color: rgb(249, 49, 49);
  margin-left: 5px;
  cursor: pointer;
}

.fc-daygrid-day-events {
  background-color: #e4eacd;
}

.calender_div {
  .fc.fc-theme-standard .fc-toolbar .fc-button {
    .fc-icon-chevron-right,
    .fc-icon-chevron-left {
      font-family: "FontAwesome" !important;
      margin-top: -5px;
    }

    .fc-icon-chevron-right:before {
      content: "\f054";
      font-size: 12px;
    }

    .fc-icon-chevron-left:before {
      content: "\f053";
      font-size: 12px;
    }
  }

  .fc-prev-button.fc-button.fc-button-primary,
  .fc-next-button.fc-button.fc-button-primary {
    background: none !important;
    font-size: 12px !important;
  }

  .fc-today-button.fc-button.fc-button-primary {
    background: none !important;
    font-size: 12px !important;
    text-transform: capitalize;
    opacity: unset !important;
  }

  .fc-button-group button {
    font-size: 12px !important;
    text-transform: capitalize;
  }

  .calender_div_main .fc {
    height: 925px !important;
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0px !important;
  border-left: 4px solid #8b5cf6;
  background-color: #e3dbf5 !important;
}

.fc {
  .fc-timegrid-axis-cushion,
  .fc-timegrid-slot-label-cushion {
    margin-bottom: 70px !important;
    content: "\00a0" !important;
    font-size: 12px;
    color: #71717a !important;
  }

  &.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border: none !important;
  }
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f2f2f2;
}

/* colgroup{
  border: none !important;
  background-color: #f2f2f2;
} */

/* .fc.fc-theme-standard .fc-view-harness th {
  background: #ffffff !important;
} */

.fc-timegrid-axis {
  background: #f2f2f2 !important;
}

@media only screen and (max-width: 600px) {
  .fc-col-header-cell-cushion {
    font-size: 9px !important;
  }

  .fc-timegrid-slot-label-cushion {
    font-size: 12px !important;
  }
}
