.createReport_container {
  position: absolute;
  bottom: 30px;
  right: 25px;
  z-index: 3;
}

.createReport_button {
  background-color: #000;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin-right: 30px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}
