.ag-theme-alpine,
.ag-theme-alpine-dark {
  width: 100%;
  height: 100%;
}


.ag-theme-alpine .ag-row:hover {
  background-color: #f0f0f0 !important;
  cursor: pointer;
}

.ag-theme-alpine-dark .ag-row:hover {
  background-color: #414141 !important;
  cursor: pointer;
}

.ag-floating-filter-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.patient-tag-container {
  margin: 2px;
  margin-top: -2px !important;
}

.no_patient_found_message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

/* ===================================================================================================== */

.patient_tab {
  margin: 0px;
  height: 820px;
  width: 100%;
  display: flex;
}

.patient_tab_side_filters_container {
  margin: 20px;
  min-width: 300px;
  height: auto;

  .patient_tab_side_filters_box {
    display: flex;
    justify-content: space-between;
    min-width: 300px;
    height: auto;
    margin-top: 20px;
    padding: 8px 10px 8px 10px;
    border-radius: 8.969696998596191px;
    gap: 11.21212100982666px;
    cursor: pointer;

    &:hover p {
      font-weight: 600;
    }

    p {
      margin: 0;
    }

    &:hover .itemCount {
      background-color: #222222;
    }
  }
}

.patient_tab {
  .patient_tab_patient_list {
    overflow: auto;
    width: 100%;
    height: auto;
    margin-right: 5px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .patient_list_grid_container {
    height: auto;
    margin: 0px 30px;
  }

  .ag-root-wrapper {
    border-radius: 15px !important;
    border: none !important;
  }

  .ag-header-cell-resize {
    display: none !important;
  }

  .ag-header {
    border-bottom: none !important;
  }

  .ag-root {
    .ag-row-odd,
    .ag-row-even {
      background-color: transparent;
    }
  }

  .patient_tab_patient_header {
    margin: 20px 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .patient_tab_patient_controls {
    display: flex;
    justify-content: space-between;
  }

  .patient_tab_control_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px;
    gap: 6px;
    border: 1px solid #cccccc;

    &:hover {
      background-color: #111111 !important;

      .patient_tab_button_span {
        color: #dddddd !important;
      }
    }
  }

  .patient_tab_button_span {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px;
    font-weight: 510;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .patient_tab_patient_count_span {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 16px;
    font-weight: 590;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.patient_tab_side_filters_container {
  .patient_tab_side_filter_box_text {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .itemCount {
    width: 21px;
    height: 20px;
    background-color: #999999;
    color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
  }
}

.patient_list_grid_container {
  .ag-theme-alpine-dark .ag-root {
    border-radius: 20px;
    overflow: hidden;
  }

  .ag-checkbox-input-wrapper input {
    border: 1px solid #cccccc !important;
  }
}

.ag-checkbox-input-wrapper input {
  border: 1px solid #cccccc !important;
}

.patient_tab {
  .ag-header-cell-text {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px !important;
    font-weight: 510 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }

  .ag-ltr .ag-cell {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border: none !important;
  }

  .ag-theme-alpine-dark {
    background: #000 !important;
  }
}

.patient-tag-container {
  .custom_tag_succes {
    color: #00a337;
    border: 1px solid #0bda51;
    border-radius: 15px;
    width: 40px;
    height: 26px;
    margin-top: 9px;
  }

  .custom_tag_nhs {
    color: #444;
    border: 1px solid #2b8348;
    background-color: #38d76d;
    border-radius: 15px;
    width: auto;
    height: auto;
    margin-top: 9px;
  }

  .p-tag-icon {
    font-size: 10px;
    font-family: "SF-Pro Regular", sans-serif !important;
  }
}

.p-tag-value,
.p-tag-icon.pi {
  font-size: 10px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.patient-tag-container {
  .custom_tag_subscription {
    color: black;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 15px;
    width: auto;
    height: auto;
    margin-top: 9px;
  }

  .custom_tag_warning {
    color: orange;
    border: 1px solid orange;
    border-radius: 15px;
    width: 40px;
    height: 26px;
    margin-top: 9px;
  }
}

.patient_tab {
  .ag-body {
    border-radius: 15px !important;
  }

  .ag-theme-alpine-dark .ag-row-selected {
    background-color: black !important;
  }
}

.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-alpine-active-color: none !important;
  --ag-row-hover-color: rgb(130 136 140 / 10%) !important;
  --ag-selected-row-background-color: rgb(130 136 140 / 10%) !important;
}

.patient_tab_patient_list .patient_list_no_data_found_message h5 {
  text-align: center;
  margin-top: 100px;
}

.patient_tab {
  .ag-paging-panel {
    border-top: none !important;
  }

  .ag-icon {
    font-family: "agGridAlpine" !important;
  }
}

.patientview_wrapper {
  width: 81%;
  margin-top: 20px;

  .MuiTab-root.Mui-selected {
    color: unset;
  }

  .MuiButtonBase-root {
    padding: 0 10px !important;
  }

  .MuiTab-root {
    text-transform: unset;
  }

  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiTabs-flexContainer {
    gap: 20px;
    height: 55px;
  }
}

/* -----------------create compaign pop up------------------------- */

.patient_tab_list_footer {
  display: flex;
  justify-content: flex-end;

  button {
    margin: 5px 20px;
    padding: 10px;
    background-color: #000;
    height: 40px;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    font-family: "SF-Pro Regular", sans-serif !important;
  }
}

/* Styles for the modal overlay */

.patient_compaign_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Styles for the modal content */

.patient_compaign_modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  overflow-y: auto;
  position: relative;
}

/* Styles for the accordion */

.patient_compaign_modal {
  .accordion-content {
    margin-top: 10px;
    padding: 10px;
  }

  .date-range {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 20px !important;
    height: 50px !important;
  }

  .date-range .MuiTextField-root {
    margin-right: 10px;
  }

  .MuiAccordionSummary-root {
    border-radius: 20px;
    border-radius: 20px;
    background-color: white;
    border-bottom: 1px solid #ddd;
  }

  .MuiAccordionSummary-content {
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiSvgIcon-root.MuiAccordionSummary-expandIcon {
    color: #333;
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    &.Mui-expanded:first-of-type,
    &:first-of-type {
      border-radius: 20px;
    }
  }
}

/* Styles for the date range */

/* Styles for the AccordionSummary */

/* Styles for the expand icon */

.patient_compaign_modal-content h5 {
  align-items: center;
  text-align: center;
}

.patient_compaign_modal {
  .accordion-content-items {
    width: 100%;
    background-color: white;
    height: 40px;
    margin-top: 2px;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
  }

  .add_campaign_button {
    height: 40px;
    margin: 10px;
    margin-top: 30px;
    border-radius: 20px;
    background-color: #000;
    justify-content: center;
    text-align: center;
    align-items: center;

    button {
      color: white;
      margin-top: 6px;
    }
  }
}

.patient_campaign_services_dropdown select {
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  height: 40px;
}

/* ----------------campaign tab------------------------------------------------------- */

.campaign_list_container {
  width: 100%;
  height: 800px;
  margin-right: 5px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: auto;

  .innerServices {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;
    margin-top: 20px;
  }

  .card_service_main_wrapper {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 15px;
    height: 50px;

    .card_count {
      border-radius: 50%;
      background-color: #000;
      color: white;
      padding: 5px;
      font-size: 12px;
    }

    .created_on_date {
      margin-right: 20px;
      padding: 5px;
      font-size: 16px;
    }
  }

  .innerServices .campaign_list_serviceCard .myCard {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
  }

  .cardHeading {
    background-color: transparent !important;
  }

  .progress-bar-container {
    margin-left: 155px;
    display: block;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 150px;
  }

  .total_patient_div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 20px;
  }

  .campaign_service_title {
    font-size: 24px;
    font-weight: 600;
    font-family: "SF-Pro Regular", sans-serif !important;
    margin: 0 auto;
  }

  .campaign_list_cardWrapper {
    flex: 0 0 calc(33.3333% - 20px);
    margin: 10px;
    box-sizing: border-box;
  }

  .campaign_list_serviceCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px;
    background-color: transparent !important;
  }
}

@media screen and (max-width: 600px) {
  .patientview_wrapper .MuiTabs-flexContainer {
    gap: unset;
  }

  .patient_tab {
    display: block;
  }

  .patient_tab_side_filters_container {
    margin: unset;
  }

  .patientview_wrapper {
    width: 100%;
  }

  .campaign_list_container .campaign_list_serviceCard {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .campaign_list_container .campaign_list_serviceCard {
    display: block;
  }
}
