.orbit_settings_content_wrapper {
  overflow: scroll;
  padding: 0 60px;
}

.orbit_card-title {
  font-size: 20px;
  color: #9b9b9b;
  text-transform: uppercase;
  font-weight: 400;
}

.complianceDoc_sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.orbitSettings_accordion {
  background-color: none !important;
  box-shadow: none !important;
  border-radius: 15px !important;

  .accordion-titleBox div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title_meta {
    display: block;
    font-size: 12px;
    margin-top: 10px;

    p {
      margin: 0;
    }
  }
}

.orbitSettings_accordionTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.orbitSettings_calendarBox p {
  margin: 0;
  margin-bottom: 3px;
  font-weight: 600;
}

.orbitSettings_calendar {
  width: 100%;
}

.orbitSettings_calendarBox button {
  margin: 0;
  margin-top: 1px;
  background-color: #a0a0a0;
  border: none;
}

.orbitSettings_uploadBox {
  margin-top: 20px;

  p {
    font-weight: 600;
    margin: 0;
    margin-bottom: 3px;
  }

  .upload_zone {
    padding: 20px;

    .p-button {
      background-color: #b4b4b4;
      font-size: 20px;
      border: none;

      &:hover {
        background-color: #a0a0a0;
        font-size: 20px;
        border: none;
      }

      svg {
        color: black;
      }
    }
  }

  .disclaimer {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 15px;

    p {
      margin: 0;
    }
  }
}

.select_docType p {
  margin: 0;
  margin-bottom: 3px;
  font-weight: 600;
}

.skills_area {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .skill {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 8px;

    p {
      margin: 0;
      margin-bottom: 3px;
      color: #a0a0a0;
      text-transform: uppercase;
      font-weight: 400;
    }

    button {
      margin-top: 20px;
      padding: 10px;
      background-color: #a0a0a0;
      transition: 0.1s linear;
      color: #000;

      &:hover {
        background-color: #6b6b6b;
        transition: 0.1s linear;
        color: #fff;
      }
    }
  }
}

.accSkills_section .input_area {
  padding: 20px;
  border-radius: 15px;
  background: #f2f2f2;
  margin-top: 20px;
}

.pass_fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.acc_fields {
  display: flex;
  gap: 25px;
}

.acc_info {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 20px;
}

.gp_ps_no {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.proff_details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.file_view_container {
  display: flex;
  justify-content: center;
}

.upload__image-wrapper {
  border: 2px dotted #000;
  border-radius: 10px;
  align-items: center;
  overflow: hidden;
}

.file_view_doc {
  cursor: pointer;
}

.add_button_doc_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.add_document {
  color: #fff;
  background: #222;
  border-radius: 10px;
  padding: 10px 20px;
}

.all_uploaded_documents {
  display: flex;
  gap: 25px;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px;
}

.cross_icon {
  display: flex;
  justify-content: flex-end;
}
