.custom-label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400 !important;
}

.addNewModal .custom-label {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400 !important;
}
