.cc-find-patient-comparison-modal-content {
  background-color: var(--find-patient-modal-bg) !important;
  p {
    margin: 0;
  }
  .modal-header {
    display: block;

    .heading {
      font-size: 20px;
      font-weight: bold;
      color: #e92a2a;
    }

    .sub-desc {
      font-size: 12px;
      color: #666;
      margin-top: 5px;
    }
  }

  .modal-body {
    margin: 0;

    h5 {
      font-size: 18px;
      font-weight: bold;
    }

    .comparison-table {
      .header-row {
        display: flex;
        justify-content: space-between;

        > :nth-child(1) {
          width: 40%;
          text-align: right;
        }

        > :nth-child(2) {
          width: calc(40% + 3px);
          text-align: left;
        }
      }

      .body-rows {
        .comp-row {
          display: flex;
          width: 100%;
          border-top: 1px solid var(--find-patient-comparison-border);
          padding: 6px 0 4px 0;
          font-size: 14px;

          > :nth-child(1) {
            width: 40%;
            display: block;
            text-align: right;
          }

          > :nth-child(2) {
            width: 20%;

            display: flex;
            justify-content: center;
            align-items: center;
          }

          > :nth-child(3) {
            width: 40%;
          }

          label {
            color: var(--h2-color);
            font-weight: bold;
          }

          p {
            color: #999;
          }

          .decision-box {
            width: 25px;
            height: 25px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid;

            &.matched {
              border-color: #21bf56;

              > svg {
                color: #21bf56;
              }
            }

            &.not-matched {
              border-color: #e92a2a;

              > svg {
                color: #e92a2a;
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    margin-right: 0;

    button {
      border: 2px solid var(--global-bg-color);
      background-color: var(--find-patient-modal-button) !important;
    }
  }
}
