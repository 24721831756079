.consent_page_header {
  background-color: #005eb8;
  padding: 20px;
}

.nhsuk-button {
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 28px;
  -webkit-appearance: none;
  background-color: #007f3b;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 4px 0 #00401e;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 0;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: auto;

  &:link,
  &:visited,
  &:active,
  &:hover {
    color: #ffffff !important;
    text-decoration: none;
  }
}

@media (min-width: 40.0625em) {
  .nhsuk-button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}

a:visited {
  color: #330072;
}

.nhsuk-width-container {
  margin: 0 16px !important;
  max-width: 960px;
}

.nhsuk_container {
  margin-top: 20px;
  position: absolute;
  display: flex;

  /* left: 20%; */
  width: 100%;
  justify-content: center;
}

.nhsuk-radios__item {
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  clear: left;
  display: block;
  margin-bottom: 8px;
  min-height: 40px;
  padding: 0 0 0 40px;
  position: relative;
}

.nhsuk-radios__input {
  cursor: pointer;
  height: 40px;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 1;

  + .nhsuk-radios__label {
    &::before {
      background: #ffffff;
      border: 2px solid #4c6272;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      height: 40px;
      left: 0;
      position: absolute;
      top: 0;
      width: 40px;
    }

    &::after {
      background: #4c6272;
      border: 10px solid #212b32;
      border-radius: 50%;
      content: "";
      height: 0;
      left: 10px;
      opacity: 0;
      position: absolute;
      top: 10px;
      width: 0;
    }
  }

  &:checked + .nhsuk-radios__label::after {
    opacity: 1;
  }
}

.nhsuk-radios__label {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 12px 4px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.nhsuk-back-link__link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  color: #005eb8;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 0 0 16px;
  position: relative;
  text-decoration: none;

  &:visited {
    color: #005eb8;
  }

  &:hover {
    color: #7c2855;
    text-decoration: underline;
  }
}

.nhsuk-radios__input:focus + .nhsuk-radios__label::before {
  border: 4px solid #212b32;
  box-shadow: 0 0 0 4px #ffeb3b;
}

.nhsuk_container .nhsuk-fieldset__heading {
  text-align: center;
  color: #27b7f1;
}

.nhsuk_title_heading_container {
  background: rgb(67, 59, 59) 848 !important;
  width: 100%;
  height: 70px;
}

.main_container {
  height: auto;
  margin: 30px;
  border: 1px solid #444;
  border-radius: 10px;
  padding-bottom: 40px;
}

.nhsuk_Logo_wrapper {
  text-align: center;

  h1 {
    color: #27b7f1;
  }
}

.nhsuk_heading_wrapper {
  height: 70px;
  background-color: rgb(57, 49, 49);
  text-align: center;
  margin-top: 20px;

  h1 {
    color: #fff;
    padding-top: 10px;
  }
}

.nhsuk_content_wrapper {
  padding: 20px;
}

.nhsuk_footer_wrapper {
  text-align: center;

  button {
    color: #fff;
    display: block;
    margin: 10px auto;
    background-color: rgb(85, 83, 83);
    height: 50px;
    width: 320px;
    padding: 10px;
    border-radius: 10px;
    margin-top: 30px;
  }

  a {
    color: rgb(52, 112, 190) !important;
    text-decoration: underline !important;
    cursor: pointer;
    margin-top: 30px;
  }

  div {
    margin-top: 40px;
  }
}

.nhsuk_info_btns__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;

  button {
    background-color: rgb(85, 83, 83);
    height: 50px;
    max-width: 200px;
    min-width: 200px;
    padding: 10px;
    border-radius: 10px;
    margin-top: 30px;
    color: #fff;
  }

  div {
    a {
      color: rgb(52, 112, 190) !important;
      text-decoration: underline !important;
      cursor: pointer;
    }

    margin-top: 30px !important;
  }
}

.click_here_link {
  color: rgb(52, 112, 190) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

/* ======================================================= */

.personal_info_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;

  .info_fields_wrapper {
    width: 100%;

    input {
      width: 97%;
      padding: 10px;
      margin: 20px;
      border-radius: 10px;
    }

    label {
      width: 97%;
      padding: 10px;
      margin: 20px;
    }
  }
}

.nhsuk-link {
  display: flex;
  align-items: center;

  a {
    color: rgb(52, 112, 190) !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
}

/* --------------------Custom radio buttons-------------------------------------------- */

.nhsuk-form-group {
  .MuiSvgIcon-root {
    width: 40px !important;
    color: #404040 !important;
    height: 40px !important;
  }

  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 16px !important;
  }
}

.nhsuk-radios {
  margin-top: 30px;
}

.nhsuk-back-link {
  margin-left: -20px !important;
}
