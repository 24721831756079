.nms-tabs-container {
  width: 55%;
  height: max-content;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  position: sticky;
  top: 5px;
}

.card-actions {
  display: flex;
}

.nms-title {
  width: fit-content !important;
  margin-top: -40px;
}

.nms-presc-slider {
  position: relative;
  height: 800px;
  padding: 10px;
  overflow: hidden;
}

.nms-tabs-container {
  .main-div {
    display: block;
    padding: 0 20px 0 0;
    width: 96%;
    margin: 30px auto 100px auto;
  }
}

.nms-label-tabs {
  padding-left: 45px !important;
}

.nms-content-area {
  padding-left: 43px !important;
}

.plus_icon {
  margin: 30px 0px;
}

@media only screen and (max-width: 1025px) {
  .nms-tabs-container {
    display: none;
  }

  .show-nms-sidebar {
    max-width: unset;
    display: block;
    position: absolute;
    width: fit-content;
    background: white;
    left: 0;
    z-index: 9999;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    top: 15px;
  }

  .nms-hamburger {
    height: fit-content;
    position: absolute !important;
    top: 45px;
    display: block !important;
  }

  h2.img-heading.para_color {
    margin-top: 55px;
  }

  .nms-presc-slider {
    width: 565px;

    .img-div {
      width: 100%;
    }
  }

  .nms-ham {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.call_intro {
  &.call_box {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    p {
      font-size: 12px;
      line-height: 16px;
      color: #999;
    }

    .call_intro_focus {
      display: inline-block;
      font-weight: 600;
      margin-left: 5px;
    }
  }

  &.call_box_alt {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > div {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 14px;
      color: red;
    }

    .confirm p {
      color: rgb(167, 167, 31);
    }
  }
}

.activity_feed_wrapper {
  padding: 30px 20px;
}

.activity_feed_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  h4,
  p {
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: -20px;
  }

  .switch_container {
    cursor: pointer;
    transition: 0.2s linear;
    padding: 3px 10px;
    border-radius: 10px;
    border: 2px solid #ddd;

    &:hover {
      background-color: #ddd;
      color: black;
      transition: 0.2s linear;
    }
  }
}

.activity_feed_search {
  margin-top: 15px;
}

.search_transcript_input {
  width: 100%;

  input {
    width: 100%;
    border-radius: 10px;
  }
}

.activity_feed_stepper {
  min-height: 20vh;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  span.MuiTimelineDot-root {
    margin: 0;
  }

  .MuiTimelineItem-root:before {
    content: unset !important;
  }

  .MuiTimelineContent-root {
    padding-top: 3px;
  }

  h6 {
    margin: 0;
    margin-top: 5px;
  }

  .MuiTimelineSeparator-root > svg {
    padding: 4px 0;
    width: 25px;
  }

  .MuiTimeline-root {
    margin: 0;
    padding: 0 !important;
  }
}

.timeline_accordion {
  background-color: transparent !important;
  box-shadow: none !important;

  > div {
    padding: 0;
    margin: 0;
  }

  .heading {
    font-size: 12px;
    font-weight: 400;
    color: grey;
  }

  .MuiSvgIcon-root {
    width: 20px !important;
    color: #ccc !important;
    height: 20px !important;
  }
}

.activity_feed_stepper {
  h6 {
    font-weight: 400;
    color: #a6a6a6;
  }

  .caller_name {
    color: #00ca69;
    font-weight: 600;
    font-size: 13px;
  }

  .typeHeading {
    font-size: 13px;
    font-weight: 600;
  }

  .MuiAccordionDetails-root {
    padding: unset;
  }
}

.nms_email_template {
  padding: 16px;
  border-radius: 12px;

  .header {
    p {
      text-transform: uppercase;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }

  .email_content p {
    color: #a496a1;
    word-break: break-word;
  }

  .email_action {
    margin-top: 20px;

    p {
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
      color: #fff;
    }
  }
}

.nms_signature_template {
  margin-top: 30px;
  padding: 16px;
  border-radius: 12px;
  height: auto;
  position: relative;

  .header,
  .sign_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sign_footer {
    margin-top: 10px;
  }

  .header p,
  .sign_footer p {
    margin: 0;
  }

  .header p {
    font-weight: 600;
    font-size: 11px;
  }

  .header svg {
    width: 15px;
  }

  .sign_content {
    margin-top: 10px;
    color: var(--h2-color);
  }

  .sign_content img {
    width: 300px;
  }

  .sign_footer {
    width: 100%;
    p {
      font-size: 12px;
    }
  }
}

.nms_info_accordion {
  margin-top: 15px !important;
  border-radius: 12px !important;

  p {
    margin: 0;
    font-size: 11px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .header.transcript {
    width: 100%;
    justify-content: space-between;
  }

  .top_parent_details {
    padding: 5px 20px !important;
  }

  .accordion_content {
    padding: 5px 10px;
  }

  .list_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 12px;
  }

  .transcript {
    padding: 0;
  }

  .inner-most {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    padding: 10px;

    .header {
      justify-content: start;
      margin-bottom: 5px;
    }

    .list_box_wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.timeline_action_box {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 30px 0;
  padding: 0 30px;

  .buttons {
    display: flex;
    gap: 10px;
  }

  .timeline_btn {
    background-color: #222;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    transition: 0.2s linear;

    &:hover {
      background-color: #424141;
      transition: 0.2s linear;
    }

    p {
      margin: 0;
      color: #fff;
      text-transform: capitalize;
      font-weight: 400;
    }
  }
}

.nms_note {
  padding: 16px;
  border-radius: 12px;
}

.nms_note_cont {
  margin: 20px 0;
}

.nms_note {
  p {
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .nms_note_header {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .note_header {
    display: flex;
    align-items: center;
    gap: 6px;

    p {
      font-size: 12px;
    }
  }
}

.nms_info_accordion .nms_note_header p {
  color: #00ca69;
  font-size: 12px;
  font-weight: 500;
}

.nms_note .body p {
  margin-top: -10px;
  font-size: 12px;
}

.nms_call_reminder {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 16px;
  border-radius: 12px;

  p {
    margin: 0;
  }

  .time {
    font-size: 20px;
    font-weight: 600;
    padding: 0;
  }

  .label {
    color: #a6a6a6;
  }
}

.followup_header {
  display: flex;
  align-items: center;
  gap: 6px;
}

.nms-accordion-boxes-wrapper {
  padding: 0 !important;
}

p.label-textfield {
  font-size: 12px;
}

.textfield label {
  font-size: 12px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.nms_grouped_notes_box {
  margin-top: 15px;
  padding: 16px;
  border-radius: 12px;
  height: auto;
  position: relative;

  p {
    margin: 0;
  }

  .d-flex {
    gap: 6px;
    .date-tag {
      align-items: center;
    }
    .tag {
      font-size: 9px;
      padding: 3px 6px;
      border-radius: 30px;
      border: 1px solid var(--grouped-notes-value);
    }
  }

  .d-flex.label {
    p {
      color: #00ca69;
    }
  }
  .d-flex.justify {
    font-size: 11px;
    justify-content: space-between;
  }

  .drug-action {
    margin-top: 10px;
    p {
      color: var(--drug-action-text);
      font-size: 11px;
    }
  }

  .note-comments {
    margin-top: 15px;
    p {
      color: var(--grouped-notes-value);
      font-size: 14px;
    }
  }
}
