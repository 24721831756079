.services_tab {
  display: flex;
  gap: 20px;
  padding: 10px;
}

@media only screen and (max-width: 1180px) {
  .services_tab {
    display: block;
  }
}
