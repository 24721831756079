.popup_header {
  h5 {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }

  p {
    margin: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    line-height: 14px;
    margin-top: -5px;
  }
}

.popup_content {
  display: flex;
  align-items: center;

  p {
    color: #fff;
    margin: 0;
  }
}

.popup_footer button {
  margin-top: 10px;
  background-color: #fff;
  width: 88%;
  padding: 3px 10px;
  border-radius: 5px;
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  z-index: 99;

  p {
    margin: 0;
    font-size: 14px;
    color: #000;
  }
}

.process-now {
  display: flex;
  align-items: center;

  > div {
    margin-right: -12px;
  }
}

.single-marker {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  gap: 6px;
  cursor: pointer;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }
}

.popup-cluster {
  background-color: #000;
  padding: 5px 10px 15px 10px;
  min-width: 250px;
  border-radius: 18px;
  color: black;
  border: 3px solid #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  font-family: "SF-Pro Regular", sans-serif !important;
  z-index: 2 !important;
  position: absolute;
}

.clusterFade {
  transition: 0.2s linear;
  opacity: 0.2;
}

.animated-modal {
  height: 100%;
}

.patient-cluster-marker {
  width: 40px;
  background: black;
  color: #fff;
  font-family: "SF-Pro Regular", sans-serif !important;
  padding: 2px 5px;
  border-radius: 30px;
  border: 1px solid #fff;
  text-align: center;
  font-weight: 500;
  display: block;
  position: relative;
}

.pharmacyIcon {
  background: white;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1000px;
  border: 2px solid black;
  height: 31px;
  font-size: 14px;

  p {
    margin: 0;
    padding-right: 10px;
    margin-top: 2px;
  }
}

p.pharmacy-name {
  margin: 0;
  line-height: 1;
}

.percentage-marker {
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 3px;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;

  p {
    margin-bottom: 2px;
  }

  .perc-val {
    color: #000;
  }

  &.rippleMarker::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 65%;
    background-color: rgba(255, 0, 0, 0.8);
    border-radius: 50%;
    animation: ripple 1.5s linear infinite;
    z-index: -1;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 0.2;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.tooltip-container {
  font-family: "SF-Pro Regular", sans-serif !important;
  position: absolute;
  width: 400px;
  height: 340px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 6 !important;
}

.dashboardtool {
  position: relative;
}

.tooltip-pharmacy-info {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.tooltip-container {
  .pharmacy-image {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
  }

  .tooltip-close {
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .tooltip-content {
    padding: 10px 15px;

    h6 {
      margin: 0;
    }
  }
}

.tooltip-progressbar-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: start;
  margin-top: 20px;

  .progress-bar-wrapper {
    width: 100%;
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    padding: 2px;
    text-align: left;
  }

  .progress-bar-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress-bar-rating {
    border-radius: 30px;
    padding: 1px 10px;
    width: 40px;
    border: 1px solid #000;
    text-align: center;
    color: #000;
  }

  .progress-bar-filled {
    height: 100%;
  }

  .progress-bar-wrapper p {
    font-weight: 600;
  }
}
