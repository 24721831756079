.overviewTab_container {
  width: 100%;
  background-color: rgb(247, 247, 247);
  overflow: auto;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0px;
}

.monthly_report_container {
  width: 48.5%;
  max-width: 50%;
  height: auto;
  margin-top: 70px;
  margin-left: 20px;
  margin-right: 20px;
  top: 223px;
  left: 43px;
  padding: 16px 0px 16px 0px;
  border-radius: 16px;
  border: 2px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  max-height: auto;
}

.monthly_report_title {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 16px;
  font-weight: 510;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.mothly_report_body {
  margin: 15px;

  div {
    width: 100%;
    max-width: 100%;
    height: 34px;
    margin-top: 10px;
    padding: 5px, 12px, 5px, 12px;
    border-radius: 10px;
    border: 1px solid rgb(233, 227, 227);
    box-shadow: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: right;
    color: #848080;

    span {
      padding: 5px;
    }
  }
}

.report_total_amount_container {
  width: auto;
  max-width: auto;
  height: 60px;
  padding: 14px 16px 6px 16px;
  gap: 7px;
  margin-left: 5px;
  margin-right: 5px;

  span {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.report_footer_container {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 11px;
  font-weight: 510;
  line-height: 13px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #999999;
  margin-left: 20px;
  margin-right: 15px;
}

.dashboard_container {
  /* margin-left: 40px;
  margin-right: 20px; */
  display: flex;
  margin: 0px;
  padding: 0px;
}

.chartBar_header {
  display: flex;
  justify-content: space-between;

  /* width: 703px; */
  height: 36px;
  padding: 0px 16px 0px 16px;
  gap: 7px;

  .title {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 16px;
    font-weight: 510;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
}

.calneder_input {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 16px;
  font-weight: 510;
  line-height: 19px;
  letter-spacing: 0em;
  border: 0px solid white;
  color: #999999;
}

.calneder_inputField {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 16px;
  font-weight: 510;
  line-height: 19px;
  letter-spacing: 0em;
  margin-top: 10px;
  border: 0px solid white;
  color: #999999;
  display: flex;
  justify-content: left;
}

.buttons_container {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 16px;
  font-weight: 510;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.selected_button {
  background-color: #eeeeee;
  width: 70px;
  height: 32px;
  padding: 0px 8px 0px 8px;
  border-radius: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: 510;
}

.unselected_button {
  width: 70px;
  height: 32px;
  padding: 0px 8px 0px 8px;
  border-radius: 10px;
  color: #999999;
}

.chartBar_container {
  background-color: rgb(255, 255, 255);
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: auto;
  top: 223px;
  left: 413px;
  padding: 16px 0px 16px 0px;
  border-radius: 17.56031608581543px;
  border: 2px;
  margin-top: 70px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
}

.chartBar {
  padding: 20px;
}

.Estimated_earnings_container {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  margin-left: 20px;
  margin-right: 15px;
}

.Estimated_earnings_range {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  margin-left: 20px;
  margin-right: 15px;

  span input {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000000;
    width: 100%;
    max-width: 100%;
  }
}

.custom_range_input {
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: var(
    --range-color,
    linear-gradient(to right, #000000 0%, white 0%)
  );
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #000000;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #000000;
    cursor: pointer;
  }
}

.Estimated_earnings_container span {
  margin-top: 30px;
}

.circularBar_title {
  h4 {
    margin-top: 10px;
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 16px;
    font-weight: 510;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    align-items: center;
  }

  span {
    margin: 15px;
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 16px;
    font-weight: 510;
    line-height: 19px;
    letter-spacing: 0em;

    /* text-align: center;
    align-items: center; */
  }
}

.challenge_container {
  margin-top: 16px;
}

.circularProgressBar_icon {
  text-align: center;

  svg {
    margin-top: 30px;
  }
}

.Cards_container {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  top: 223px;
  left: 43px;
  padding: 16px 0px 16px 0px;
  border-radius: 16px;
  border: 2px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  max-height: auto;
}

@media (min-width: 768px) {
  .Cards_container {
    max-width: auto;
  }
}

@media (min-width: 992px) {
  .Cards_container {
    max-width: auto;
  }
}

@media (min-width: 1200px) {
  .Cards_container {
    max-width: auto;
  }
}

.overview_tab {
  display: flex;
  height: 100%;
  overflow: auto;
  padding: 0 18px 40px 18px;

  p {
    font-size: 14px;
    line-height: 1rem;
    margin: 0;
  }

  h3,
  h4 {
    font-size: 18px;
    line-height: 1rem;
  }

  h5 {
    font-size: 14px;
    line-height: 1.2rem;
    margin: 0;
  }
}

.overview_col-1,
.overview_col-2,
.overview_col-3 {
  height: 100%;
}

.overview_col-1,
.overview_col-3 {
  flex: 0.5;
  padding: 15px 0;
}

.overview_col-2 {
  flex: 1;
  padding: 200px 50px 15px 50px;
}

.overview_col-1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overview-boxes {
  border: 1px solid #eee;
  border-radius: 8px;
  height: 230px;
  padding: 14px 16px;
}

.overview-headings {
  display: flex;
  justify-content: space-between;

  .menu-icon {
    cursor: pointer;
  }
}

.overview-iconBox {
  display: flex;
  gap: 10px;
}

.overview-content {
  padding: 10px 0;
}

.seenBox {
  p {
    color: #999;
  }

  .team-text p {
    color: #fff;
  }
}

.seenMembers {
  display: flex;
  margin-left: 10px;
  position: relative;
  margin-top: 10px;
}

.seenBox {
  .team-img {
    margin-left: -10px;
    width: 45px;
    height: 45px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .team-text {
    position: absolute;

    p {
      font-size: 12px;
    }
  }
}

.cal-date {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  p {
    color: #999;
    font-size: 13px;
  }

  svg {
    color: #999;
  }
}

.overview_col-2 {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 20px 0 50px 0;
  }

  .col-box {
    background: white;
    padding: 15px 16px 15px 10px;
    box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s linear;

    &:hover {
      .box-label p {
        color: #353434;
      }

      background-color: #e1e1e1 !important;
      cursor: pointer;
      transition: 0.2s linear;
    }
  }

  .box-label {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .count {
    background-color: #eee;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    border-radius: 10px;
    width: 35px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    font-weight: 600;
  }
}

.two-col-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-bottom: 20px;

  .wrapper-alt {
    background: white;
    padding: 16px;
    box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
    border-radius: 16px;
    transition: 0.2s linear;
  }

  .heading {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .content {
    padding: 10px 0;

    p {
      font-weight: 300;
      color: #666;
    }
  }
}

.single-set-actions {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 16px;
  padding: 16px;

  .action {
    display: flex;
    align-items: center;

    .heading {
      display: flex;
      align-items: center;
    }
  }

  .heading .count {
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    border-radius: 10px;
    width: 35px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.double-set-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .action {
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    align-items: center;

    .heading {
      display: flex;
      align-items: center;

      .count-alt {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

.action > svg:hover {
  cursor: pointer;
}

.week-tabs {
  padding: 20px 0 50px 0;

  .tab-container {
    display: flex;
    gap: 30px;

    .tab {
      font-size: 16px;
      padding: 5px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #eee;
      color: #aaa;
      margin-bottom: 20px;
      font-family: "SF-Pro Regular";

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .content {
    background: white;
    padding: 30px 16px;
    box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
    border-radius: 16px;
    transition: 0.2s linear;
  }

  .tab-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .date {
      background-color: #eee;
      padding: 5px;
      border-radius: 10px;
      color: #aaa;
    }
  }

  .content h6 {
    color: #666;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .content-item .services {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.task-item {
  width: 100%;
}

.task-item-top {
  > div {
    display: flex;
    align-items: center;
    gap: 10px;

    &:first-child svg {
      margin-top: -2px;
    }
  }

  display: flex;
  justify-content: space-between;
  width: 100%;

  .priority {
    font-size: 11px;
    color: #ebac44;
    background-color: #fdf4e5;
    border: 1px solid #ebac44;
    padding: 5px;
    border-radius: 30px;
  }
}

.task-item-desc {
  margin-top: 10px;
  margin-left: 26px;

  p {
    font-size: 12px;
    color: #66666687;
  }
}

.week-tasks {
  padding: 0 0 30px 0;
}

.completed-tasks {
  border-top: 1px solid #999;
  padding: 30px 0;
}

.week-tasks,
.completed-tasks {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.week-tasks svg:hover,
.completed-task svg:hover {
  cursor: pointer;
}

.completed-text {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.services-offered {
  margin-top: 20px;

  .image {
    border: 1px solid #eee;
    border-radius: 8px;
    height: 200px;
    background-color: #eaf2f9;
    padding: 16px;
  }

  .image-two {
    background-color: #c2cbc9;
  }

  .image-three {
    background-color: #536558;
  }

  .image p {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    width: 100%;
  }

  .bottom {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-top: 10px;

    .icon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .created-by {
      font-size: 12px;
      font-weight: 300;
      color: #666;
    }
  }
}

.overview_col-3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.responsible-pharmacist,
.next-up-today {
  margin-top: 20px;
}

.live-feed .wrapper {
  border: 1px solid #eee;
  height: 200px;
  border-radius: 16px;
  overflow: hidden;

  .video-player {
    display: grid;
  }
}

.responsible-pharmacist .wrapper {
  border: 1px solid #eee;
  padding: 16px;
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon-box {
    border: 1px solid #eee;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
  }

  .label {
    display: flex;
    gap: 10px;

    .desig {
      font-weight: 300;
      font-size: 13px;
    }
  }
}

.next-up-today .calender-wrapper {
  display: flex;
  height: 300px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
}

.next-up-top,
.next-up-bottom {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: -5px;
}

.next-up-today {
  .vertical-line {
    height: 90%;
    width: 2px;
    background: #d9d9d9;
    position: absolute;
    top: 14px;
    z-index: 1;
  }

  .time {
    background-color: #eee;
    color: #666;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 30px;
  }

  .appointments {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-left: -8px;
    z-index: 2;
  }

  .appointment {
    border: 1px solid #fff;
    padding: 16px;
    background: rgba(255, 255, 255, 0.6);
    padding: 16px;
    box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .name {
      font-weight: 600;
    }

    .service {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    .parentService {
      font-size: 12px;
      color: #8c8c8c;
    }
  }

  .apt-type {
    font-size: 12px;
    color: #8c8c8c;
  }

  .appointments {
    .circle {
      width: 6px;
      height: 6px;
      background-color: #999;
      border-radius: 50%;
    }

    .total-appointments {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: 48%;
      left: -2px;

      &:hover {
        cursor: pointer;
      }

      p {
        color: #b3b3b3;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }
}

.overview_footer {
  position: fixed;
  z-index: 2;
  margin-top: 20px;
  bottom: 0;
  width: 100%;
  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 1px 0px 0px #cccccc;
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  text-align: right;
  float: right;
  right: 0;
}

.overview_mapbox {
  position: absolute;
  z-index: -2;
  width: 99.9%;
  left: 0;
  overflow: hidden;

  .mapboxgl-map {
    margin-top: -85px;

    &::after {
      content: "";
      height: 100vh;
      width: 100vw;
      background: white;
      position: absolute;
      top: 40vh;
    }
  }

  &::after {
    content: "";
    width: 100vw;
    position: absolute;
    height: 100vh;
    top: 200px;
    filter: blur(25px);
  }
}

@media only screen and (max-width: 1900px) {
  .overview-boxes {
    height: auto;
  }

  .overview_col-2 .wrapper {
    grid-template-columns: repeat(2, 48%);
  }

  .patient-dashboard-col-2 .wrapper,
  .overview_col-2 .two-col-wrapper {
    grid-template-columns: unset;
  }
}

@media only screen and (max-width: 1400px) {
  .next-up-today .appointment .service {
    max-width: 230px;
  }

  .overview_mapbox .mapboxgl-map {
    margin-top: unset;
  }
}

@media only screen and (max-width: 1200px) {
  .overview_col-2 .wrapper {
    grid-template-columns: repeat(1, 100%);
  }

  .next-up-today .appointment .service {
    max-width: 150;
  }
}

@media only screen and (max-width: 1180px) {
  .next-up-today .appointment .service {
    max-width: unset;
  }
}

@media only screen and (max-width: 990px) {
  .overview_tab {
    display: block;
  }

  .overview_col-1,
  .overview_col-2,
  .overview_col-3 {
    height: unset;
    padding: unset;
  }

  .overview_col-2 {
    padding: 30px 0;
  }
}

@media screen and (max-width: 600px) {
  .week-tabs .tab-container {
    gap: 17px;

    .tab {
      width: 25px;
      height: 25px;
    }
  }

  .overview_tab {
    h3,
    h4 {
      line-height: 3rem;
    }
  }

  .task-item-top .priority {
    border-radius: 15px;
    text-align: center;
    padding: 2px;
    width: 80px;
  }

  .overview_mapbox {
    top: 0px;
  }

  .week-tabs .content-item {
    display: block !important;
  }

  .heart_count p {
    font-size: 12px !important;
  }

  .in-store-money {
    padding: 10px 5px !important;
  }

  .summary_details {
    flex-direction: column;
  }

  .appleHealth_details {
    flex-direction: column;

    .card-shaped-box {
      width: 265px !important;
    }
  }

  .appointment {
    flex-direction: column;
  }

  .box-label .value.allergies {
    overflow-x: scroll;
  }

  .left {
    flex-direction: column;
  }

  .lifetimeValue_attributes .box {
    width: 250px !important;
  }

  .dateandtime {
    flex-direction: column;
  }

  .summary_details .card-shaped-box {
    width: 265px !important;
  }

  .appointment-info .name {
    font-size: 12px !important;
    text-align: center;
  }

  .appleHealth_header {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .overview_mapbox {
    top: 0px !important;
  }

  .tabs_box {
    padding: 60px 20px 0 20px;
  }

  .pharmacy_header_iconbox {
    justify-content: center !important;
  }

  .overview_col-2 .wrapper {
    display: flex;
    flex-direction: column !important;
  }
}
