.orbit_page_wrapper {
  width: 90%;
  padding: 20px;
  margin: 0 auto;
}

.orbit_bg {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.orbit_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons_wrapper {
  display: flex;
  gap: 2px;
}

.orbit_user_image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.orbit_user_image .img_box {
  border-radius: 50%;
}

.orbit_user_image .img_box img {
  object-fit: cover;
}

.orbit_user_image .img_box {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
}

.orbit_user_image .img_box_dark {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}

.orbit_playStartPause {
  background-color: black;
  border-radius: 30px;
  height: 45px;
  padding: 8px;
  border: 5px solid #fff;
  box-shadow: 0px 4px 24px 0px #00000040;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: relative;
  top: -20px;
}

.orbit_btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.orbit_playStartPause p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: 0.2s linear;
}

.orbit_btn svg {
  margin-top: -2px;
}

.orbit_grid_wrapper {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  gap: 20px;
  height: 70vh;
  margin-top: 30px;
}

.orbit_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.orbit_grid_alt {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.colspan-2 {
  grid-column: span 2;
}

.orbit_chart_wrapper {
  overflow: hidden;
  font-size: 10px;
  font-weight: 500;
  height: 150px !important;
}

.orbit_box {
  border-radius: 16px;
  box-shadow: 0px 4px 28px 0px #00000010;
  padding: 12px 16px;
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
}

.orbit_grid_last {
  max-height: 250px;
}

.orbit_box-container {
  padding-right: 3px;
}

/* width */
.orbit_box::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.orbit_box::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
.orbit_box::-webkit-scrollbar-thumb {
  background: #aaa;
  transition: 0.2s linear;
}

/* Handle on hover */
.orbit_box::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.2s linear;
}

.orbit_box p {
  margin: 0;
  word-break: break-all;
}

.orbit_label {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
}

.orbit_label p {
  margin: 0;
  color: #999;
}

.orbit_value {
  margin: 0;
  font-size: 26px;
  font-weight: 500;
}

.potential-earning {
  margin-top: 10px !important;
}

.orbit_label_alt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.orbit_label_alt p {
  font-weight: 400;
  margin: 0;
  word-break: auto-phrase;
}

.d-flex-a-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.orbit_box .cal-label p {
  font-size: 12px;
  color: #999;
}

.orbit_box-container.monthly_report-container {
  height: 86%;
}

.monthly_report {
  max-height: 100%;
  flex: 1;
}

.monthly_report .content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.monthlyReport_boxes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 15px;
  flex: 1 auto;
}

.monthlyReport_boxes div {
  border-radius: 10px;
  padding: 8px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
  cursor: pointer;
}

.monthlyReport_boxes div p {
  margin: 0;
  color: #999;
}

.orbit_bottomNote {
  color: #999;
  font-size: 10px;
  font-weight: 500;
}

.orbit_active_services .orbit_bottomNote {
  margin-top: 100px;
}

.t-center {
  font-size: 12px;
  text-align: center;
}

p.est_earning_value,
p.est_earning_text {
  margin-top: 15px;
}

input[type="range"] {
  width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 3px;
  border-radius: 30px;
}

input[type="range"]::-webkit-slider-thumb {
  margin-top: -6px;
}

.est_earning_text {
  font-size: 12px;
}

.orbit_box .accordions_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  max-height: 170px;
  height: 100%;
  overflow-y: scroll;
}

.orbit_box .accordions_wrapper .accordion {
  box-shadow: none;
  border-radius: 12px;
}

.orbit_box .accordions_wrapper .accordion .title {
  align-items: start;
}

.orbit_box
  .accordions_wrapper
  .accordion
  .MuiAccordionSummary-expandIconWrapper {
  position: relative;
  top: 10px;
}

.orbit_box .accordions_wrapper .accordion .title .first {
  text-align: left;
  font-weight: 500;
}

.sessions-flex {
  gap: 10px;
  color: #ddd;
}

.orbit_themeSwitch .display-mode {
  position: relative;
  right: unset;
  margin: 0;
}

.orbit_actionGroup_wrapper {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 60px;
  box-shadow: 0px 4px 40px 0px #00000040;
  padding: 10px 20px;
  border-radius: 30px;
  margin: 0 auto;
  width: fit-content;
}

.orbit_successDialog .MuiDialog-paper {
  text-align: center;
  padding: 40px 20px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.orbit_successDialog .title h2 {
  margin-top: 10px;
}

.orbit_successDialog .MuiDialogContent-root,
.orbit_successDialog .MuiDialogActions-root {
  padding: 0;
}

.orbit_successDialog .content_wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  gap: 15px;
}

.orbit_successDialog .content_box {
  padding: 10px 15px;
  border-radius: 10px;
  text-align: left;
  width: 100%;
}
.orbit_successDialog .content_box p {
  margin: 0;
}

.orbit_successDialog .content_box:last-child {
  width: unset;
}

.orbit_successDialog .content_box .content_label {
  color: #999;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.orbit_successDialog .content_value_total {
  font-size: 20px;
  font-weight: 500;
}

.orbit_successDialog .orbit_actions {
  margin-top: 20px;
  width: 100%;

  border-radius: 10px;
  padding: 12px 15px;
}

.orbit_successDialog .orbit_actions p {
  margin: 0;
}

.orbit_successDialog .withdraw_btn {
  justify-content: space-between;
  align-items: center;
}

.orbit_successDialog .confettiAnimation {
  width: 70%;
  position: absolute;
  top: -50px;
  left: 17%;
}

.orbit_successDialog .closeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.orbit_user_image .sparklesAnimation {
  width: 8%;
  position: absolute;
  z-index: -1;
  top: -20px;
  left: 45%;
}

.orbit_bg,
.orbit_actionGroup_wrapper {
  position: fixed;
}

.orbit_box .date-range-picker {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent !important;
}

.orbit_box .date-range-picker span {
  align-items: center !important;
  height: 20px !important;
  background-color: transparent !important;
}

.orbit_box .date-range-picker-container .p-calendar .p-inputtext {
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.orbit_box .date-range-picker input {
  background-color: transparent !important;
  font-size: 12px !important;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-weight: 500 !important;
  width: 100% !important;
  margin: 0 !important;
}

.orbit_box .date-range-picker button {
  margin: 0;
  padding: 0 !important;
  background-color: transparent !important;
}

.orbit_box .date-range-picker button svg {
  width: 20px !important;
}

@media only screen and (max-width: 600px) {
  .orbit_grid,
  .orbit_grid_alt,
  .orbit_successDialog .content_wrapper {
    display: flex;
    flex-direction: column;
  }

  .orbit_successDialog .content_box {
    width: 100%;
  }

  .orbit_bg,
  .orbit_actionGroup_wrapper {
    position: fixed;
  }
}

/* -----------------orbit session------------------------------------- */
.orbit_session_contaier .popup {
  padding: 20px;
  box-shadow: 1px -1px 15px 11px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  overflow-y: auto;
}

.orbit_session_main_container {
  padding: 10px;
  width: 400px;
  margin-top: 20px;
  height: auto;
  border-radius: 10px;
}
.sms_main_container {
  padding: 10px;
  margin-top: 20px;
  height: auto;
  border-radius: 10px;
}

.orbit_session_main_container .orbit_info_container {
  margin: 15px;
  display: flex;
  justify-content: space-between;
}

.orbit_info_container .orbit_info {
  width: 100%;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
}

.orbit_info p {
  color: #747474;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
}

.orbit_end_time_container {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
}
.orbit_action_btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orbit_session_contaier .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(219 219 219 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
}
.session_heading_container {
  margin-top: 15px;
  text-align: center;
}

.orbit_action_btn .orbit_playStartPause {
  position: unset;
}
.sms_Urls {
  display: flex;
  gap: 100px;
}
.sms_link {
  text-decoration: underline;
  cursor: pointer;
}
.profile_image {
  height: 33px;
  width: 33px;
  border-radius: 10px;
}
.orbit_end_time_container .template_input {
  width: 100%;
  border-radius: 10px;
}

.orbit_grid_new,
.orbit_grid_col {
  display: flex;
  gap: 20px;
}

.orbit_grid_row {
  display: flex;
  gap: 20px;
}
.orbit_grid_col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 100%;
}

.orbit_box_earnings {
  flex: 1;
  max-height: 100%;
}

.sessions-box {
  margin-top: 10px;
  max-height: 140px;
  height: 100%;
}

.sessions-box .session {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.active-service-session {
  flex-direction: column;
}

.orbit_active_services {
  flex: 1;
  max-height: fit-content;
}

.orbit_active_services .MuiTabs-indicator {
  display: none;
}

.orbit_active_services .MuiTabPanel-root {
  padding: 0;
}

.orbit_active_services .session {
  width: 100%;
}

.orbit_active_services .sessions-flex {
  margin-top: 5px;
}

.orbit_active_services .tag-flex {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.orbit_active_services .tag {
  font-size: 9px;
  font-weight: 600;
  border-radius: 30px;
  padding: 6px;
}

.orbit_active_services .tag.intervention {
  background: #00cc6a;
}

.orbit_active_services .tag.parked {
  background: #00ccc4;
}

.orbit_active_services .tag.followup {
  background: #5837d0;
}

.orbit_active_services .overdue p {
  color: #e70000;
}

.orbit_active_services .accordions_wrapper {
  max-height: 140px;
}
.active_tags {
  display: flex;
  gap: 5px;
}

/* width */
.orbit_active_services .wrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.orbit_active_services .wrapper::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
.orbit_active_services .wrapper::-webkit-scrollbar-thumb {
  background: #aaa;
  transition: 0.2s linear;
}

/* Handle on hover */
.orbit_active_services .accordions_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.2s linear;
}

/* width */
.orbit_box-container .accordions_wrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.orbit_box-container .accordions_wrapper::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
.orbit_box-container .accordions_wrapper::-webkit-scrollbar-thumb {
  background: #aaa;
  transition: 0.2s linear;
}

/* Handle on hover */
.orbit_box-container .accordions_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.2s linear;
}

.missing_bank_info p,
.missing_bank_info a {
  font-size: 14px;
  color: #fff !important;
  line-height: 20px;
}
.missing_bank_info .missing_info_link {
  text-decoration: underline;
  cursor: pointer;
}

.missing_bank_info .orbit_label p {
  font-size: 12px;
}

.missing_bank_info .text {
  margin-top: 8px;
}

.orbit_footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.orbit_footer .orbit_actionGroup_wrapper {
  position: unset;
}

.orbit_footer .orbit_actionGroup_wrapper .bottomBar_countContainer {
  display: none;
}

.orbit_grid_top_panel {
  display: flex;
  margin-bottom: 20px;
}

.agency_accordions_wrapper {
  max-height: 400px !important;
}

.orbitagency-monthly-report {
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1400px) {
  .orbit_grid_wrapper {
    height: 100%;
  }

  .orbit_box-container.monthly_report-container {
    height: unset;
  }

  .orbit_footer {
    position: fixed;
  }
}
@media screen and (max-width: 800px) {
  .orbit_grid_wrapper {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
  }

  .orbit_user_image {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .orbit_footer {
    position: unset;
  }
}
.grid-report-content .ag-header-cell-text {
  color: #999999;
}

.grid-workload-content .ag-header-cell-text {
  color: #999999;
}

.agency-reports {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.orbit_box_pharmanaut {
  border-radius: 16px;
  box-shadow: 0px 4px 28px 0px #00000010;
  padding: 12px 16px;
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  background-color: var(--orbit-box-bg) !important;
  border: 2px solid var(--orbit-box-border);
  max-height: 100%;
}

.grid-workload-content .ag-root-wrapper {
  border-radius: 10px !important;
  border: none;
}

.grid-report-content .ag-root-wrapper {
  border-radius: 10px !important;
  border: none;
}

.orbit_active_services .ag-root-wrapper {
  border-radius: 10px;
}

.orbit-agency-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
}

.agency-report-heading {
  font-weight: 400;
  margin: 0;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.orbit_workload,
.orbit_mr {
  flex: unset !important;
}

.orbit-pharmanaut {
  max-height: unset !important;
}

.orbit-pharmanaut-ag-grid {
  max-height: 400px;
  height: auto;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .orbit_value {
    font-size: 22px;
  }
}

.orbit-no-data-note {
  font-weight: 500;
  margin: 0;
  color: #999;
}

.pharmanauts-tags {
  display: flex;
  gap: 5px;
}

.pharmanaut-tag {
  background-color: #8472ee;
  color: #000;
  padding: 5px;
  border-radius: 10px;
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
}

.pharmanaut-details-card-heading
{
  color: var(--h2-color);
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 16px !important;
    margin: 10px 0px 10px 0px;
    padding: 10px;
    gap: 10px;
    text-transform: capitalize;
}

.pharmanaut-availibility {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
}

.availibility-card-inner-value {
  color: var(--card-inner-value);
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0px;
}

.dayVal
{
  display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.pharmanaut-info-box
{
  background-color: var(--timeline-notes-content) !important;
    border: 2px solid var(--timeline-notes-content-border) !important;
    width: 100%;
    border-radius: 11px;
    height: 53px;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pharmanaut-info-box-disabled
{
  background-color: var(--timeline-notes-content) !important;
    border: 2px solid var(--timeline-notes-content-border) !important;
    width: 100%;
    border-radius: 11px;
    height: 53px;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
}
.durations
{
  display: flex;
    align-items: center;
    gap: 10px;
}

.durationVal
{
  margin: 0;
    border: 1px solid var(--availibility-value);
    border-radius: 7px;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
}

.skillset-inline-not
{

    border-radius: 10px;
    padding: 10px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pharmanaut-avatar
{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid grey;
  background-size: cover;
  display:flex;
  justify-content: center;
  align-items: center;
}

.messageBtnContainer
{
  margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.workload-save
{
  display: flex;
  justify-content: flex-end;
    gap: 10px;
    flex-direction: row;
}

.pharmanaut-list-name
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pharmanaut-status
{
  display: flex;
  align-items: center;
}

.pharmanaut-status-indicator
{
  width: 7px;
  height:7px;
  border-radius: 50%;
  margin-right: 5px;
}

.pharmanaut-list-value
{
  margin: 0;
  color: #999999;
}
.pharmanaut-name-value
{
  text-decoration: underline;
}
.pharmanaut-av-main
{
  display: flex;
  align-items: center;
}
.selected-service-border {
  border: 1px solid #00A3DA;
}