.custom-dropdown {
  width: 100%;
  display: contents;
}
.custom-dropdown .custom-select::before,
.custom-dropdown .custom-select::after,
.custom-dropdown .custom-select:hover,
.custom-dropdown .custom-select:hover::before,
.custom-dropdown .custom-select:hover::after,
.custom-dropdown .custom-select:hover:not(.Mui-disabled, .Mui-error):before,
.custom-dropdown .custom-select:hover:not(.Mui-disabled, .Mui-error):after {
  border-bottom: none;
}

.custom-dropdown .custom-select:focus,
.custom-dropdown .custom-select:focus-within,
.custom-dropdown .custom-select:active,
.custom-dropdown .custom-select:focus {
}

.custom-dropdown .MuiSelect-select:focus {
}

.MuiSelect-select {
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #888 !important;
  font-weight: 300 !important;
  border-radius: 10px !important;
}

.MuiInputLabel-root {
  color: #000;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.MuiSvgIcon-root {
  color: #999;
}
