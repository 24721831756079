.services_sidebar {
  width: 20%;
  height: 100%;
  max-height: 95vh;
  padding: 0px 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;

    &:hover {
      background: #555;
    }
  }
}

.sidebar-heading {
  font-size: 18px;
  margin-bottom: 10px;
}

.services_accordion_details p {
  margin: 0;
  font-size: 11px;
  color: #999;
}

.services_accordion_details-main-heading {
  width: 98%;
  display: flex;
  gap: 10px;

  .service_title_side {
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1180px) {
  .services_sidebar {
    width: 100%;
    padding: unset;
  }
}

.newClinicsBtn {
  background-color: #000000;
  padding: 8px 14px 8px 14px;
  border-radius: 10px;
  color: white;
  margin-top: 16px;
  width: 100%;
  font-size: 12px;
}

.sidebar-hamburger {
  background-color: #eee;
  padding: 5px 10px;
  margin: 10px -10px 0 0;
  border-radius: 10px;
  z-index: 99999;
  position: relative;
  display: none;
}

@media only screen and (max-width: 1025px) {
  .services_sidebar {
    display: none;
  }

  .show-sidebar {
    display: block;
    position: absolute;
    width: fit-content;
    background: white;
    left: 0;
    z-index: 9999;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  .settings_breadcrumb {
    justify-content: end;
    margin-left: 0px;
    margin-top: 15px;
  }

  .settings_content_wrapper {
    width: 100%;
    padding: 0 25px;
  }

  .services-ham {
    display: block !important;
    width: fit-content;
    margin: 0;
  }

  .services_sidebar.show-sidebar {
    width: 300px;
    padding: 80px 20px 20px 20px;
    top: 40px;
    min-height: 100vh;
  }

  .services-details-area {
    padding: 10px;
  }
}
