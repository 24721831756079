.custom-modal-width {
  max-width: 1000px !important;
  border-radius: 20px !important;
}

.modal-height {
  height: 580px;
}

.inputDiv {
  width: 100%;
  display: flex;
  border-radius: 15px;
}

.inputDiv-dark {
  width: 100%;
  display: flex;
  border-radius: 15px;
}

.searchPatient {
  width: 100%;
  height: 30px;
  outline: none;
  background: transparent;
  border: none;
  padding: 10px;
  font-size: 14px;
}

.searchPatient {
  width: 100%;
  height: 30px;
  outline: none;
  background: transparent;
  border: none;
  padding: 10px;
  font-size: 14px;
}

.searchPatient-dark {
  width: 100%;
  height: 55px;
  outline: none;
  background: transparent;
  border: none;
  padding: 10px;
  color: white;
  font-size: 14px;
}

.searchIcon {
  margin-top: 4px;
  margin-left: 11px;
}

.parent {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.patients {
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 40%;
}

.patientDetails {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 60%;
  border-left: 1px solid lightgrey;
}

.custom-modal-header {
  padding: 0; /* Remove the default padding from the Modal.Header */
}

.patientListHeading {
  font-size: 14px;
  font-weight: bold;
}

.addPatient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 35px;
  padding-top: 5%;
}

.patientList {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  overflow-y: auto;
  height: 100%;
  max-height: 285px !important;
}

.rowPatient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55px;
  padding: 13px;
  border-radius: 15px;
  margin-top: 10px;
  cursor: pointer;
}

.rowPatient > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.patientName {
  font-size: 15px;
}

.date {
  max-width: 150px;
  display: flex;
  font-size: 12px;
  border: none;
  width: fit-content;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.prescriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 35px !important;
  padding-top: 5%;
}

.prescriptionsHeading {
  font-size: 14px;
  font-weight: bold;
}

.addPrescriptions {
}

.prescriptionList {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.rowPrescription {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 55px;
  padding: 13px;
  border-radius: 15px;
  margin-top: 10px;
}

.rowPrescription > div {
  display: flex;
  align-items: center;
}

.prescriptionName {
  font-size: 15px;
}

.suffix-button {
  max-width: 100px;
  width: 100%;
  height: 30px;
  border-radius: 10px;
}

.patientDetails {
  display: flex;
  flex-direction: column;
}

.patientContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: dimgrey;
  border-radius: 50%;
}

.initials {
  font-size: 28px;
  color: white;
}

.patientDetailsName {
  padding-top: 10px;
  font-weight: bold;
}

.patientDetailsDate {
  font-size: 15px;
  font-family: sans-serif;
  color: darkgrey;
  padding-top: 10px;
}

.detailDigits {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}

.rectangle {
  position: relative;
  width: 23px;
  height: 25px;
  border-radius: 4px;
  margin: 0px 2px 0px 2px;
}

.rectangle-dark {
  position: relative;
  width: 23px;
  height: 25px;
  border-radius: 4px;
  margin: 0px 2px 0px 2px;
}

.digit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: 24px;
  font-weight: bold; */
  /* color: black; */
  font-weight: bold;
}

.cross-icon {
  cursor: pointer;
  position: relative;
  top: 14px;
  right: 10px;
}

.digitSectionOne {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}

.digitSectionTwo {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}

.digitSectionThree {
  display: flex;
  flex-direction: row;
}

.address {
  padding-top: 10px;
}

.address p {
  color: lightgray;
  padding-top: 10px;
}

.nominations {
  margin: 10px 15px 10px 15px;
  height: 100px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.nominations-dark {
  margin: 10px 15px 10px 15px;
  height: 100px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.nominationHeading {
  font-weight: bold;
}

.nominationChild {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
}

.nominationAddress p {
  color: grey;
  margin: 0px 10px 0px 10px;
}

.tags {
  margin: 10px 15px 10px 15px;
  height: 100px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tags-dark {
  margin: 10px 15px 10px 15px;
  height: 100px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tagsChild {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
}

.tagSection {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-evenly;
  margin-top: -10px;
}

.singleTag {
  background-color: #d1dee7;
  border-radius: 15px;
  font-weight: bold;
  font-size: 13px;
  height: 30px;
  color: #0088ff;
}

.suffix-button-dark {
  border: 1px solid white;
  width: 100px;
  height: 30px;
  margin-top: 12px;
  margin-right: 20px;
  border-radius: 10px;
  color: white;
}

.go-to-dashboard {
  background-color: white;
  color: #000;
  font-size: 12px;
  padding: 0;
}

.noRecordFound {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.closeBtn {
  display: flex;
  flex-direction: column;
  align-items: end;
  cursor: pointer;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
  background: var(--footer-color) !important;
  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: 10px 30px;
}

.noRecordFoundWhite {
  color: white;
}

.noRecordFoundBlack {
  color: black;
}

.searchPatient-darkMode {
  color: white;
}
