.note-box {
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 20px;
  border: 1px solid rgb(73, 189, 228);
  border-radius: 30px;
  margin-bottom: 20px;

  input {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.note-box-add {
  background-color: rgb(73, 189, 228);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 20px;

  > svg {
    color: #fff;
    transform: rotate(90deg);
  }
}

.note-item {
  background: rgb(224, 244, 250);
  padding: 10px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 12px;

  .edit-field{
    display: flex;
    align-items: center;
    border: 1px solid var(--pres-border);
    margin-top: 15px;
    border-radius: 11px;
    padding: 0 10px 0 0;

    input{
      color: var(--h2-color);
      background-color: transparent !important;
      border: 0;
      font-size: 14px;
      margin: 0;

      &:focus{
        outline: none;
      }
    }

    .note-confirm-close {
      display: flex;
      gap: 10px;
    }

    svg{
      font-size: 20px !important;
      cursor: pointer;
    }
  }
}

.note-item-wrapper input {
  width: 100%;
  border-radius: 8px;
  border: none;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  font-size: 14px;
}

.note-content {
  position: absolute;
  top: 5px;
  right: 15px;

  &:hover {
    cursor: pointer;
  }
}

.title-note {
  color: rgb(86 194 228);
}

.note-item-wrapper {
  width: 100%;
}

.notes-footer {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  margin-top: 10px;

  p {
    color: #aaa;
  }

  > div {
    padding-left: 20px;
    border-left: 1px solid #ddd;
    display: flex;
    align-items: baseline;
    gap: 20px;
  }
}

p.note-date {
  padding-right: 20px;
}

.note-item svg.MuiSvgIcon-root {
  font-size: 16px;
}



.note-item-message {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}


.awaiting-query {
  background-color: rgb(250, 234, 236);
  color: rgb(217, 56, 75);

  p {
    color: rgb(217, 56, 75);
  }
}

.pinned {
  background-color: rgb(75 129 34);
  color: rgb(248, 207, 135);

  p {
    color: rgb(248, 207, 135);
  }

  .pin-icon {
    transform: rotate(640deg);
  }
}

.notes-menu .MuiPaper-root {
  padding-left: 20px !important;

  .MuiButtonBase-root {
    padding-left: 10px;
    margin-left: 20px;

    font-size: 14px;

    &:hover{
      background-color: var(--menu-btn);
    }
  }
}

.notes-check-icon {
  position: absolute;
  left: -30px;
}


/*Clinical Checks*/


.clinicalchecks{
  .note-box{
    margin: 10px 6px;
    
    input{
      width: 100%;
      border: none;
      background: var(--outer-item) !important;
      font-size: 14px;
    }
  }

  .note-item-container{
    margin: 10px 6px;

    .note-item{
      background: var(--inner-card-bg-color) !important;
      border-radius: 11px;
      border: 2px solid var(--label-border-color);
    }

    .title-note{
      font-size: 12px;
    }

    .note-value p{
      font-size: 14px;
    }
  }

  .note-item-container.pinned-message{
    .note-item{
      border-radius: 0 11px 11px 11px;
    }
  }
  .note-item-container.awaiting-query-message{
    .note-item{
      border-radius: 0 11px 11px 11px;
    }
  }

  .note-item-message.pinned{
    border-radius: 11px 11px 0 0;
    width: fit-content;
    font-size: 10px;
    gap: 4px;
    padding: 5px 8px;

    background: var(--pinned-label);
  }
  .note-item-message.awaiting-query{
    border-radius: 11px 11px 0 0;
    width: fit-content;
    font-size: 10px;
    gap: 4px;
    padding: 5px 8px;

    background: var(--awaiting-query-label);
  }
}

.clinicalchecks-notes-menu{
  .MuiPaper-root{
    padding: 0px !important
  }

  .MuiButtonBase-root{
    font-size: 14px;
    margin: 0 !important;
    padding: 10px !important;

    &:hover{
      background-color: var(--menu-btn);
    }
  }

  

  hr{
    color: #999;
  }
}
