.document-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(25% - 16px);
  display: inline-block;
  box-sizing: border-box;
  margin: 8px;
  cursor: pointer;
}

.icon-container {
  width: 100%;
  text-align: center;
}

/* .pdf-icon {
  width: 100%;
  height: 130px;
} */

.title-container {
  text-align: center;
  width: 100%;
  padding: 8px 0;
}

.card-title {
  font-size: 18px;
}

.document-container {
  .card-content {
    border-radius: 8px;
    height: 180px;
  }

  a.card {
    border: none;
    background: none;
  }
}

.file-drop-zone {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 15px;
  color: #666;
  cursor: pointer;
  padding: 20px;
  transition: background-color 0.3s ease;

  &.dragging {
    background: white;
    border: 2px solid #ccc;
  }
}

@media (max-width: 768px) {
  .file-drop-zone {
    padding: 10px;
  }
}

.upload_button {
  button {
    min-width: 150px;
    background-color: #111;
    color: #f0f0f0;
    border-radius: 15px;
    margin: 20px;
    height: 50px;
  }

  margin-bottom: 20px;
}

.upload_file_Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* padding: 20px; */

  .files_details {
    display: block;
    font-size: 12px;
    font-family: "SF-Pro Regular", sans-serif !important;
  }

  .file_validation_error {
    color: red;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .card_row {
    display: flex;
    flex-direction: column;

    .document-container {
      width: 100%;
    }
  }
}
