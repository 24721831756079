.services_listview {
  .stages-wrapper {
    flex-direction: column;
  }

  .MuiAccordion-root {
    box-shadow: none;
    padding: 20px 0;
  }
}

.stage .MuiAccordionDetails-root {
  padding: 0 !important;
}

.services_listview {
  .service_item {
    margin: 0;
    padding: 5px;
    border-radius: 10px;
    transition: 0.2s linear;
  }

  .services_list {
    padding: 10px 15px;
  }

  .MuiAccordion-root:first-child {
    padding-top: unset;
  }

  .MuiPaper-root {
    background: none;
    border: none;
  }

  .MuiButtonBase-root {
    padding: 0;
  }
}

.services_accordion-header {
  background-color: #f2f2f2;
  border: none;
  box-shadow: none;
  flex-direction: row-reverse;
}

.services_listview {
  .data-box-container {
    border: none;
    border-radius: 20px;
  }

  svg {
    margin-right: 5px;
  }
}

.services_bottom {
  display: flex;
  align-items: start;
  gap: 8px;
}

.services_listview-service {
  border: 1px solid #eee;
  padding: 5px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

span.services_listview-date {
  padding: 2px 6px;
  border-radius: 30px;
  font-size: 10px;
}

.services_listview .service_item:hover {
  background-color: #9f9d9d;
  transition: 0.2 linear;
}

.dueDate_accordion {
  border-radius: 15px !important;
  background: none !important;
  box-shadow: none !important;
}

.service_item .name-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

p.services_kanban-serviceName {
  max-width: 265px;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.services_listview-service {
  justify-content: space-between;
}

.listview-accordion-right {
  display: flex;
  gap: 10px;
  align-items: center;
}

span.p-tag-value {
  font-size: 12px;
}

.tag-light-text {
  color: #000000 !important;
}

.tag-dark-text {
  color: #fff !important;
}

.patient-tag-container-kanban {
  border-radius: 40px;
}

@media screen and (max-width: 600px) {
  .services_iconboxService span {
    max-width: unset !important;
    text-wrap: balance;
  }

  .service_item {
    span {
      max-width: unset !important;
      text-wrap: balance;
    }

    flex-direction: column;
  }

  .listview-accordion-right {
    flex-direction: column;
  }
}

span.p-tag.p-component.p-tag-custom.custom_tag_succes {
  border-radius: 40px !important;
}

.patient-tag-main-container {
  display: flex;
}

span.p-tag.p-component.p-tag-custom {
  border-radius: 40px !important;
}

.service_tags_list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}

.service_contact_no {
  p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    text-decoration: underline;
  }

  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  border: 1px dashed #e96a6a;
  margin-bottom: 10px;
  padding: 5px;
}

.checkbox_name {
  margin-bottom: 5px;
  gap: 10px;
  display: flex;
  align-items: center;
}

.list_view_tags {
  margin-left: 20px;
  margin-bottom: 0px;
}

.contact_listview {
  cursor: pointer;
  margin-bottom: 0px !important;
}

.sms_sent_text {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.lock_days {
  display: flex;
  align-items: center;
  gap: 5px;
}
