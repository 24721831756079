.ov_page_wrapper {
  display: flex;
  height: 100vh;
  gap: 30px;
  padding: 20px;
}

.ov_sidebar p {
  margin: 0;
}

.ov_sidebar .heading {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ccc;

  margin-bottom: 10px;
}

.ov_sidebar .heading .title {
  font-size: 14px !important;
  font-weight: 700;
  padding: 0 !important;
}

.ov_sidebar .heading .number {
  font-size: 11px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ov_sidebar .step_wrapper.active .heading .number {
  border: 1px solid #000;
}

.ov_sidebar .steps {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  max-width: 320px;
}

.ov_sidebar .steps .step_wrapper {
  padding: 20px;
  border-radius: 16px;
}

.ov_sidebar .step_wrapper.disabled p {
  color: #ddd;
}

.ov_sidebar .steps .step_wrapper p {
  font-size: 12px;
  line-height: 19px;
}

.ov_page_wrapper .main_content {
  border-radius: 16px;
  width: 100%;
  padding: 50px 20px 20px 20px;
}

.ov_main_content_container {
  position: relative;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
  padding-top: 100px;
}

.ov_inner_wrapper {
  margin: 0 auto;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  width: 500px;
}

.ov_welcome_wrapper h1 {
  font-weight: bolder;
}

.ov_footer {
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: white;
}
.otc_footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.ov_inner_wrapper .MuiFormControl-root {
  width: 100%;
}

.radio-flex {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.radio-flex p {
  margin: 0;
}

.radio-flex .MuiFormGroup-root {
  display: block;
}

.ov_your_details_wrapper .upload__image-wrapper {
  width: 100%;
  border: 2px dashed #000;
  padding: 20px 0;
}

.ov_your_details_wrapper .upload__image-wrapper p {
  margin: 0;
}

.ov_your_details_wrapper .upload__image-wrapper .upload-btn {
  display: flex;
  gap: 10px;
  margin: 0;
}

.ov_your_details_wrapper .uploaded-img {
  width: 80px;
  height: 80px;
  border: 3px solid #000;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}

.ov_your_details_wrapper .image-item p {
  font-size: 12px;
  margin-top: 10px;
}

.ov_your_details_wrapper .image-item .image-item__btn-wrapper {
  justify-content: space-between;
  gap: 20px;
}

.terms h1 {
  font-weight: bolder;
}

.terms h5 {
  font-weight: bolder;
  margin: 20px 0;
}

.ov_main_content_container::-webkit-scrollbar {
  width: 5px;
}
.ov_main_content_container::-webkit-scrollbar-track {
  background-color: #dfdfdf !important;
}

@media only screen and (max-width: 1400px) {
  .ov_page_wrapper .main_content {
    padding: 20px;
  }

  .ov_main_content_container {
    padding-top: 0;
  }
}
