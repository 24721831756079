.newClinicsContainer {
  width: 79%;
  height: 92%;
}

.services_container-content-area {
  position: relative;
  max-height: 88vh;
  height: 86vh;
  margin-top: -10px;
}

.newClinicsContainerWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.services_container-tabs {
  padding: 10px 0;
  background: unset !important;
}

.services_content-bg {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.service_content-bg {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popularServicesHeading {
  font-size: 16px;
  margin: 0 0 15px 0 !important;
  font-weight: 590 !important;
}
.popularServices
{
  padding: 15px;
}
.innerServices {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .serviceCard, .multipleServiceCard {
    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.cardHeading {
  background-color: #ffffff;
  font-size: 14px;
  color: #000;
  padding: 5px 5px 5px 8px;
  border-radius: 10px;
  font-weight: 500;
  margin: 15px;
  display:flex;
  justify-content: space-between;
}

.cardDetailHeading {
  background-color: rgba(255, 255, 255, 0.6); /* white with 60% opacity */
  font-size: 14px;
  color: #000;
  padding: 5px 5px 5px 8px;
  border-radius: 10px;
  font-weight: 500;
  border-radius: 6px;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-weight: bold;
  font-size: 18px;
}

.serviceCard {
  position: relative;
  border-radius: 14px !important;
  cursor: pointer;
  background-image: linear-gradient(140deg, rgba(0, 94, 184, 0.3), rgba(0, 94, 184, 0.1));
}

.status_tag {
  margin: 5px;
  border: 1px solid #91de91;
  background: #91de91;
  border-radius: 10px;
  padding: 5px;

  /* text-align: center; */
}

.service_status {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.serviceCard_enabled {
  position: relative;
  background-image: linear-gradient(140deg, rgb(40, 228, 10, 0.3), rgba(0, 94, 184, 0.1)) !important;
}

.multipleServiceCard {
  padding: 12px !important;
  position: relative;
  border-radius: 14px !important;
  background-image: linear-gradient(140deg, rgba(0, 94, 184, 0.3), rgba(0, 94, 184, 0.1));
  flex: 0.2;
  margin: 16px;
}

.myCard {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  // background-image: url("/src/assets/images/serviceBackground.png");
}

.serviceDetailCard
{
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  height: 200px;
  padding: 8px;
}

.serviceIcon, .serviceIconP {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 7rem;
  justify-content: center;
}

.mainDetailcont

{
  overflow: scroll;
    padding: 15px 15px 0 15px;
    height: 100%;
}

.nhsserviceIcon {
  width: 45px;
  height: 25px;
  margin: 0 0 10px 20px;
}

.lowerIconBar {
  display: flex;
  align-items: center;
  margin: 15px auto;
}

.privateCard {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.PrivateserviceCard {
  padding: 12px !important;
  position: relative;
  border-radius: 14px !important;
  background-image: linear-gradient(140deg, rgba(0, 94, 184, 0.3), rgba(0, 94, 184, 0.1));
  flex: 0.3;
  margin: 16px;
}

.PrivatecardHeading {
  background-color: #ffffff;
  font-size: 16px;
  color: #000;
  padding: 5px 5px 5px 8px;
  border-radius: 10px;
  font-weight: 500;
}

.rounded-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;

  img {
    width: 100%;
    height: auto;
  }
}

.details {
  flex: 1;
}

.createdBy {
  font-size: 12px !important;
  margin: 0;
  color: #888888;
  font-weight: 400 !important;
}

.createdByName {
  font-size: 12px !important;
  font-weight: 590 !important;
  margin: 0;
  color: #888888;
}

// .nhs-section {
//   margin-top: 40px !important;
// }

.innerServiceMargin {
  margin-top: 15px !important;
}

// .privateMargin {
//   margin-top: 40px !important;
// }

.makeYourOwnBtn {
  width: 117px;
  height: 23px;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  border-radius: 10px;
}

.makeYourOwn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 17px;
}
.privateServices
{
  padding: 15px;
}
.privateServices .serviceCard {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.serviceStages{
  width: 49%;
  height: 150px;
  border-radius: 10px;
  background-color: var(--service-stage-bg);
  padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: "SF-Pro Regular", sans-serif !important;
}
.serviceDateStatus
{
  width: 49%;
  height: 150px;
  border-radius: 10px;
  background-color:var(--service-stage-bg);
  padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
font-family: "SF-Pro Regular", sans-serif !important;
    
}
.mainServiceDetails {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content:space-around;
}
.templatesMain
{
  margin-top: 20px;
}

.serviceStageContent
{
  color: #606060;
}

.serviceDateStatusContent
{
  color: #606060;
}

.serviceStageHeading
{
  color: var(--service-stage-inner-heading);
  font-family: "SF Pro Regular", sans-serif !important;
  font-weight: bold;
}

.serviceDateStatusHeading
{
  color: var(--service-stage-inner-heading);
  font-family: "SF Pro Regular", sans-serif !important;
  font-weight: bold;
}

.questionHeading
{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 15px;
    align-items: center;
}

.questionHeading p
{
  font-family: "SF Pro Regular", sans-serif !important;
  font-size: 14px;
  font-weight: bold;
}

.sectionTitle
{
  margin-left: 15px;
    margin-top: 15px;
}

.sectionTitle p

{
  font-family: "SF Pro Regular", sans-serif !important;
  font-size: 14px;
  font-weight: bold;
}

.templateName
{
  margin-left: 15px;
    margin-top: 15px;
}

.templateName p
{
  font-family: "SF Pro Regular", sans-serif !important;
  font-size: 24px;
  font-weight: bold;
}

.cus-template-name
{
  font-family: "SF Pro Regular", sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin-left: 15px !important;
}

.cus-title-section
{
  margin-top: 10px;
}

.cus-template-title
{
  margin-left: 15px !important;
  font-weight: bold !important;
  // font-family: "SF Pro Regular", sans-serif !important;
  display: flex;
    align-items: center;
    gap: 5px;
}


.service-detail-template-name
{
  // font-family: "SF Pro Regular", sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.service-detail-template-title
{
  font-weight: bold !important;
  font-family: "SF Pro Regular", sans-serif !important;
}