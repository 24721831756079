.settings_wrapper {
  padding: 10px 15px;
  display: flex;
}

.settings_heading {
  margin-top: 10px;

  h1 {
    font-weight: 600;
    margin-top: 10px;
    font-size: 25px;
  }
}

.settings_breadcrumb {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
}

.settings_page .tabs_box {
  padding: 10px;
}

.settings_breadcrumb {
  transition: 0.1s linear;

  &:hover {
    cursor: pointer;
    font-weight: 600;
    transition: 0.1s linear;
  }
}

.sidebarSettings_wrapper {
  padding: 10px 10px 30px 10px;
  width: 15vw;
  min-height: 100%;
  height: 95vh;
  overflow: hidden;
  overflow-y: scroll;
}

.sidebarSettings_listItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iconTitle {
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    margin: 0;
  }
}

a.sidebarListParent {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 10px;
  font-weight: 300;
}

.submenu a {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 10px;
  font-weight: 300;
  padding: 10px;
}

a.sidebarListParent:hover,
.submenu a:hover {
  text-decoration: none !important;
}

a {
  &.sidebarListParent span.icons-color-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
  }

  &.selectedSidebarItem span.selected-icon > svg {
    color: #fff;
  }

  &.sidebarListParent.selectedSidebarItem {
    background: #222;
  }
}

.submenu a.selectedSidebarItem {
  background: #222;
}

a.sidebarListParent.selectedSidebarItem p,
.submenu a.selectedSidebarItem p {
  color: #fff;
}

.sidebarSettings_listItems .submenu {
  margin-top: 10px;
  margin-left: 35px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settings_content_wrapper {
  width: 85vw;
  height: 95vh;
  padding: 0 30px 80px 30px;
}

.template_content_wrapper {
  width: 85vw;
  height: 95vh;
  padding: 0 10px 0px 10px !important;
}

.communication_wrapper {
  padding: 0 0px 0px 0px !important;
}

.settings_content_area {
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  max-height: 85vh;
  min-height: 85vh;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;

    &:hover {
      background: #555;
    }
  }
}

/* width */

/* Track */

/* Handle */

/* Handle on hover */

.accordion-group {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .MuiAccordion-rounded {
    box-shadow: none;
    border-radius: 10px !important;
  }

  p.service_title {
    margin-top: 7px;
  }
}

.locations_list_area {
  .pharmacy_titleBox {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    width: 100%;
  }

  .pharmacy_title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -5px;
  }

  span {
    &.pharmacy_room_count {
      font-size: 12px;
      color: #adadad;
    }

    &.services_count {
      font-size: 12px;
      color: #adadad;
      margin-left: 20px;
    }
  }

  .pharmacies-box-container {
    border-radius: 15px;
    margin: 0 35px 20px 35px;
  }
}

.rooms_listBox {
  margin-top: 10px;
}

.locations_list_area .room_name {
  font-size: 14px;
  margin-bottom: -5px;
}

.addNew_cta {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.2s linear;
  cursor: pointer;
}

.members_wrapper .addNew_cta,
.allRoles_wrapper .addNew_cta {
  font-size: 16px;
}

.addNew_cta:hover {
  transition: 0.2s linear;
  font-weight: 500;
}

.back_to_SettingsListView {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: 0.2s linear;
  width: fit-content;
  margin-top: 20px;

  &:hover {
    font-weight: 500;
    transition: 0.2s linear;
  }
}

.locations_general_info {
  margin-top: 50px;
  margin-top: 20px;
  width: 100%;

  /* display: flex; */
  gap: 50px;
}

.locations_select_services {
  margin-top: 20px;
  width: 100%;

  /* display: flex; */
  gap: 50px;
  margin-top: 40px;

  .services_list {
    columns: 3;
    font-family: "SF-Pro Regular", sans-serif !important;
  }
}

.settings_formFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 10px;
}

.color_pick_section {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 30px;
}

.selected-color-p {
  font-size: 14px;
  margin-top: 15px;
}

span.selected-color {
  color: #000;
  padding: 5px;
  border-radius: 5px;
}

.allView_list_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  color: var(--h2-color);
}

.allView_listview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.allView_container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 10px !important;
  align-items: center;
}

.allView_name {
  font-size: 14px;
  margin-bottom: -2px;
}

.allView_occupation {
  color: #adadad;
  font-size: 13px;
}

.member_edit_icon {
  cursor: pointer;
}

.addNewMember_wrapper {
  margin-top: 20px;
}

.addMember_formFields {
  margin-top: 30px;

  .settings_formFields {
    margin: unset;
  }

  .title_field {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .divider {
    height: 2px;
    width: 60%;
    display: block;
    background-color: #ddd;
    margin: 60px auto;
  }

  .inner-panels {
    display: flex;
    flex-direction: column;
    gap: 25px;

    h4 {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
}

.title-select label {
  background-color: white;
}

.stage_divider {
  background: black;
  margin-top: 30px !important;
}

.userDetails_fields {
  display: flex;
  gap: 20px;
}

.close_icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.userDetails_fields > div {
  width: 50%;
  margin-top: 30px;

  &:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 5px;
  }
}

.user_clinician {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
}

.upload__image-wrapper {
  border: 2px dotted #000000;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .controls {
    text-align: center;
    cursor: pointer;
  }

  .upload-btn {
    text-align: center;
    display: block;
    color: black;
    font-size: 16px;
  }

  .uploaded-img {
    width: 150px;
    height: 200px;
    text-align: center;
  }
}

.uploaded-img > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.upload__image-wrapper .upload-btn {
  margin-top: 30px;
}

.image-item .image-item__btn-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.members_services_provided .services_list {
  column-count: 3;
}

.notification_setting_wrapper .switch_row {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 15px;
    font-weight: 500;
  }
}

.AddNew_sectionbox {
  padding: 20px;
  border-radius: 15px;
}

.membersSchedule_header {
  width: 100%;
  display: flex;
  justify-content: space-between !important;

  > div:first-child {
    display: flex;
    align-items: center;
  }
}

.footer_float {
  position: sticky;
  bottom: 0;
  background: #dfdfdf;
  width: 100%;
  padding: 10px;
}

.footer_btn {
  width: 100%;
  text-align: right;
  margin-top: 30px;
}

.template_footer_button {
  margin-top: 5px !important;
}

.add_section {
  margin: 20px 0px;
}

.settings_content_area .duration {
  justify-content: space-between;

  .p-dropdown {
    width: 100% !important;
  }
}

.allView_title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.service-color {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.type_color_picker {
  position: absolute;
  width: 200px;

  .chrome-picker {
    position: absolute;
  }
}

.color_pick_type {
  display: flex;
}

.types_confirm_cancel .title_field {
  margin: unset;
}

.members_list_title {
  display: unset;
}

.company_info_details {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .company_info_right_panel,
  .company_info_left_panel {
    width: 50%;
  }
}

.label_tag {
  font-size: 16px;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-weight: 510;
}

.label_tag_data {
  font-size: 14px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.settings_content_area {
  h3 {
    font-size: 20px !important;
    font-weight: 500 !important;
    margin: 0 !important;
  }
}

.servicetypes_content_wrapper {
  border-radius: 10px;
  padding: 20px;
  margin-top: 15px;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;

  .servicetype_listbox {
    .allView_list_header {
      margin-bottom: 10px;

      h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
      }
    }

    .allView_listview {
      margin: 0;
      gap: 12px;
    }

    .allView_container {
      padding: 10px 15px;
      font-weight: 300;
      cursor: pointer;
      transition: 0.1s linear;
    }

    .allView_container:hover {
      background-color: var(--global-bg-color);
      transition: 0.1s linear;
    }
  }
}

.allEmails_wrapper {
  .allView_container {
    padding: 10px 15px;
    font-weight: 300;
    cursor: pointer;
    transition: 0.1s linear;
  }
}

.servicetypes_tabs_box {
  display: unset;

  .MuiTabs-scroller {
    height: 45px;
  }

  .d-flex {
    align-items: center;
    gap: 10px;

    svg {
      cursor: pointer;
    }
  }
}

.privateservices_formFields {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .description {
    font-size: 12px;
    color: #b4a8a8;
  }

  .clipboard-field {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.box-value {
  color: #888;
  font-size: 14px;
}

.privateservices_one_layout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.privateservices_two_layout {
  .d-flex {
    margin-top: 15px;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
    }

    .membersAddNew_box {
      width: 100%;
    }
  }

  .mt-15 {
    margin-top: 15px;
  }

  .settings_formFields > input {
    color: #888 !important;
    font-size: 14px;
  }
}

.privateservices_two_layout.colors {
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0;
  }

  .membersAddNew_box {
    background-color: var(--settings-fields);
    border-radius: 15px;
    border: 2px solid var(--label-border-color);
    padding: 10px 14px;

    position: relative;
  }

  .d-flex {
    margin: 0;
    justify-content: space-between;
  }
}

.patient-info-container {
  padding: 0;

  .patient-info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .settings_wrapper > :first-child {
    position: absolute !important;
  }

  .back_to_SettingsListView {
    margin-left: 15px;
  }

  .locations_select_services .services_list,
  .members_services_provided .services_list {
    display: flex;
    flex-direction: column;
  }

  .range,
  .membersSchedule_header,
  .duration {
    flex-direction: column !important;
  }

  .settings_formFields {
    display: flex !important;
    flex-direction: column;
  }

  .userDetails_fields {
    flex-direction: row;
  }

  .type_fields,
  .temp_content {
    flex-direction: column;
  }

  .temp_head,
  .temp_body {
    width: 100%;
  }

  .endorsement-box {
    width: 400px !important;
  }

  .settings_content_wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .sidebarSettings_wrapper {
    width: 35vw;
  }

  .settings_formFields {
    display: flex;
    flex-direction: column;
  }

  .color_pick_section_type {
    flex-direction: column;
  }
}

.title_field {
  .template_input,
  .title-select {
    width: 100%;
  }
}

.membersAddNew_box .template_input,
.locations_general_info .template_input {
  width: 100%;
}

// -----------------------Email template Css------------------------------------------------------------

.email_template_content_wrapper {
  display: flex;
  gap: 20px;

  .email-template-content {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .counter-wrapper {
      text-align: right;
      color: #6c6c6c;
      font-size: 14px;
      margin-right: 5px;
    }
  }

  .email-template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
  }

  .active-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  textarea {
    flex-grow: 1;
    border-radius: 10px;
  }

  .action-toggle-wrapper {
    display: flex;
  }

  span {
    font-weight: 510;
  }

  .placeholder_label {
    font-weight: 600 !important;
  }

  .placeholder_content {
    color: #999;
  }

  .placeholder_tags {
    overflow-y: auto !important;
    height: 600px;
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  .sidebar {
    width: 300px;
  }

  .edit-preview-toggle {
    display: flex;
    justify-content: flex-end;
  }
}

.template-header {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.AddNew_sectionbox {
  .template_title {
    margin-bottom: 50px;
  }
}

.template_heading {
  margin-left: 25px !important;
}

// --------------------Email default template------------------------------------------------------

.email-template-main-wrapper {
  .template-header {
    margin-bottom: 30px;
  }

  .template-title {
    margin-bottom: 30px;
  }

  .template-title-content {
    font-size: 20px !important;
    line-height: 15px !important;
    font-weight: 600;
  }

  .template-body {
    font-size: 15px;

    .lets_get_started {
      background-color: #b93dcf;
      padding: 10px 40px;
      border-radius: 12px;
      text-align: center;
      color: #fff;
      width: 100%;
      border: none;
      font-size: 16px;
      line-height: 24px;
      margin-top: 30px;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transition: 0.2s linear;
    }
  }

  .template-footer {
    width: 85% !important;
    margin: 50px auto !important;
    border-top: 1px solid #aaa !important;
    padding-top: 30px !important;
    text-align: center !important;

    .copyright {
      margin-top: -10px !important;
    }

    p {
      color: #aaa !important;
    }
  }
}

.allView_checkbox
{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.allView_action_btns
{
  display:flex;
  gap: 10px;
}

.allView_actions
{
  display: flex;
  gap: 10px;
}

.allView_checkbox .template-email-checkbox
{
  padding: 0px !important;
}