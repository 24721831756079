.startchecks_container {
  position: absolute;
  bottom: 30px;
  right: 25px;
  z-index: 3;
}

.startchecks_button {
  background-color: #000;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin-right: 30px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}

.startchecks_button-counter {
  background-color: #fff;
  color: #000 !important;
  padding: 2px;
  border-radius: 3px;
}

.startNMSCount {
  width: 45px;
  max-width: 45px;
  height: 29px;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  border-radius: 5px;
  padding-top: 7px;
  align-items: center;
  text-align: center;
  color: #000000;
  background: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}
