.bottomActionBar_container {
  position: fixed;

  /* z-index: 2; */
  z-index: 0 !important;
  margin: auto;
  right: 0;
  bottom: 20px;
  left: 0;
  width: fit-content;
  transform: translateY(0);
  transition: transform 0.8s linear, opacity 0.8s linear;

  &.hidden {
    transform: translateY(100%);
    opacity: 0;
  }
}

.bottomActionBar_wrapper {
  padding: 9px 24px;
  height: 130px;
  backdrop-filter: blur(25px);
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomActionBar_wrapper_left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 30px;
}

.bottomBar_image-box {
  position: relative;
}

.bottomBar_image-container {
  width: 50px;
  height: 50px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}

.bottomBar_image-box:hover {
  cursor: pointer;
}

/* .bottomBar_image-box:hover p.bottomBar_image-box-title {
  font-weight: bold;
  transition: 0.2s linear;
} */

.bottomBar_countContainer {
  z-index: 2;
  background: #ffffff;
  border: 1px solid #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  width: 32px;
  max-width: 32px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -5px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  transition: 0.3s ease-in-out;

  p {
    margin: 0;
    color: #222;
  }
}

.bottomBar_img {
  position: absolute;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.3));
  object-fit: cover;
  transition: 0.3s ease-in-out;
  z-index: 1;
}

.sparklesAnimation {
  z-index: 4;
}

.bottomBar_image-box-title {
  margin-top: 20px;
}

p.bottomBar_image-box-title {
  margin: 0;
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  transition: 0.2s linear;
}

.checksImg {
  top: 3px;
  width: 42px;
  transform: rotate(9.15deg);
}

.NMSImg {
  top: 3px;
  width: 30px;
  transform: rotate(-12.67deg);
}

.CXImg {
  width: 50px;
}

.rotate {
  transform: rotate(20deg) scale(1.08);
  transition: 0.3s ease-in-out;
  overflow: visible;
}

.grow {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.overflow-visible {
  overflow: visible;
  transition: 0.3s ease-in-out;
}

.shrinkRotate {
  transform: scale(0) rotate(270deg);
  transition: 0.5s ease-in-out;
}

.actionIconGroup {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  border-left: 1px solid #ccc;
  padding-left: 30px;
}

.actionIconGroup_iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 6px;
  width: 45px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid #000;
  }
}

.actionIconGroup_iconName {
  background-color: black;
  font-size: 8px;
  padding: 2px 4px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 50px;
  width: 80px;
  display: none;
  z-index: 2;

  p {
    margin: 0;
    color: #fff;
  }
}

.actionIconGroup_iconBox:hover .actionIconGroup_iconName {
  display: block;
}

.actionIconGroup_iconBox_active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 6px;
  width: 45px;
  height: 45px;
  background-color: #222;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 10px;
  position: relative;
}

.fadeBottomActionIcons {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.bottomActionBar_wrapper_left_alt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .bottomBar_image-box-title {
    font-weight: 600;
  }
}

.clinicalImg {
  width: 50px;
  position: absolute;
}

.operationsImg,
.financeImg,
.efficiencyImg {
  width: 30px;
  position: absolute;
}
