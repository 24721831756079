/* Style for the user profile card */
.medicine-card {
  margin-bottom: unset;
}

/* Style for the user's name */

/* Style for the user's date of birth and sex */
.medicine-card p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}

/* Style for the user's allergies */
.medicine-card p:last-child {
  margin-bottom: 0;
}

/* Style for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.medicine-info {
  display: flex;
  padding: 10px;
}

.medicine-label {
  font-weight: bold;
}

.prescription-pills {
  margin-right: 10px;
}

.quantity {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 55px;
  padding: 6px 15px;
  justify-content: center;
}

.dose {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  align-items: center;

  height: 55px;
}

.dose-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 55px;
  padding: 6px 15px;
}
.dose-element input {
  width: 100%;
}
.dose-element input:focus {
  outline: none;
  border: 1px solid #eee;
  border-radius: 10px;
}

.quantity-box {
  width: 100%;
  border: none;
}

.quantity-box:focus {
  outline: none;
  border: 1px solid #eee;
  border-radius: 10px;
}
.switch-content {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active-switch {
  border: 1px solid #00a3da;
}
.switch-content-reports {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch-content-reports-outer {
  width: 100%;
  border-radius: 11px;
  /* background: #f2f2f2 !important; */
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.multi_select_outer {
  width: 100%;
  border-radius: 11px;
  /* background: #f2f2f2 !important; */
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
}
.multiSelect_div {
  display: block;
  columns: 2;
}
.multiselect_label {
  justify-content: start !important;
}
.radio-text-box {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}
.radio-text-box-outer {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px;

  flex-direction: column;
}
.add-item {
  width: 52%;
  text-align: end;
}

.add-item-d {
  display: none;
}

.disable {
  border: 0;
  background-color: inherit;
}

.enable {
  border: 1px solid #999;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 3px 8px;
}
.input-style {
  border-bottom: 1px solid !important;
}
.section-heading {
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
}
/* new css */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* height: 40px; */
  background: #00cc6a;
  margin-top: -40px;
  border-radius: 15px 15px 0 0;

  color: #fff;
}

.card-header-title {
  display: flex;
  gap: 10px;
}
.acc_bold {
  font-weight: bold;
  font-size: 13px !important;
}
.card-header-title p {
  color: #fff;
}

.medicine-card .card-step-number {
  left: -63px;
  top: 0;
}

.card-content {
  padding: 20px;
}

.medicine-card .medicine-card-heading {
  padding: 0 !important;
  margin: 0;
}

.card-actions {
  margin-bottom: 12px;
}
.template_input .MuiInputLabel-root {
  line-height: 16px !important;
  font-size: 13px;
}
.template_input .MuiInputLabel-root.MuiInputLabel-shrink {
  top: -4px !important;
}
.text_input_field .MuiOutlinedInput-input {
  height: 7px !important;
}
/* .template_input .MuiOutlinedInput-input {
  height: 7px !important;
} */
.card-actions,
.card-right-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.card-right-actions p {
  margin: 0;
}

.card-accordion-cont p {
  margin: 0;
}

.MuiAccordion-root {
  box-shadow: unset;
}

.card-accordion-cont > div {
  display: flex;
  justify-content: space-between;
}

.card-accordion-cont > div > div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.core-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.active-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .mds-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
} */

.mds-inner-boxes {
  display: grid;
  grid: auto/repeat(12, 1fr);
  gap: 8px;
}
.reports-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dosagetime-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dispensing-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.schedule-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dose .MuiFormControl-root {
  width: 100% !important;
}
.form-buttons {
  background: #00a3da;
  color: #fff;
  border-radius: 10px;
  padding: 9px;
}
.buttons-bar {
  display: flex;
  gap: 10px;
}
.warning-switch {
  display: flex;
  gap: 8px;
  padding: 10px;
  align-items: center;
}
.buttons-enable {
  display: flex;
  justify-content: space-between;
}
.buttons-disable {
  display: none;
}
.text-area {
  resize: none;
  /* height: 176px; */
  /* width: 175px; */
  overflow: hidden;
  min-height: 30px;
  /* max-height: 0px; */
  border-radius: 8px;
  border-color: #dee2e6;
}
.outer-item-text {
  flex-direction: column;

  border-radius: 11px;
  background: #f2f2f2;
  display: flex;
  width: 100%;
  height: 65px;
  padding: 8px;
}
.outer-item-white {
  flex-direction: column;
  border-radius: 11px;
  background: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  height: 75px;
  padding: 8px;
  gap: 8px;
}
.outer-item-white-text {
  flex-direction: column;
  border-radius: 11px;
  background: rgb(255, 255, 255);
  display: flex;
  width: 100%;

  padding: 8px;
  gap: 8px;
}

.Red-Outline {
  border: 2px solid #fa4c4c !important;
}
.two-line {
  min-width: 49% !important;
  width: min-content;
  margin-right: 20px;
  min-height: 65px;
  cursor: pointer !important;
}
.inline-not.two-line:last-child {
  margin-right: 0 !important;
}
.outer-item-textarea {
  flex-direction: column;

  border-radius: 11px;
  background: #f2f2f2;
  display: flex;
  width: 100%;
  padding: 6px 15px;
  min-width: 278px;
}
.outer-item {
  flex-direction: column;

  border-radius: 11px;
  background: #f2f2f2;
  display: flex;
  width: 100%;
  height: 65px;
  padding: 6px 15px;
  min-width: 278px;
}
.template_control {
  flex-direction: column;
  border-radius: 11px;
  display: flex;
  width: 100%;
  /* padding: 6px 15px; */
  /* min-width: 335px; */
}
.template_control_label {
  flex-direction: column;
  border-radius: 11px;
  display: flex;
  gap: 200px;
  width: 100%;
  /* padding: 6px 15px; */
  /* min-width: 335px; */
}
.outer-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px 10px 0px;
}
.accordion-boxes {
  /* display: flex; */
  gap: 10px;
  /* flex-direction: column; */
}
.inline-not {
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  min-width: 32%;
}
.inline {
  margin: 10px;
  padding: 5px;
}
.validation-message {
  color: rgb(237, 72, 72);
  margin: 5px 0;
  font-size: 12px !important;
}

.sub-quest {
  display: flex;
  flex-direction: column;
  margin-top: -8px;
}

.sub-quest > div {
  margin: 0 !important;
  border-radius: 0 !important;
  padding-left: 50px;
  padding-top: 15px;
}

.sub-quest > div:first-child {
  padding-top: 20px;
}

.sub-quest > div:last-child {
  border-radius: 0 0 11px 11px !important;
}

.parent_template {
  padding-left: 49px;
  padding-bottom: 25px;
}


@media only screen and (max-width: 1440px) {
  .two-line {
    min-width: 48% !important;
  }
}
@media only screen and (max-width: 1800px) {
  .two-line {
    min-width: 48% !important;
  }
}
/*@media only screen and (max-width: 1366px) {
  .inline-not {
    min-width: 235px;
  }
}

@media only screen and (max-width: 1280px) {
  .inline-not {
    min-width: 277px;
  }
}
*/

@media only screen and (max-width: 600px) {
  .outer-boxes {
    flex-direction: column;
  }
}

.switch-content-reports-pharmanaut {
  width: 100%;
  border-radius: 11px;
  background: var(--pharmanaut-service-check);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
