
.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
}

.CustomCheckBox {
  margin-top: -10px;
}

/* Claim.css */

.custom-checked .p-checkbox-icon::before {
  content: "✔";
  color: white;
}

.custom-unchecked .p-checkbox-icon::before {
  content: "";
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #cccccc !important;

  &.p-highlight {
    background-color: #222 !important;
    border: none !important;
  }
}

.ag-theme-alpine h4 {
  align-items: center;
  text-align: center;
}

.ag-theme-alpine-dark h4 {
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #cccccc;
  align-items: center;
  text-align: center;
}

.analytics_tab_list_footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px 20px;
  position: sticky;
  bottom: -20px;
  box-shadow: 0px -10px 15px -10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -10px 15px -10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -10px 15px -10px rgba(0, 0, 0, 0.1);

  button {
    padding: 10px 30px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    font-family: "SF-Pro Regular", sans-serif !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin: 3px;
    }
  }
}

.services_details_main_wrapper{
  width: 600px;
}

.services_details_main_wrapper .drawer_wrapper{
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
}
.services_details_main_wrapper .img-heading{
  margin-top: 30px;
  padding-top: 15px;
}

.services_details_main_wrapper .img-div{
  width: 400px !important;
  height: 400px !important;
}

.services_details_main_wrapper .drawer_header{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
