.patient_form {
  min-height: 100%;

  /* padding: 0; */
  /* margin: 0; */

  color: #666;
  display: flex;
  justify-content: center;

  /* text-align: center; */
  background: #f2f2f2;

  /* align-items: center; */

  h1 {
    margin: 0 0 20px;
    font-weight: 700;
    color: #000000;
  }

  p {
    margin: 0 0 5px;
  }
}

.form_control {
  flex-direction: column;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  width: 100%;
  padding: 6px 15px;
  min-width: 278px;
}

.switch-content-vaccine1 {
  margin-left: 13px;
  align-items: center;
  background: #fff;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  width: 97%;
}

.main-block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #ffffff;
  width: 50%;
  padding: 25px;
}

.input-style {
  border-bottom: 1px solid !important;
}

.input_color {
  background: #f2f2f2 !important;
}

.patient_card {
  border-radius: 20px;
}

.fas {
  margin: 25px 10px 0;
  font-size: 72px;
  color: #fff;
}

.fa-envelope {
  transform: rotate(-20deg);
}

.fa-at,
.fa-mail-bulk {
  transform: rotate(10deg);
}

textarea {
  width: calc(100% - 18px);
  border: 1px solid #575f5b;
  outline: none;
}

input::placeholder {
  color: #666;
}

.patientform_accordion {
  border-radius: 8px !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12) !important;

  &:before {
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    height: 1px;
    content: "";
    opacity: 1;
    background-color: rgba(0, 0, 0, 0) !important;
    -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.MuiAccordion-root:before {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.PatientFormSubmit {
  width: 100%;
  padding: 10px;
  border: none;
  background: #575f5b;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.GP_switch {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.PatientFormSubmit:hover {
  background: #4d504f;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px !important;
}

.btn-div {
  display: flex;
  justify-content: end;
}
