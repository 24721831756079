.main-row {
  margin-bottom: 80px;
}

.p-button {
  color: #ffffff;

  border: 1px solid rgb(138, 138, 138);
  height: 40px;
  margin: 0px 8px 0px 8px;
}

.action-footer {
  width: 100%;
}
.actions {
  position: fixed;
  width: inherit;
  bottom: 0;
  display: flex;
  justify-content: flex-end !important;
  padding: 10px;
  background: #efeded;
  margin-top: 20px !important;
  left: 0;
  gap: 8px;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  /* background: #4F46E5; */
  /* color: #ffffff; */
  /* border-color: #4F46E5; */
}
.message-inner-items
{
  display: flex;
  gap: 10px;
}

.item-title-orange
{
  font-weight: bold;
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #de9a5e;
}
.item-count-orange
{
  border: 1px solid #de9a5e;
  padding: 0 10px 0px 10px;
  border-radius: 3px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  color: #de9a5e;
  justify-content: center;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.item-title-risk
{
  font-weight: bold;
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #a4132f;
}

.item-count-risk
{
  border: 1px solid #a4132f;
  padding: 0 10px 0px 10px;
  border-radius: 3px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  color: #a4132f;
  justify-content: center;
  font-family: "SF-Pro Regular", sans-serif !important;
}