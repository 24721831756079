.nopatient-card {
  padding: 10px;
  border-radius: 15px;
  margin-bottom: unset;
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nopatient-card p {
  font-size: 2rem;
  line-height: 2.5rem;
}
