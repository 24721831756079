.customcard .radio-form {
  display: flex;
  padding: 5px 20px;
}

.followup-heading {
  display: flex;
  align-items: baseline;
  gap: 10px;
  color: #00a3da;
  border-bottom: 1px solid #ddd;
  padding: 5px 20px;
}

.decision {
  height: auto !important;
}

.outer-item.decision {
  .custom-dropdown .MuiFormControl-root {
    margin-top: unset;
  }

  .MuiSelect-select {
    font-size: 12px;
    padding: 0;
  }

  .MuiInputBase-root {
    background-color: transparent !important;
  }
}

.followup-heading h5 {
  color: #00a3da;
  font-size: 16px;
}

.outer-boxes .outer-item {
  justify-content: center;
  gap: 5px;
}

.customcard {
  .MuiFormGroup-root {
    width: 100%;
  }

  label.MuiFormControlLabel-root {
    justify-content: space-between;
    width: 100%;
    margin: 0;
    height: 35px;
  }
}

.followup-in {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 10px 20px;
  position: relative;

  select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #aaa;

    &:focus {
      outline: none;
    }
  }

  label {
    margin-bottom: 50px;
  }
}

.customcard {
  .map_dropdown {
    width: 100%;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    padding: 10px;
    transition: 0.2s linear;
    position: absolute;
    top: 40px;

    &.active {
      border: 1px solid #00a3da;

      .dropdown__text {
        padding-bottom: 10px;
      }
    }
  }

  .dropdown__item {
    border-top: 1px solid #ddd;
  }
}
