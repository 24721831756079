.cc-find-patient-modal-content {
  background-color: var(--find-patient-modal-bg) !important;
  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    padding: 20px;
    svg {
      cursor: pointer;
    }
  }
  .p-icon-field {
    margin: 4px 16px;
    width: 95%;
    & > input {
      background-color: var(--find-patient-modal-search-input-bg) !important;
      width: 100%;
    }
    &.p-icon-field-left {
      & > svg {
        position: absolute;
        top: 28%;
        left: 1%;
        color: #aaa;
      }
    }
  }
  .modal-footer {
    margin-right: 0;

    button {
      border: 2px solid var(--global-bg-color);
      background-color: var(--find-patient-modal-button) !important;
    }
  }
}

.cc-find-patient-modal-content .modal-body,
.cc-find-patient-modal-content p {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.patient-list {
  min-height: 200px;
  max-height: 600px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .no-patient-found {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;

    p {
      color: #777;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border: 1px solid #b5b5b5;
  }
  &::-webkit-scrollbar-thumb {
    background: #b8b8b8a5;
    transition: 0.2s linear;
    &:hover {
      background: #b8b8b8;
      transition: 0.2s linear;
    }
  }
}

.patient-info-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding: 12px 20px;
  cursor: unset;
  &:hover {
    background-color: unset;
  }
}

.patient-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding: 12px 20px;
  cursor: pointer;
  transition: 0.1s linear;

  &.selected {
    background-color: var(--find-patient-modal-patient-info-hover-bg);
    border: 0;
  }

  &:hover {
    transition: 0.1s linear;
    background-color: var(--find-patient-modal-patient-info-hover-bg);
  }
  p {
    color: #999;
    font-size: 14px;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #7fbed6;
  }

  .details {
    display: flex;
    align-items: start;
    gap: 8px;
    p {
      &.name {
        font-size: 16px;
        color: var(--find-patient-modal-patient-name);
      }
    }
    .address {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0;
      p {
        padding: 0;
      }
    }
  }
  .MuiRating-iconFilled {
    & > .MuiSvgIcon-root {
      color: var(--find-patient-modal-patient-name);
    }
  }
}
