.add-event-popup {
  background-color: #fff;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 350px;
  height: auto;
  text-align: left;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
}

.add-event-popup-buttons {
  text-align: center;
  margin-top: 30px;

  button {
    margin: 0 10px;
    padding: 8px 16px;
    border-radius: 15px;
    background-color: #2e2a2a;
    color: #fff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #000000;
    }
  }
}

.calender_div_main {
  position: relative;
  z-index: 1;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.add-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.input-text {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;

  &:focus {
    outline: none;
    border-color: #000000;
    box-shadow: 0 0 0 2px rgba(2, 28, 55, 0.25);
  }
}

.input_field {
  /* display: flex; */
  padding: 10px;
}

.event_modal_container {
  background-color: rgb(172, 171, 171);
  color: #fff;
  border-radius: 5px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 50px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 30%;
}

.title {
  padding-top: 8px;
  font-family: "SF-Pro Regular", sans-serif !important;
  color: #000000;
  text-align: center;
}

.custom_labels {
  color: #2e2a2a;
  font-weight: 500;
  margin-left: 10px;
}

.event_title {
  white-space: pre-line;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.timing_schedule {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;
  color: #000000;
  display: flex;
  justify-content: space-between;

  /* height: 50px; */
  width: 100%;
  background-color: #f5f5f5;
  margin-left: 5px;
  margin-right: 20px;

  i {
    font-weight: 400;
  }
}
