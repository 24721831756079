.services_analytics {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    transition: 0.2s linear;

    &:hover {
      background: #555;
      transition: 0.2s linear;
    }
  }
}

.analytics_charts_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 20px;
  justify-content: space-around;
  padding: 30px;
}

.service_analytics span {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.analytic-desc {
  max-width: 100%;
  width: 100%;
  min-width: 200px;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin: 5px;
  margin-top: 10px;
}

.chart-stages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin-top: 5;
  align-items: center;

  /* justify-content: space-between; */
  cursor: pointer;
  margin: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 15px;

    h6 {
      margin: 0;
      background-color: #111;
      color: white;
      border-radius: 10px;
      padding: 10px 15px;
      font-family: "SF-Pro Regular", sans-serif !important;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  .d-flex {
    align-items: center;
    gap: 15px;
  }

  div span {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}

.nms-chart-text {
  margin-top: 10px !important;
  text-align: center;
}

.analytic-desc {
  h6 {
    margin: 0;
    background-color: #111;
    color: white;
    border-radius: 10px;
    padding: 10px 15px;
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
  }

  .declined-count {
    margin: 0;
    background: linear-gradient(135deg, hsl(300, 53%, 59%), hsl(293, 78%, 29%));
    color: white;
    border-radius: 10px;
    padding: 10px 15px;
  }

  span {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 14px;
    font-weight: 510;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .d-flex {
    gap: 15px;
    align-items: center;
  }

  h2 {
    margin: 0;
  }
}

.serviceClaimStatus {
  margin: 0 20px 30px 20px;
  padding-top: 30px !important;

  .ag-column-first {
    .ag-header-cell-comp-wrapper {
      display: none !important;
    }
  }
}

.analytic-desc p {
  font-size: 12px;
  margin: 0;
}

.serviceClaimStatus_wrapper {
  padding: 15px 0px 15px 10px;
  box-shadow: 0px 3.380577325820923px 11.83202075958252px 0px #0000001f;
  border-radius: 16px;
  transition: 0.2s linear;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    transition: 0.2s linear;

    &:hover {
      background: #555;
      transition: 0.2s linear;
    }
  }
}

.servicesAnalytics_tab-content {
  max-height: 570px;

  .MuiBox-root {
    padding: 0;
  }
}

.servicesAnalytics_tab-labels {
  border-bottom: 1px solid #eee;
  margin-top: 35px;
}

.service_analytics {
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  .analytics_stages_filter {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    .title-heading {
      padding-left: 40px;
      font-weight: 400;
    }
  }
}

.chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-heading {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.chart-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart_alt {
  font-size: 11px !important;
  color: #999;
}

.analytics_chart {
  margin-top: 20px;
}

.chart-heading .heading-estimate {
  color: #00a337;
  border-radius: 20px;
  border: 1px solid rgb(83, 176, 83);
  padding: 3px 15px;
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.user_list .user_record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  border-radius: 15px;
  padding: 10px;
  padding-right: 40px;
  margin-top: 20px;
  gap: 10px;
}

.user_record {
  .user_image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .user_heading {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .user_body {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #666666;
  }

  .d-flex {
    align-items: center;
    gap: 10px;
    width: 250px;
  }
}

.stages_tabs_container {
  margin-top: 20px;

  .notification-count p {
    font-size: 12px;
  }

  .tabs_text {
    font-family: "SF-Pro Regular", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #999999;

    &.selected {
      font-weight: 590;
      color: #000000;
      border: none;
    }
  }

  .MuiTabs-root {
    margin: unset !important;
    width: 100%;
  }

  .service_analytics {
    box-shadow: unset;
  }
}

.servicesAnalytics_tab-labels {
  .notification-count p {
    font-size: 12px;
  }

  .tabs_text {
    &.selected {
      font-weight: 590;
      color: #000000;
      border: none;
    }

    color: #999999;
  }
}

.x_axis_chart {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px;
  font-weight: 510;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #000000 !important;
}

.y_axis_chart {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px;
  font-weight: 510;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #999999;
}

@media (max-width: 768px) {
  .service_analytics {
    flex-direction: column;
  }

  .tabs_text {
    font-size: 14px;
  }

  .user_list .user_record {
    gap: unset !important;
  }
}

.servicesAnalytics_tab-content {
  #simple-tabpanel-0 .ag-center-cols-container {
    width: 100% !important;
  }

  #simple-tabpanel-1 .ag-body-horizontal-scroll-viewport {
    display: none;
  }

  #simple-tabpanel-2 .ag-center-cols-container {
    width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .servicesAnalytics_tab-content {
    #simple-tabpanel-1 .ag-center-cols-container {
      width: 100% !important;
    }

    #simple-tabpanel-2 .ag-cell:last-child {
      width: 100% !important;
    }
  }
}

.date-range-picker-container {
  display: flex;
  justify-content: flex-end;

  .date-range-picker {
    display: flex;
    padding: 4px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #cccccc;
  }
}

.date-range-picker-container {
  display: flex;
  justify-content: flex-end;

  .date-range-picker-endorsement {
    display: flex;
    padding: 4px;

    text-align: right;
    align-items: center;
    border-radius: 15px;
    border: 2px solid var(--label-border-color);
  }
}

.agency-date-range-picker-container {
  display: flex;
  justify-content: flex-end;

  .date-range-picker {
    display: flex;
    padding: 4px;
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #cccccc;
  }
}

.MuiInputBase-input {
  border: none !important;

  /* text-align: center; */
}

.date-range-picker-container {
  .range-picker-icon {
    cursor: pointer;
  }

  .p-calendar .p-inputtext {
    border: none !important;
    border-radius: 10px !important;
    padding-left: 10px !important;
    margin-left: 8px !important;
    font-size: 12px !important;
    font-family: "SF-Pro Regular", sans-serif !important;
  }

  .p-button {
    border: none !important;
  }

  .p-icon {
    width: 20px !important;
    height: 20px !important;
  }
}

.analytics_charts_wrapper .charts_loader,
.user_list_chart .charts_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 600px) {
  .user_list .user_record {
    gap: unset !important;
  }
}

// ============================================MUI date range picker===================================================
.date-range-picker-container .MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

.date-range-picker-container .MuiStack-root>.MuiTextField-root {
  min-width: 0px !important;
}

.date-range-picker-container .MuiStack-root {
  padding-top: 1px !important;
}

.date-range-picker-container .MuiInputBase-input {
  padding: 5px 10px !important;
  font-size: 14px !important;
  font-family: "SF-Pro Regular", sans-serif !important;
  width: fit-content !important;
  margin-right: 8px !important;
  border-radius: 10px !important;
}

.date-range-picker-container .MuiSvgIcon-root {
  cursor: pointer !important;
}

.agency-date-picker
{
  display:flex;
  justify-content: flex-start;
}

.endorsements-label
{
  font-weight: bold !important;
}