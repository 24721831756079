.delete-event-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
}

.delete-event-popup-buttons {
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #cc0000;
    }
  }
}

.calender_div_main {
  position: relative;
  z-index: 1;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.add-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.event_title {
  font-weight: bold;
}
