.header-container {
  position: absolute;
  top: 3px;
  right: 5px;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  z-index: 2;
  padding: 0 5px;

  /* transition: 0.8s ease-in-out; */

  &.hidden {
    transition: 0.8s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
  }
}

.header-wrapper-one,
.header_wrapper-two {
  display: flex;
}

.team-members {
  display: flex;
  margin-right: 20px;
}

.team-img-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-img,
.team-length {
  width: 35px;
  height: 35px;
  transition: 0.2s linear;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.2s linear;
}

.team-add {
  width: 35px;
  height: 35px;
  transition: 0.2s linear;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .header_wrapper-two {
    flex-direction: column;
  }

  .team-members {
    flex-direction: column;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .team-member-count,
  .team-member-add {
    margin-left: 13px;
  }
}

.team-length {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-img {
  img {
    object-fit: cover;
    width: 35px;
    height: 35px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.team-text {
  background-color: #000;
  padding: 2px 5px;
  width: fit-content;
  border-radius: 3px;
  font-size: 10px;
  margin: 5px auto;
  transition: 0.2s linear;
  display: none;

  p {
    color: #fff;
    margin: 0;
  }
}

.team-member-count p {
  margin: 0;
}

.team-members {
  > div {
    margin-right: -12px;
    transition: 0.2s linear;
  }

  &:hover > div {
    transition: 0.2s linear;
    margin-right: unset;
  }
}

.team-img:hover,
.team-length:hover {
  border: 2px solid #000;
  transition: 0.2s linear;
  cursor: pointer;
}

.team-add:hover {
  border: 2px dotted #000;
  transition: 0.2s linear;
  cursor: pointer;
}

.team-img-text:hover .team-text,
.team-member-count:hover .team-text,
.team-member-add:hover .team-text {
  display: block;
  position: absolute;
  top: 35px;
}

.team-members > div:last-child {
  margin-right: unset;
}

.header-icons {
  margin-left: 10px;

  .icon-only {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 6px;
    margin: 0 auto;
    width: 37px;
    height: 37px;
    border-radius: 10px;
    transition: 0.1s linear;
  }

  .icon-only-alt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 6px;
    margin: 0 auto;
    height: 35px;
    border-radius: 10px;
    transition: 0.1s linear;
  }

  .icon-wtext {
    background-color: #000;
    padding: 2px 5px;
    width: fit-content;
    border-radius: 3px;
    font-size: 9px;
    margin: 5px auto;
    transition: 0.2s linear;
    display: none;
  }

  &:hover {
    .icon-only,
    .icon-only-alt {
      border: 1px solid #000;
      cursor: pointer;
      transition: 0.1s linear;
    }

    .icon-wtext {
      display: block;
      border: none;
      transition: 0.2s linear;
      position: absolute;
    }
  }
}

.icon-wtext p {
  margin: 0;
  color: #fff;
}

.header-icons-alt:hover {
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.1s linear;
}

.header-notification-count {
  background-color: #d90000;
  border-radius: 3px;
  padding: 2px;

  p {
    margin: 0;
    color: #fff;
    font-size: 10px;
  }
}

.header_dropdown select {
  /* accordian */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px px 5px 10px;
  gap: 6px;
  width: 250px;
  height: 35px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 10px;
  transition: 0.5s linear;
  margin-left: 10px;
  font-size: 14px;
}

.chat-icon-dark > svg {
  color: #fff !important;
}

/* Chat icon for light mode */

.chat-icon-light > svg {
  color: #000;
}

.header-icon-active {
  background-color: #222222 !important;
}


.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;

  /* Adjust the background color */
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  div {
    cursor: pointer;
    padding: 5px;
    white-space: nowrap;

    &:hover {
      background-color: #e0e0e0;

      /* Adjust the background color on hover */
    }
  }
}

.profile_image {
  height: 33px;
  width: 33px;
  border-radius: 10px;
}

.profile_menu_item {
  gap: 8px;
  font-size: 14px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  margin-top: 5px !important;
}

/* --------------------------------- */

@media screen and (max-width: 600px) {
  .tabs_text {
    font-size: 10px !important;
  }

  .tabs_box {
    padding: 55px 20px 0 5px !important;
  }

  .pharmacy_header_iconbox {
    justify-content: center !important;
  }

  .tabs_box .MuiTabs-flexContainer {
    gap: 0px !important;
  }

  .overview_tab {
    margin-top: 170px;
  }

  .pharmacy-code-icon-white {
    color: #fff !important;
  }

  /* Chat icon for light mode */

  .pharmacy-code-icon-black {
    color: #000;
  }
}
