.dashboardListing_container {
  position: absolute;
  top: 60px;
  right: 5px;
  height: calc(100% - 60px);
  padding: 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;

    &:hover {
      background: #555;
    }
  }

  .pharmacy_listview {
    height: fit-content;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
    width: 400px;
    padding: 16px;
    border-radius: 30px;
    min-width: fit-content;
  }

  .pharmacy-info {
    padding: 100px 30px 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    margin-bottom: 20px;
    position: relative;

    p,
    h6 {
      position: relative;
      color: #fff;
    }

    p {
      font-weight: 300;
      font-size: 13px;
    }

    .overlay {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      border-radius: 16px;
    }
  }

  .pharmacy_listview h6 {
    margin: 0;
  }
}

.pharmacy-buttons {
  display: flex;
  gap: 10px;
}

.pharmacy_listview-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 3.3805773258px 11.8320207596px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 16px;
  border-radius: 16px;
  width: 100%;
  transition: 0.2s linear;

  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
    transition: 0.2s linear;
  }

  .count-box {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  .count {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .count-box p {
    margin: 0 !important;
  }
}
