.team_container {
  padding: 25px;
  margin-top: -10px;
  position: absolute;
  top: -10px;
  z-index: 6;
  max-height: 80vh;
  height: 100%;
  overflow: auto;

  transition: 0.8s ease-in-out;
}

.team_container.hidden {
  transition: 0.8s ease-in-out;
  transform: translateX(-100%);
  opacity: 0; /* Slide up and out of the screen */
}

.team-title {
  position: absolute;
  margin-top: 15px;
  margin-left: 55px;
  z-index: -1;
}

.team-title h5 {
  margin: 0;
}

.team_wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.team_icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;

  width: 40px;
  height: 40px;
  border-radius: 10px;

  flex: none;
  order: 0;
  flex-grow: 0;

  transition: 0.2s linear;
}

.team_icon-label {
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #000;
  padding: 8px;
  border-radius: 10px;

  display: none;
}

.team_icon-label p {
  margin: 0;
  margin-left: 5px;
  text-wrap: nowrap;
}

.team_icon-box:hover {
  cursor: pointer;
  border: 2px solid #000;
  transition: 0.2s linear;
}

.team_icon-box:hover + .team_icon-label {
  display: flex;
}

.notification-circle {
  width: 12px;
  height: 12px;
  background-color: #d90000;
  border-radius: 50%;

  position: absolute;
  top: -4px;
  right: -5px;
}

.notification-counts {
  background-color: #d90000;
  width: 20px;
  padding: 2px 3px;
  margin-left: 5px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.notification-counts p {
  margin: 0;
  color: #fff !important;
  font-size: 10px;

  display: block;
  margin: 0 auto;
}

.hover_team-img {
  width: 40px;
  height: 40px;
}

.team_icon-box img,
.hover_team-img img {
  width: -webkit-fill-available;
  width: -moz-available;
}

.hover_team-img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.pharmacy-list-wrapper,
.pharmacies-list-footer {
  padding: 0 20px;
}

.addNewPharmacy-heading {
  position: sticky;
  top: 0px;
  padding: 5px 20px;
}

.pharmacy-list-wrapper {
  margin-top: 20px;
}

.pharmacies-list-footer {
  position: sticky;
  bottom: -2px;
}

.pharmacy-search-wrapper {
  margin-top: 20px;
}

.pharmacy-search-box {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 8px;
  font-size: 14px;
}

.pharmacy-search-box input {
  width: 100%;
  border: 0;
  background-color: transparent;
}

.pharmacy-search-box input:focus {
  outline: none;
}

.pharmacy-error-message {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0;
}
