.settings_sidebar {
  border: none !important;
  width: 400px;

  > .ps-sidebar-container a {
    padding: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }

  .ps-submenu-content .ps-menuitem-root a,
  > .ps-sidebar-container .no-submenu a {
    font-size: unset;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 10px;
  }

  .submenu-title > .ps-menu-button:hover {
    background-color: transparent;
    border-radius: 10px;
  }

  .ps-submenu-expand-icon{
    margin-right: 3px;
  }
}

.settings-sidebar-selected {
  border-radius: 10px;
}

.d-none {
  display: none;
}

.ps-submenu-content {
  border-radius: 10px;
}

.sidebar-hamburger {
  background-color: #eee;
  padding: 5px 10px;
  margin: 10px -10px 0 0;
  border-radius: 10px;
  z-index: 99999;
  position: relative;
  display: none;
}

// -----------------Selection screen work------------------------------------------
.selection_screen_wrapper {
  // Image: pexels-nicole-avagliano-2706654;
  background: url("../../images/selectionBackground.png");
  background-size: cover;
  height: 100vh;
}

.selection_sreen_options {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}

.screen_options {
  position: relative;
  width: 383px;
  height: 400px;
  background-color: #fff;
  border-radius: 20px;
  margin: 30px;
  margin-top: 20px;
  padding: 20px;
  gap: 17px;
}

.screen_options h4 {
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.09px;
  text-align: left;
}

.screen_options p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.go_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(180deg, #ff65dd, #b752ff);
  color: #f2f2f2;
  border: none;
  border-radius: 12px;
  gap: 16px;
  height: 41px;
  width: 76.5px;
  cursor: pointer;
}

.go_button span {
  color: #f2f2f2;
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}
.screen_header {
  margin-bottom: 20px;
}

// ------------------End------------------------------------------------------

@media only screen and (max-width: 768px) {
  .sidebarSettings_wrapper {
    display: none;
  }

  .sidebar-hamburger {
    display: block;
  }

  .show-sidebar {
    display: block;
    position: absolute;
    width: fit-content;
    background: white;
    left: 0;
    z-index: 9999;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  .settings_breadcrumb {
    justify-content: end;
    margin-left: 0px;
    margin-top: 15px;
  }

  .settings_content_wrapper {
    width: 100%;
    padding: 0 25px;
  }
}

@media only screen and (max-width: 600px) {
  .sidebarSettings_wrapper {
    width: 75vw !important;
  }

  .settings_content_wrapper {
    padding: unset !important;
  }

  .settings_heading {
    text-align: center;
  }

  .AddNew_sectionbox {
    display: flex !important;
    flex-direction: column !important;
  }

  .color_pick_section {
    flex-direction: column !important;
  }

  .company_info_details .company_info_right_panel {
    width: 100% !important;
  }
}
