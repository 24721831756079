.map_dropdown {
  margin: 0 auto;
  width: fit-content;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 10px;
  padding: 9px 8px;
  width: 100%;
  transition: 0.2s linear;

  &:hover {
    border: 1px solid #000;
    transition: 0.2s linear;
  }
}

.dropdown__text {
  color: #000;
  cursor: pointer;
  height: auto;
  font-size: 12px;
  line-height: 16px;

  p {
    margin: 0;
  }

  &:after {
    content: "";
    transition: all 0.3s;
    border: solid #212121;
    border-width: 0 1px 1px 0;
    float: right;
    margin-top: 8px;
    margin-right: 6px;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.map_dropdown.active .dropdown__text:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  font-size: 12px;
  line-height: 16px;
  color: #999;
}

.map_dropdown.active .dropdown__items {
  visibility: visible;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 1s, visibility 4s ease;
}

.dropdown__item {
  cursor: pointer;
  padding: 10px 0px;
}
