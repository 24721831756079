.box {
  width: 300px;
  height: 250px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  overflow: auto;
  max-height: 300px;
}

.service_title {
  font-size: 16px !important;
  color: grey;
}

.services_list {
  font-weight: bold;
  font-size: 13px;
  list-style-type: none;
  padding: 5px 10px;
  transition: 0.1s linear;
  z-index: 4;

  .private_services_add_box {
    .member_edit_icon {
      margin-right: 10px;
    }

    display: flex;
  }

  &.active {
    background-color: #f2f2f2;

    .service_item_title {
      color: #111111;
      font-weight: 700;
    }
  }
}

.services_sidebar_container {
  .services_list {
    transition: 0.2s linear;
  }

  .MuiAccordionDetails-root:last-child {
    padding-bottom: 18px;
  }

  .services_list:hover .service_item span:first-child {
    transition: 0.1s linear;
    font-weight: 600;
  }
}

.service_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;

  transition: 5s linear;
}

.service_item_sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 30px;
  transition: 0.2s linear;

  &:hover {
    transition: 0.2s linear;
    cursor: pointer;
  }
}

.itemCount {
  width: 30px;
  background-color: black;
  color: white;
  border-radius: 30px;
  text-align: center;
  font-size: 11px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services_sidebar_container {
  max-height: 95vh;
  overflow: hidden;
}

.services_accordion_details {
  > div {
    background: transparent !important;
    box-shadow: none !important;

    > div {
      padding: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
}

.services_sidebar_container {
  > div {
    background: none;
    box-shadow: none !important;
    margin: 0;
  }

  .pharmacy-error-message {
    margin: 10px 0;
  }
}

// .services_container .MuiButtonBase-root {
//   padding: 0 10px !important;
// }

.services_sidebar_container {
  .MuiButtonBase-root {
    min-height: 30px !important;
    height: 30px;
    margin-top: 5px;
  }

  .MuiPaper-root {
    &::before {
      content: unset;
    }

    border-radius: 20px !important;
  }
}

.services_accordion-loader .oval-loading {
  width: 10%;
}

.services_iconboxService span {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.service_item span {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 12px;
}

@media only screen and (max-width: 1600px) {
  .services_iconboxService span,
  .service_item span {
    max-width: 140px;
  }
}

@media only screen and (max-width: 1400px) {
  .services_iconboxService span,
  .service_item span {
    max-width: 90px;
  }
}

@media only screen and (max-width: 1200px) {
  .services_iconboxService span,
  .service_item span {
    max-width: 70px;
  }
}

@media only screen and (max-width: 1180px) {
  .services_iconboxService span,
  .service_item span {
    max-width: unset;
  }
}

.sideBarBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.template-comments-box {
  margin-left: 15px;
}

.template-comments-heading {
  color: #999;
  font-size: 12;
}
