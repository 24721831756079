.patientsFilter_sidebar {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 1px 0px 0px #cccccc;
  backdrop-filter: blur(5.5px);
  height: 100vh;
  z-index: 5;
  position: absolute;
  overflow: hidden;
}

.patientsFilter_sidebar_wrapper {
  padding: 20px 10px 10px 80px;
}

.patientCount {
  display: flex;
  gap: 10px;
  align-items: center;
}

.patientCount p {
  font-size: 12px;
  margin: 0;
  font-weight: bold;
}

.patientAgeRange {
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 10px 14px 14px;
  font-size: 12px;
}

.patientAgeRange p {
  margin-bottom: 5px;
}

.patientAgeRange input[type="range"] {
  width: 100%;
  accent-color: #000;
}

.patientAgeRange input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
}

.patientAgeRange input[type="range"]::-webkit-slider-thumb {
  margin-top: -5px;
}

.patientAgeRange_MaxMinText {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 10px;
}

.patientFilterList_wrapper {
  margin-top: 15px;
}

.patientFilterList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px;
  gap: 6px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-size: 12px;
}

.patientFilterList:not(:first-child) {
  margin-top: 10px;
}

.patientFilterList:hover {
  cursor: pointer;
}

.selected {
  border: 1px solid #000;
}

.patientsFilter_sidebar_btns {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 25px;
  right: 15px;
}

.patientsFilter_sidebar_btns button {
  font-size: 12px;
  min-width: unset;
  min-width: 100px;
  padding: 10px;
  border-radius: 8px;
}

.patientsFilter_sidebar_btns button:first-child {
  background-color: white !important;
  color: #000 !important;
  border: 1px solid #000;
}

.tooltipRange {
  background-color: black;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  margin-top: 5px;
}

.patientsFilter_notFound {
  position: absolute;
  right: 6px;
  top: -25px;
  background-color: red;
  padding: 2px 15px;
  border-radius: 5px;
}

.patientsFilter_notFound p {
  color: white;
  font-size: 11px;
  margin: 0;
}
