.breadcrumbs {
  margin-left: 5px;
  cursor: pointer;

  span {
    a {
      font-size: 15px;
    }

    font-size: 15px;
  }
}

.breadCrumb_container {
  display: flex;
  align-items: baseline;
  gap: 10px;
  padding: 10px 0;
}

.breadcrumbs p {
  margin: 0;
}

.back-to-home-icon {
  cursor: pointer;
  margin-left: 15px;

  svg {
    height: 12px;
  }
}

.breadCrumb_container span {
  cursor: pointer;
}

.pharmacy_header_details {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;

  display: flex;
  gap: 10px;

  p {
    margin: 0;
  }
}

.breadcrumbs .tabs_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px 0 25px;
  background-color: #fff;
}

.darkModeTabBox {
  background-color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px 0 25px;
}

.darkModeBreadCrumb_title {
  color: #fff !important;
}

.pharmacy_header_details {
  .pharmacy_header_name_darkMode {
    color: #fff;
  }

  .pharmacy_header_address_darkMode {
    color: #afadad !important;
  }
}

@media only screen and (max-width: 1024px) {
  .pharmacy_header_iconbox {
    justify-content: end;
  }
}

@media only screen and (max-width: 600px) {
  .breadcrumbs .breadCrumb_title {
    margin-top: 30px;
  }
}

.clinicalchecks .back-to-home-icon{
  margin-left: 0;
}
