.custom-input {
    display: block;
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 1);
    border-radius: 8px;
    padding: 6px 6px 6px 10px;

    font-size: 14px;
}

.custom-endorsement-input {
    display: block;
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 1);
    border-radius: 8px;
    padding: 6px 6px 6px 10px;

    font-size: 14px;
}