/* Style for the user profile card */
.user-profile-card {
  padding: 10px;
  margin-bottom: unset;

  .service_tags_list {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

/* Style for the user's name */

/* Style for the user's date of birth and sex */
.user-profile-card p {
  margin: 0;
  margin-bottom: 5px;
}

/* Style for the user's allergies */
.user-profile-card p:last-child {
  margin-bottom: 0;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.user-profile-card .p-message-warn-orange > p {
  font-size: 12px !important;
  max-width: unset !important;
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}

@media only screen and (max-width: 1440px) {
  .user-profile-card p:last-child {
    margin-bottom: 0;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

/* Style for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.user-label {
  font-weight: bold;
}
.user-prescription {
  margin-right: 10px;
}
.patient-profile {
  height: 65px;
  width: 100%;
  border-radius: 11px;
  background-color: #f2f2f2;
  padding: 6px 15px;
  display: flex;
  align-items: start;
}
@media screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .user-info {
    display: flex;
    padding: 10px;
    overflow-x: scroll;
  }
}

.clinicalchecks .user-profile-card .card-heading {
  margin-top: -45px !important;
}

.clinicalchecks .card-header {
  padding: 10px 20px !important;
  height: 40px !important;
}

.service_tags_list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}
