.question_box{
    display: flex;
    gap: 20px;
    margin: 20px 0px 0px 0px;
    flex-direction: column;
}
.question_dropdown{
    display: flex;
    flex-direction: column;
}

.q_modal_footer{
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
}
.option_box {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
}
.control_field{
    align-items: center;
    display: flex;
    gap: 30px;
    padding: 15px 0px;
}
.template_info{
    display: flex;
    gap: 30px;
}
.drag_card{
    margin-top: 20px;
    margin-bottom: 20px;
}
.temp_content {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 20px;
}
.temp_head {
    width: 30%;
}

 .temp_body {
        display: flex;
        width: 70%;
        flex-direction: row;
 }
.sec_name{
    padding: 5px 0px;
    width: 65%;
}
.sec_footer{
    padding: 5px 0px;
}
.control_box{
   
    padding: 6px;
    width: 55%;
    font-size: 17px;
}
p.sec_line{
    font-size: 15px;
}
.temp_save{
    /* margin-top: 10px; */
}
.temp_body_b{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.temp_body_a{
    width: 80%;
}