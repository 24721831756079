.patient_mapview_container {
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  border-radius: 6px;

  position: relative;
  overflow: hidden;
}

.patient_mapview_count {
  position: absolute;
  top: 12px;
  left: 15px;
  z-index: 2;
  font-weight: 400;
  font-size: 20px;
}
