.addTeam_icon-box {
  position: absolute;
  z-index: 1;
  bottom: 30px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.addTeam_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 10.2128px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: 0.2s linear;

  &:hover {
    cursor: pointer;
    border: 2px dotted #000;
    transition: 0.2s linear;

    + .addTeam_text-box {
      display: flex;
    }
  }
}

.addTeam_text-box {
  margin-left: 10px;
  display: none;
  transition: 0.2s linear;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  height: fit-content;

  p {
    margin: 0;
  }
}

.addTeam_cmd-icons {
  display: flex;
  align-items: center;
  margin-left: 10px;

  > div {
    border: 1px solid #eee;
    padding: 2px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &:last-child {
      margin-left: 2px;
    }
  }
}

/*Add New Modal*/

.addNewModal {
  width: 25vw;
}

.inv-form{
  padding: 30px;
  font-size: 14px;
}


.addNewModal form{
  padding: 0px 30px 10px 30px;
}

.addNewModal h5{
  font-weight: 600;
  word-wrap: break-word;
}

.addNewModal .error-message{
  margin-top: 5px;
}


.addNew_header {
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
    width: 100%;
}

.addNew_iconBox .team_icon-box {
  border: 2px solid #000;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 46px;
  height: 44px;
}


.addNew-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
  gap: 5px;
}

.addNew-svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addNew-input-container input {
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.addNew-uploadBox {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;

  p {
    margin: 0;
  }

  span {
    font-weight: bold;
  }

  p {
    font-size: 11px;
  }
}

.addNew-button {
  background-color: #000;
  color: #fff;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;

  svg {
    float: right;
    display: inline-block;
  }
}

.addNew-button-icon {
  float: right;
  display: block;
  margin-top: -15px;
}

.addNewPharmacySelection .modal-content {
  overflow: hidden;
}

.addNewPharmacy-heading h4 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.pharmacy-list-wrapper {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #222;
    transition: 0.2s linear;

    &:hover {
      background: #555;
      transition: 0.2s linear;
    }
  }
}

/* width */

/* Track */

/* Handle */

/* Handle on hover */

.pharmacy-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    margin-top: 15px;
  }

  p {
    margin: 0;
  }
}

.pharmacy-title {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-weight: 500;
}

.pharmacy-address p {
  color: #999;
  font-size: 12px;
}


.pharmacy-loader .oval-loading {
  position: unset;
  transform: unset;
  margin: 0 auto;
  display: block;
  width: 50px;
}

.invite_modal_heading {
  font-size: 40px;
  font-weight: 600;
}

.invite_desc {
  padding: 0px 25px;

  p {
    font-size: 16px;
    text-align: center;
  }
}
