.modal-header {
  justify-content: unset !important;
  gap: 8px;
  border: none !important;
}

.modal-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: red;
}

.modal-footer,
.modal-body {
  border: none !important;
  margin: 0 auto;
}
.progressmodal_footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.telephone_text {
  width: 100%;
}
