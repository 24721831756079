.teamName_container {
  position: absolute;
  z-index: 5;
  top: 22px;
  left: 80px;

  transition: 0.6s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;

  h5 {
    font-weight: 600;
  }

  svg {
    position: relative;
    top: -5px;

    cursor: pointer;
  }
}

.teamName_container.hidden {
  transition: 0.6s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;
}
