.pharmacy_header_modal {
  height: 100%;
  z-index: 1;
  display: block;
  position: relative;
  transition: 0.2s ease-out;

  .modal-content {
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      to bottom,
      var(--map-opacity) 30%,
      var(--bg-clr) 45%
    ) !important;

    &.map-blur-black {
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    padding: 0 !important;

    .MuiTabs-root {
      margin-top: -25px;
    }
  }
}

.tabs_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 0 20px;
  overflow: hidden;
}

.services_container .tabs_box {
  background-color: unset;
}

.services_container-tabs {
  padding: 10px 0;
  background: unset !important;
}

.tabs_box {
  .MuiTabs-flexContainer {
    gap: 20px;
    height: 55px;
  }

  // .MuiButtonBase-root {
  //   padding: 0;
  // }

  .MuiTab-root {
    color: #999;

    &.Mui-selected {
      color: #000;
    }

    text-transform: unset;
  }
}

.pharmacy_header_modal .modal-content {
  padding: 90px;
}

.tabs_text {
  font-size: 12px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.tabs_text_alt {
  font-size: 14px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.tabs_text.active_tab {
  font-weight: bold;
  color: black;
}

.pharmacy_header_name {
  font-weight: 700;
  font-size: 12px;
}

.pharmacy_header_address {
  margin-top: -3px !important;
  font-weight: 300;
  color: #666;
  text-align: center;
}

.pharmacy_header_details {
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;

  p {
    margin: 0;
    font-size: 12px;
  }
}

.pharmacy_header_iconbox {
  display: flex;
  align-items: end;
  justify-content: center;
}

.pharmacy-details-area {
  height: calc(100% - 80px);
}

.services-details-area {
  height: 100% !important;
}

.pharmacy_header_teamMembers {
  display: flex;
  align-items: center;
}

.homeIcon {
  min-width: 20px !important;

  > svg {
    position: relative;
    top: 8px;
  }
}

.notification-serv {
  p {
    margin: 0;
    color: #fff;
  }

  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;

  .notification-count {
    width: 26px;
    height: 26px;
    background-color: black;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tasks_tab {
  height: 100%;
}

@media only screen and (max-width: 1180px) {
  .pharmacy-details-area {
    height: 100%;
    overflow: scroll;
  }
}

.searchIc {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 26px;
  margin-left: 10px;
  font-size: 13px;

  p {
    padding-left: 6px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1024px) {
  .tabs_box .MuiTabs-flexContainer {
    gap: 15px;
  }

  .tabs_text,
  .searchIc p {
    font-size: 12px;
  }

  .pharmacy-details-area {
    padding: 30px;
  }
}
