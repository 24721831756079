.modal-header {
  justify-content: unset !important;
  gap: 8px;
  border: none !important;
}

.modal-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: red;
}

.modal-footer, .modal-body {
  border: none !important;
  margin: 0 auto;
}
