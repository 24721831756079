/* Style for the user profile card */

.medicine-card {
  margin-bottom: unset;

  p {
    font-size: 12px;
    margin: 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Style for the user's name */

/* Style for the user's date of birth and sex */

/* Style for the user's allergies */

/* Style for links */

a {
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.medicine-info {
  display: flex;
  padding: 10px;
}

.medicine-label {
  font-weight: bold;
}

.prescription-pills {
  margin-right: 10px;
}

.quantity {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 55px;
  padding: 6px 15px;
}

.dose {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  height: 55px;
}

.box-element {
  border-radius: 10px;
  height: 65px;
  padding: 8px 15px;
  background: #eee !important;

  p {
    color: grey;
  }

  label {
    font-size: 13px !important;
  }
}

.quantity-box {
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid #eee;
    border-radius: 10px;
  }
}

.switch-content {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active-switch {
  border: 1px solid #00a3da;
}

.switch-content-reports {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-item {
  width: 52%;
  text-align: end;
}

.add-item-d {
  display: none;
}

.disable {
  border: 0;
  background-color: inherit;
}

.enable {
  border: 1px solid #999;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 3px 8px;
}

.section-heading {
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
}

/* new css */

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  /* height: 40px; */
  background: #00cc6a;
  margin-top: -40px;
  border-radius: 15px 15px 0 0;
  color: #fff;
}

.card-header-title {
  display: flex;
  gap: 10px;

  p {
    color: #fff;
    font-size: 16px;
  }
}

.medicine-card .card-step-number {
  left: -70px;
}

.card-content {
  padding: 20px;
}

.medicine-card .medicine-card-heading {
  padding: 0 !important;
  margin: 0;
}

.card-actions {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.card-right-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
}

.card-accordion-cont p {
  margin: 0;
}

.MuiAccordion-root {
  box-shadow: unset;
}

.card-accordion-cont > div {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.core-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.active-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mds-inner-boxes,
.reports-inner-boxes,
.dosagetime-inner-boxes,
.dispensing-inner-boxes,
.schedule-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}

.dose .MuiFormControl-root {
  width: 100% !important;
}

.form-buttons {
  background: #00a3da;
  color: #fff;
  border-radius: 10px;
  padding: 9px;
}

.buttons-bar {
  display: flex;
  gap: 10px;
}

.warning-switch {
  display: flex;
  gap: 8px;
  padding: 10px;
  align-items: center;
}

.buttons-enable {
  display: flex;
  justify-content: space-between;
}

.buttons-disable {
  display: none;
}

.cc-dropdown-class 
{
  width: 100%;
    border-radius: 11px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 55px;
    padding: 6px 15px;
}

.cc-dropdown-class .MuiFormControl-root
{
  width: 100% !important;
}