.inviteModal {
  width: 35vw;

  form {
    padding: 15px;
    font-size: 14px;
  }
}

.inviteList-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header button {
  font-size: 12px;
}

.inviteList-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    p {
      margin: 0;
    }
  }
}

.p-dropdown {
  border-radius: 8px !important;
}

.p-dropdown-panel {
  z-index: 100000 !important;
}

.inviteList-owner {
  border-radius: 8px;
  width: fit-content;
  padding: 5px;
  gap: 5px !important;
  font-size: 12px;
}

.invite-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: end;

  button {
    width: 100%;

    &:first-child {
      min-width: 100px;
      margin: 3px;
      border-radius: 10px;
      text-align: center;
      height: 40px;
      font-size: 12px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

span.p-dropdown-label.p-inputtext {
  font-size: 12px;
}

.inviteList-email {
  display: flex;
  border-radius: 8px;
  padding: 5px;
  gap: 10px;

  input {
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.invite_email_div {
  width: 100%;
}

.invite_form {
  display: flex;
  align-items: center;
}

.team_members {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
}
