.img-div {
  object-fit: cover;
  height: 780px;

  background: #ffffff;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 0 auto;
  display: block;
}

.clinicalchecks .img-div {
  height: 100%;
  margin: unset;
  width: 100%;
  object-fit: contain;
}

.image-button {
  background: #bdbdbda3;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 90px;
  text-align: center;
  line-height: 2.5;
  font-size: 20px;
}

.image-button:hover {
  background: #838080;
}
