#hybrid {
  visibility: hidden;
}

#theme-toggle {
  visibility: hidden;
}

#theme-toggle + label {
  content: "";
  display: inline-block;
  cursor: pointer;
  height: 33px;
  width: 35px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

#theme-toggle:not(:checked) + label {
  background-color: gold;
}

#theme-toggle:checked + label {
  background-color: transparent;
  box-shadow: inset -18px -16px 1px 1px #fff;
}

.display-mode {
  display: flex;
  /* position: absolute; */
  top: 0;
  right: 25px;
  z-index: 3;
  /* margin-top: 3px; */
}

.display-mode:hover .header_display-mode{
  border: 1px solid #000;
}

.header_display-mode{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 6px;
    margin: 0 auto;
    width: 37px;
    height: 37px;
    border-radius: 10px;
    transition: 0.1s linear;
}

.display_mode-text{
  background-color: #000;
  padding: 2px 5px;
  width: fit-content;
  border-radius: 3px;
  font-size: 10px;
  margin: 5px auto;
  transition: 0.2s linear;

  display: none;
}

.display_mode-text p{
  margin: 0;
  color: #fff;
}

.display-mode:hover .display_mode-text {
  display: block;
  border: none;
  transition: 0.2s linear;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: -10px;
  white-space: nowrap;
}
