.caller_control {
  margin-left: auto;
  margin-right: auto;

  /* margin-top: 30px; */
  text-align: center;
  padding: 5px 12px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  white-space: no-wrap;
  min-width: 100px;
  cursor: pointer;
}

.caller_control_end {
  min-width: 280px;
  cursor: default;
}

.caller_control {
  .call_icon_green {
    background-color: #00cc6a;
  }

  .call_icon_red {
    background-color: #f7001e;
  }

  p {
    margin: 0;
  }
}

p.caller_control_label {
  font-weight: 500;
  font-size: 14px;
}

.caller_control .patient-name {
  color: #999;
  font-size: 11px;
  font-weight: 400;
  width: 130px;
}

.mute_icon {
  background-color: #dadada;
}

.caller_patient-name {
  width: fit-content;
}

.caller_control {
  .patient-info {
    display: flex;
    align-items: center;
    gap: 10px;

    .patient-img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid #00cc6a;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin-top: -3px;
      }
    }
  }

  .icons.d-flex {
    gap: 10px;
  }

  .d-flex.calling-label {
    gap: 6px;
    align-items: center;
  }
}

.start-nms {
  align-items: center;
  gap: 10px;
}

.caller_control_end.answered {
  background-color: #004825;

  p.caller_control_label,
  .patient-info > p {
    color: #fff;
  }

  .calling-label > svg {
    color: #fff !important;
    fill: #fff !important;
  }
}

