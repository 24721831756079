/* Style for the user profile card */
.medicine-card {
  margin-bottom: unset;
}

/* Style for the user's name */

/* Style for the user's date of birth and sex */
.medicine-card p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}
.ateps_item {
  left: -80px !important;
}
/* Style for the user's allergies */
.medicine-card p:last-child {
  margin-bottom: 0;
}

/* Style for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.medicine-info {
  display: flex;
  padding: 10px;
}

.medicine-label {
  font-weight: bold;
}

.prescription-pills {
  margin-right: 10px;
}

.quantity {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 55px !important;
  justify-content: center;
  padding: 6px 15px;
}

.ca {
  height: 65px !important;
}

.dose {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  display: flex;
  align-items: center;

  height: 55px;
}

.dose-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 55px;
  padding: 6px 15px;
}
.dose-element input {
  width: 100%;
}
.dose-element input:focus {
  outline: none;
  border: 1px solid #eee;
  border-radius: 10px;
}

.quantity-box {
  width: 100%;
}

.quantity-box:focus {
  outline: none;
  border: 1px solid #eee;
  border-radius: 10px;
}
.switch-content {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active-switch {
  border: 1px solid #00a3da;
}
.switch-content-reports {
  width: 100%;
  border-radius: 11px;
  background: #ffffff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-item {
  width: 52%;
  text-align: end;
}

.add-item-d {
  display: none;
}

.disable {
  border: 0;
  background-color: inherit;
}

.MuiInputBase-root::before {
  border-bottom-style: unset !important;
}

.enable {
  border: 1px solid #999;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 3px 8px;
}

.section-heading {
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
}
/* new css */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* height: 40px; */
  background: #00cc6a;
  margin-top: -40px;
  border-radius: 15px 15px 0 0;

  color: #fff;
}

.card-header-title {
  display: flex;
  gap: 10px;
}
.group_des {
  padding: 5px;
}
.group-des-p-orange {
  color: #de9a5e;
}

.group-des-p-risk {
  color: #a4132f;
}
.card-header-title p {
  color: #fff;
}
.group_check {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.group_count {
  display: flex;
  gap: 15px;
}
.medicine-card .card-step-number {
  left: -80px;
}

.card-content {
  padding: 20px;
}

.medicine-card .medicine-card-heading {
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-items: center;
}

.card-actions {
  margin-bottom: 12px;
}

.card-actions,
.card-right-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.card-right-actions p {
  margin: 0;
}

.card-accordion-cont p {
  margin: 0;
}
.drug_item_card {
}

.MuiAccordion-root {
  box-shadow: unset;
}

.card-accordion-cont > div {
  display: flex;
  justify-content: space-between;
}

.card-accordion-cont > div > div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.core-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.active-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.mds-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.reports-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dosagetime-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dispensing-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.schedule-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px !important;
}
.dose .MuiFormControl-root {
  width: 100% !important;
}
.form-buttons {
  background: #00a3da;
  color: #fff;
  border-radius: 10px;
  padding: 9px;
}
.buttons-bar {
  display: flex;
  gap: 10px;
}
.warning-switch {
  display: flex;
  gap: 8px;
  padding: 10px;
  align-items: center;
}
.buttons-enable {
  display: flex;
  justify-content: space-between;
}
.buttons-disable {
  display: none;
}
.blue {
  background: #0072ff !important;
}
.green {
  background: #00cc6a !important;
}
.orange {
  background: #f5a623 !important;
}

.card-right-actions img:hover {
  cursor: pointer;
}
.dispense-label {
  color: rgb(214, 35, 57);
  border: 1px solid rgb(214, 35, 57);
  border-radius: 10px;
  padding: 2px;
  width: max-content;
  font-weight: 600;
  font-size: 13px !important;
  padding: 2px 10px;
  background: rgb(250, 234, 236);
}
.validation-message {
  color: rgb(237, 72, 72);
  margin: 5px 0;
  font-size: 12px !important;
}
.outer-item {
  flex-direction: column;
  border-radius: 11px;
  background: #f2f2f2;
  display: flex;
  width: 100%;
  min-height: 55px;
  height: 100%;
  padding: 6px 15px;
}

.outer-item .MuiSelect-select {
  /* padding: 0 !important; */
  padding-top: 10px;
}

.outer-item .custom-dropdown .MuiFormControl-root {
  margin-top: 8px;
}
.outer-boxes {
  display: flex;
  align-items: start;
  gap: 10px;
  margin: 10px 0px 10px 0px;
}

/* Tooltip CSS Classes */

.nmstooltip-container {
  width: 100%;
  height: 100%;
}

.nmstooltip-title {
  padding: 10px;
}

.nmstooltip-title-text {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.nmstooltip-divider {
  height: 1px;
  background-color: lightgrey;
}

.nmstooltip-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.nmstooltip-item {
  width: 48%;
}

.nmstooltip-caption {
  color: black;
  font-weight: bold;
}

.nmstooltip-description {
  color: grey;
  font-weight: bold;
}

/* Dark Mode Classes */

.nmstooltip-dark-container {
  background-color: #111111;
}

.nmstooltip-dark-title-text {
  color: white;
}

.nmstooltip-dark-divider {
  background-color: #444444; /* Darker divider for dark mode */
}

.nmstooltip-dark-caption {
  color: white;
}

.nmstooltip-dark-description {
  color: lightgrey;
}
