.modal-header {
    justify-content: unset !important;
    gap: 8px;
    border: none !important;
  }
  
  .modal-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: red;
  }
  
  .modal-footer,
  .modal-body {
    border: none !important;
    margin: 0 auto;
  }
  
  .endorse-heading {
    display: flex;
    align-items: baseline;
    gap: 10px;
    color: #00a3da;
    margin-left:10px;
    // border-bottom: 1px solid #ddd;
    padding: 5px 5px;
  
    h5 {
      color: var(--endorsement-heading-color);
      font-size: 16px;
    }
  }
  
  .endorse-switch {
    // border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .form-buttons {
    background: #00a3da;
    color: #fff;
    border-radius: 10px;
    padding: 9px;
  }
  
  .buttons-bar-en {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  
  .endorse-item {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .prescriber-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0px 0px 15px 0px;
  }
  
  .dispenser-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0px 0px 14px 0px;
  }
  
  .endorse-item-input
  {
    border: 1px solid grey;
      border-radius: 6px;
  }
  
  .endorsement-date-picker
  {
    color-scheme: var(--endorsement-datepicker-icon);
    padding: 10px;
    font-size: 14px;
    font-family: "SF-Pro Regular", sans-serif !important;
    border-radius: 8px;
    border: 1px solid grey;
    color: var(--endorsement-datepicker-color);
    width: 50% !important;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color:var(--endorsement-datepicker-bg)
  }
  
  