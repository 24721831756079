.modalHeadings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.serviceName {
  font-weight: 590;
  font-size: 14px;
}

.userName {
  font-weight: 400;
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}

.navButtons {
  display: flex;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 35px 25px 35px;
}

.Questionheading {
  font-size: 14px;
  font-weight: 590;
}

.stepOneQuestion,
.stepTwoQuestion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  border-radius: 20px !important;
}

.payment-choice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 9%;
  margin-bottom: 10%;
  gap: 20px;
  justify-content: center;
}

.choiceBox {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid lightgray;
  height: 185px !important;
  width: 250px;
  align-items: center;
  transition: border-color 0.3s;
  cursor: pointer;

  /* Add a transition for smooth effect */
}

.navBtnbwd {
  background-color: white;
  color: black;
  border-radius: 14px;
  padding: 9px 20px 9px 16px;
  border: 2px solid black;
}

.navBtnfwd {
  background-color: black;
  color: white;
  border-radius: 14px;
  padding: 9px 16px 9px 20px;
}

.btnText {
  font-size: 12px;
}

.forwardIcon {
  color: white;
  margin-bottom: 2px;
  margin-left: 14px;
  font-size: 24px;
}

.backwardIcon {
  margin-right: 14px;
  color: black;
  font-size: 24px;
  margin-bottom: 2px;
}

/* In your CSS */

.custom-modal-dialog {
  max-width: 800px;

  /* Set the desired maximum width */
  width: 80%;

  /* Or use a specific width value */
}

.choiceBox {
  p {
    font-weight: 590;
  }

  &:hover {
    border-color: black;
    border: 3px solid;

    /* Change border color on hover */
  }
}

.stepIndicators {
  width: 30px !important;
}

.firstStep {
  justify-content: flex-end !important;

  /* Adjust the alignment for the first step */
}

/* Styles for steps other than the first */

.notFirstStep {
  justify-content: space-between;

  /* Distribute items with space between them */
}

.costField {
  display: flex;
  justify-content: center;
  margin-top: 9%;
  margin-bottom: 10%;
}

.costInput {
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 180px;
  width: 500px;
  padding: 8px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  transition: border-color 0.3s ease;

  /* Add smooth transition for border change */

  &:focus {
    border: 3px solid black;

    /* Change border to 3px solid black when focused */
  }
}

.stepFourQuestion,
.stepFiveQuestion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pharmacyList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 50px;
  gap: 15px;
}

/* Add a rounded tick icon */

.pharmacy {
  position: relative;
  border: 1px solid lightgray;
  border-radius: 20px;
  height: 55px;
  padding: 11px 15px 11px 15px;
  display: flex;
  flex-direction: row;
}

.checkIcon {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 3px;
}

.pharmacyName {
  margin-left: 30px;
}

.location {
  color: rgba(102, 102, 102, 1);
  margin-left: 30px;
}

.stepSixQuestion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.qrContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}

.qrBox {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 20px;
}

.indicatorsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.stepIndicators {
  width: 10%;
  height: 3px;
  margin: 0 4px;

  &.inactive {
    background-color: lightgrey;
  }
}

.stepThreeQuestion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.schedulesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 100px;
}

.serviceSchedulesContainer
{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 100px;
  gap:15px;
}

.schedule-accordian {
  border-radius: 20px !important;

  /* Adjust this value as needed */
  box-shadow: 1px 8px 18px rgba(0, 0, 0, 0.1) !important;

  /* Add the desired shadow styles */
}

.product-schedule-accordian {
  border-radius: 20px !important;

  /* Adjust this value as needed */
  box-shadow: 1px 8px 18px rgba(0, 0, 0, 0.1) !important;

  /* Add the desired shadow styles */
}

.switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.day {
  margin-left: 20px;
  font-weight: 590;
  margin-bottom: 0px;
  font-size: 15px;
}

.range {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  gap: 20px;
}

.from,
.until {
  border: 1px solid lightgray;
  height: 35px;
  width: 150px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;

  p {
    font-size: 14px;
    margin: 0px;
    font-weight: 510;
  }
}

.schedule {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.accordianHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
}

.rangeIndicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(153, 153, 153, 1);
}

.scheduledetails {
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0px;
  }
}

.duration {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}

.staticDropdown {
  background-color: rgba(242, 242, 242, 1);
  width: 250px !important;
  height: 40px;
  border-radius: 12px;
  display: flex;
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

.dropdownValue {
  font-size: 14px;
  margin: 0px;
  font-weight: 510;
}

.until-text {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.icons-container {
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  gap: 13px;
}

.noSchedule {
  border: 1px solid lightgray;
  height: 35px;
  width: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 45px;
  padding: 5px;

  p {
    font-size: 14px;
    margin: 0px;
    font-weight: 510;
  }
}

.qrBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleDropdown {
  width: 250px !important;
  border-radius: 12px !important;
  height: 40px !important;
  background-color: rgba(242, 242, 242, 1) !important;
  border: none !important;
}

.p-placeholder,
.p-inputtext {
  font-size: 14px !important;
  font-weight: 510 !important;
  color: #000000 !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
}

.scheduleDropdown {
  .p-dropdown-item {
    font-weight: 590 !important;
    font-size: 14px !important;
  }

  .p-dropdown-panel {
    border-radius: 15px !important;
  }

  .p-dropdown-label {
    padding-left: 20px !important;
    padding-top: 7px !important;
  }

  .p-dropdown-trigger {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.addIcon,
.copyIcon,
.trashIcon {
  cursor: pointer;
}
