@font-face {
  font-family: SF-Pro Regular;
  src: url(./fonts/SF-Pro/SF-Pro-Text-Regular.otf);
}

.font-face-sf-pro,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button {
  font-family: "SF-Pro Regular", sans-serif !important;
}

/* Headings */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

/* Anchor tags (links) */
a {
  font-size: 1.1rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.light-theme {
  background-color: #fff;
  --global-bg-color: #ffffff;
  --main-heading: #000;
  --para-clr: #333;
  --btn-bg-color: #489ec7;
  --btn-bg-color-hover: #91d5f5;
  --btn-text-clr: #fff;
  --bg-clr: #fff;
  --card-bg-color: #ffffff;
  --overview-card-bg-color: #ffffff;
  --h2-color: #000000;
  --drug-card: #fff;
  --inner-card-bg-color: #f2f2f2;
  --warn-color: #efdfc7;
  --warn-btn-clr: #f0cd98;
  --menu-btn: #efeded;
  --label-btn: #ffffff;
  --label-btn-hover: #fefefe;
  --label-border-color: #eeeeee;
  --map-dropdown: #fff;
  --message-box-bg: rgba(255, 255, 255, 0.25);
  --card-inner-value: #000;
  --edit-medicine-icon-color: #999999;
  --popup-bg-color: #ffffff;
  --popup-border-color: #ccc;
  --p-warn-bg-color: rgba(0, 204, 106, 0.3);
  --p-warn-border-color: rgba(0, 204, 106, 0.3);
  --warn-button-checked-color: #fff;
  --modal-border-outline: 1px solid rgba(153, 153, 153, 1);
  --invite-field-copy-button-bg: #eee;
  --invite-field-copy-button-border: 2px solid #ccc;
  --addNew-uploadBox: #f2f2f2;
  --light-999-dark-fff: #999;
  --addNew-uploadBox-span: #000;
  --outer-item: #f2f2f2;
  --selectPharmacy-pharmacy-name: #000;
  --footer-color: #efeded;
  --count: #eee;
  --map-opacity: rgba(255, 255, 255, 0);
  --patient-list-date: #f3eded;
  --allergy-card: #f2f2f2;
  --allergy-card-hover: #0a0a0a;
  --appointment-border-line: rgba(0, 0, 0, 0.25);
  --patient-dashboard-desc-color: #666;
  --patient-tab-control-button-color: #000000;
  --patient-tab-grid-list-cells: #666666;
  --placeholder-hover-bg-color: #d1d1d1;
  --sendBird-chanels-body: #e0f1fd;
  --linear-gradient: linear-gradient(
    135deg,
    rgb(239, 245, 239),
    rgb(215, 246, 215)
  );

  --linear-gradient-patient-nominated: linear-gradient(
    135deg,
    rgb(239, 245, 239),
    rgb(233, 244, 170)
  );

  --linear-gradient-graphs: linear-gradient(
    135deg,
    rgb(239, 245, 239),
    rgb(215, 246, 215)
  );
  --new-clinic-button: #000000;
  --modal-service-heading: #000000;
  --indicators-color: #000000;
  --indicators-dark-inactive: rgb(181, 177, 177);
  --steps-btn-fwd: none;
  --disabled-accordian-bg: #b8b8b8;
  --check-icon-border: 1px solid #000000;
  --check-icon-color: #fff;
  --check-icon-bg: #000000;
  --tooltip-progress-bar: #eee;
  --patient-search-row-hover-bg: #c2f5f5;
  --container-border: #eee;
  --bottomwrapper-boxshadow: rgba(0, 0, 0, 0.25);
  --serviceList-sidebar: #f5f5f5;
  --serviceList-sidebar-hover: #cfcfcf;
  --grey-111: #fff;
  --grey-222: #fff;
  --serviceitem-active: #cfcfcf;
  --services-content-bg: #f2f2f2;
  --services-kanban-service: #fff;
  --toggleSwitch-bg: #bababa;
  --sidebar-icons-color: #6a6a6a;
  --black-666: #666;
  --black-444: #444;
  --addMemberbox_bg: #f5f5f5;
  --uploadImage_wrapper: #f9f9f9;
  --grey-3535-f5f5f5: #f5f5f5;
  --service-sidebar-hover: #e3e3e3;
  --orbit-box-bg: #fff;
  --orbit-box-border: #eee;
  --orbit-round-boxes: #f2f2f2;
  --orbit-action-group-bg: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  --orbit-audit-btn: #ccc;
  --orbit-withdraw-btn: #000;
  --bottomBar_image-bg: #eee;
  --bottomBar_image-border: #ccc;
  --timeline-email-content: #f2f2f2;
  --timeline-email-content-border: #eee;
  --timeline-signature-content: #f2f2f2;
  --timeline-signature-content-border: #eee;
  --timeline-notes-content: #f2f2f2;
  --timeline-notes-content-border: #eee;
  --timeline-inner-notes-content: #fff;
  --timeline-inner-notes-content-border: #eee;
  --textfield: #fff;
  --caller-control-bg: #f2f2f2;
  --search-transcript-border: #eee;
  --info-box-border: #eee;
  --info-box-label: #000;
  --settings-card-bg: #fff;
  --sidebar-hover-color: #f2f2f2;
  --call-recorder-bg: #f2f2f2;
  --ov-bg: #f2f2f2;
  --ov-fff-222: #fff;
  --calling-bg: #f2f2f2;
  --calling-option: #ddd;
  --patient-tag: #fdf0f0;
  --kanban-date-bg: #eee;
  --med-box-bg: #f2f2f2;
  --analytics-btn: #ddd;
  --nms-notes-bg: #e1f4fb;
  --nms-notes-border: #ccc;
  --date-time-border: #0a0a0a;
  --settings-fields: #f2f2f2;
  --services-header-progress-border: rgba(0, 0, 0, 0.1);
  --kanban-stack-border: #cfcfcf;
  --grouped-patients-border: #f2f2f2;
  --grouped-patients-border-hover: #cbcbcb;
  --kanban-stack-border: rgba(0, 0, 0, 0.2);
  --kanban-stack-border-2: rgba(0, 0, 0, 0.12);
  --kanban-stack-border-3: rgba(0, 0, 0, 0.14);
  --user-signup-bg: #f2f2f2;
  --map-modal-bg: #fff;
  --map-dropdown-label: #000;
  --map-modal-button-hover: #f2f2f2;
  --invite-scroll-thumb: #aaa;
  --invite-scroll-track: #ccc;
  --pharmacy-selection-border: #aaa;
  --active-services-tab-label-bg: #f2f2f2;
  --orbit-background-box: #fff;
  --listview-grid-border: #d9cdcd;
  --ag-row-color: #09090b;
  --bottom-bar-bg: #2a2e34;
  --date-range-picker-bg: #f2f2f2;
  --services-container-footer-bg: #e1e1e1;
  --pinned-label: #666666d1;
  --awaiting-query-label: #66666629;
  --pres-border: #ddd;
  --service-stage-bg: #e9eeea;
  --service-strage-border: #eeeeee;
  --service-stage-inner-heading: #000;
  --service-crt-btn-bg: #fff;
  --drug-action-text: #333;
  --grouped-notes-value: #333;
  --ps-tab-scroll: #f2f2f2;
  --ps-add-item-dropdown: #f2f2f2;
  --email-templates-bg-hover: #e9e9e9;
  --orbit-scrolltrack: #ccc;
  --orbit-scrollthumb: #eee;
  --info-wrapper-list-bg: #f2f2f2;
  --wp-pharmanaut-inbox-bg: #f2f2f2;
  --availibility-value: #000;
  --pharmanaut-service-check: #f2f2f2;
  --ordering-tooltip-icon: #0a0a0a;
  --order-tooltip-bg: #f0f0f0;
  --warn-new-bg: #f4ebda;
  --endorsement-heading-color: #00a3da;
  --endorsement-datepicker-color: #000;
  --endorsement-datepicker-bg: #fff;
  --endorsement-datepicker-icon: light;
  --timeline-notes-content-bg: #f2f2f2;
  --consultationPage-bg-color: #f2f2f2;
  --find-patient-modal-bg: #fff;
  --find-patient-modal-search-input-bg: #f5f5f5;
  --find-patient-modal-patient-info-hover-bg: #e5f7fe;
  --find-patient-modal-patient-info-select-bg: #b4e1f2;
  --find-patient-modal-patient-name: #2d69e1;
  --find-patient-modal-button: #222;
  --find-patient-comparison-border: #d9d9d9;
}

.dark-theme {
  --patient-search-row-hover-bg: #000;
  --drug-card: #222;
  background-color: #111;
  --global-bg-color: #000000;
  --main-heading: #fff;
  --para-clr: #fff;
  --btn-bg-color: #000000;
  --btn-bg-color-hover: #585656;
  --btn-text-clr: #fff;
  --bg-clr: #222222;
  --card-bg-color: #444444;
  --overview-card-bg-color: #323232;
  --inner-card-bg-color: #111;
  --label-color: #fff;
  --h2-color: #ffffff;
  --warn-color: #e5b875;
  --warn-btn-clr: #f0cd98;
  --menu-btn: #333333;
  --label-btn: #444444;
  --label-btn-hover: #000000;
  --label-border-color: #000000;
  --map-dropdown: #444;
  --message-box-bg: #000;
  --card-inner-value: #ffffff;
  --edit-medicine-icon-color: #ffffff;
  --popup-bg-color: #444444;
  --popup-border-color: #000;
  --p-warn-bg-color: rgba(0, 204, 106, 0.06);
  --p-warn-border-color: rgba(0, 204, 106, 0.06);
  --warn-button-checked-color: rgba(68, 68, 68, 1);
  --modal-border-outline: 1px solid #000;
  --invite-field-copy-button-bg: #444;
  --invite-field-copy-button-border: 2px solid #000;
  --addNew-uploadBox: #222;
  --light-999-dark-fff: #fff;
  --addNew-uploadBox-span: #fff;
  --outer-item: #111;
  --selectPharmacy-pharmacy-name: #fff;
  --footer-color: #444444;
  --count: #828181;
  --map-opacity: rgba(0, 0, 0, 0);
  --patient-list-date: #000;
  --allergy-card: #282727;
  --allergy-card-hover: #fff;
  --appointment-border-line: rgba(255, 255, 255, 0.25);
  --patient-dashboard-desc-color: #fff;
  --patient-tab-control-button-color: #999999;
  --patient-tab-grid-list-cells: #999999;
  --placeholder-hover-bg-color: #000;
  --sendBird-chanels-body: #000;
  --linear-gradient: linear-gradient(135deg, rgb(69, 72, 69), rgb(27, 55, 27));
  --linear-gradient-patient-nominated: linear-gradient(
    135deg,
    rgb(69, 72, 69),
    rgb(93, 108, 30)
  );

  --linear-gradient-graphs: linear-gradient(
    135deg,
    rgb(239, 245, 239),
    rgb(215, 246, 215)
  );
  --new-clinic-button: #000000;
  --modal-service-heading: #fff;
  --indicators-color: #fff;
  --indicators-dark-inactive: rgb(122, 115, 115);
  --steps-btn-fwd: 1px solid #fff;
  --disabled-accordian-bg: #b8b8b8;
  --check-icon-border: 1px solid #fff;
  --check-icon-color: #000000;
  --check-icon-bg: #fff;
  --tooltip-progress-bar: #525252;
  --container-border: #1c1c1c;
  --bottomwrapper-boxshadow: rgb(115 115 115 / 25%);
  --serviceList-sidebar: #222;
  --serviceList-sidebar-hover: #fff;
  --grey-111: #111;
  --grey-222: #222;
  --serviceitem-active: #fff;
  --services-content-bg: #0a0a0a;
  --toggleSwitch-bg: #555;
  --sidebar-icons-color: #fff;
  --black-666: #eee;
  --black-444: #eee;
  --addMemberbox_bg: #111;
  --uploadImage_wrapper: #222;
  --grey-3535-f5f5f5: #353535;
  --service-sidebar-hover: #222;
  --orbit-box-bg: #222;
  --orbit-box-border: #1d1d1d;
  --orbit-round-boxes: #111111;
  --orbit-action-group-bg: linear-gradient(0deg, #000000, #000000),
    linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  --orbit-audit-btn: #444;
  --orbit-withdraw-btn: #fff;
  --bottomBar_image-bg: #222;
  --bottomBar_image-border: #676767;
  --timeline-email-content: #402a47;
  --timeline-email-content-border: #723a6f;
  --timeline-signature-content: #111;
  --timeline-signature-content-border: #1b1a1a;
  --timeline-notes-content: #111;
  --timeline-notes-content-border: #0a0a0a;
  --timeline-inner-notes-content: #222;
  --timeline-inner-notes-content-border: #0a0a0a;
  --textfield: #222;
  --caller-control-bg: #2a2a2a;
  --search-transcript-border: #444;
  --info-box-border: #000;
  --info-box-label: #999;
  --settings-card-bg: #222;
  --sidebar-hover-color: #222;
  --call-recorder-bg: #111;
  --ov-bg: #111;
  --ov-fff-222: #222;
  --calling-bg: #111;
  --calling-option: #222;
  --patient-tag: #111;
  --kanban-date-bg: #111;
  --med-box-bg: #111;
  --analytics-btn: #222;
  --nms-notes-bg: #222;
  --nms-notes-border: #000;
  --date-time-border: #626262;
  --settings-fields: #111;
  --services-header-progress-border: rgba(255, 255, 255, 0.1);
  --kanban-stack-border: #333;
  --grouped-patients-border: #222;
  --grouped-patients-border-hover: #555;
  --kanban-stack-border: rgba(255, 255, 255, 0.2);
  --kanban-stack-border-2: rgba(255, 255, 255, 0.12);
  --kanban-stack-border-3: rgba(255, 255, 255, 0.14);
  --user-signup-bg: #000;
  --map-modal-bg: #333;
  --map-dropdown-label: #ccc;
  --map-modal-button-hover: #000;
  --invite-scroll-thumb: #555;
  --invite-scroll-track: #333;
  --pharmacy-selection-border: #00000040;
  --active-services-tab-label-bg: #111;
  --orbit-background-box: url("https://titanpmr.com/media/bxenrudz/pexels-nicole-avagliano-2706654.jpg");
  --listview-grid-border: #444;
  --ag-row-color: #fafafa;
  --bottom-bar-bg: #777;
  --date-range-picker-bg: #222;
  --services-container-footer-bg: #000;
  --pinned-label: #333;
  --awaiting-query-label: #333;
  --pres-border: #333;
  --service-stage-bg: #000;
  --service-strage-border: #3d3d3d;
  --service-stage-inner-heading: #fff;
  --service-crt-btn-bg: #222;
  --drug-action-text: #888;
  --grouped-notes-value: #9ebeaf;
  --ps-tab-scroll: #303030;
  --ps-add-item-dropdown: #555;
  --email-templates-bg-hover: #000;
  --orbit-scrolltrack: #333;
  --orbit-scrollthumb: #555;
  --info-wrapper-list-bg: #111;
  --wp-pharmanaut-inbox-bg: #222;
  --orbit-scrolltrack: #222;
  --orbit-scrollthumb: #444;
  --availibility-value: #fff;
  --pharmanaut-service-check: #111;
  --ordering-tooltip-icon: #888;
  --order-tooltip-bg: #34393d;
  --warn-new-bg: #f4ebda;
  --endorsement-heading-color: #fff;
  --endorsement-datepicker-color: #fff;
  --endorsement-datepicker-bg: #222;
  --endorsement-datepicker-icon: dark;
  --timeline-notes-content-bg: #222;
  --consultationPage-bg-color: #111;
  --find-patient-modal-bg: #1f1f1f;
  --find-patient-modal-search-input-bg: #111;
  --find-patient-modal-patient-info-hover-bg: #2a2a2a;
  --find-patient-modal-patient-info-select-bg: #2c2c2c;
  --find-patient-modal-patient-name: #699bfe;
  --find-patient-modal-button: #111;
  --find-patient-comparison-border: #eeeeee5a;
}

/* .inline-not {
  background: var(--inner-card-bg-color) !important;
}
.inline {
  background: var(--inner-card-bg-color) !important;
} */

.graph_bars {
  fill: var(--linear-gradient-graphs) !important;
}

.heading-estimate {
  background: var(--linear-gradient) !important;
}

.tab_indicator_line {
  background: var(--para-clr) !important;
}

.title-heading {
  color: var(--h2-color) !important;
}

.user_record {
  background: var(--global-bg-color) !important;
  border: 1px solid var(--container-border);
}

.chart-stages {
  background: var(--global-bg-color) !important;
  border: 1px solid var(--container-border);
}

.analytic-desc {
  background: var(--global-bg-color) !important;
  border: 1px solid var(--container-border);
}

.map-blur {
  background-image: linear-gradient(
    to bottom,
    var(--map-opacity) 30%,
    var(--bg-clr) 45%
  );
}

h1,
h2,
h4,
h5,
h6 {
  color: var(--main-heading);
}

p,
span {
  color: var(--para-clr);
}

.btn-class {
  background-color: #222222 !important;
  color: var(--btn-text-clr) !important;
  min-width: 150px;
  margin: 3px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #ccc;
  height: 40px;
  width: 30px;
  font-size: 15px;
}

.clinicalchecks .btn-class {
  width: fit-content !important;
  padding: 5px 10px;
}

.btn-class:hover {
  background-color: #424141 !important;
  color: var(--btn-text-clr) !important;
}

.customcard {
  background: var(--grey-222) !important;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  padding: 10px;
}

.customcard:not(:first-child) {
  margin-top: 25px;
}

.image-div {
  background: var(--grey-222) !important;
}

.actions {
  background: var(--footer-color) !important;
}

.overview_footer {
  background: var(--footer-color) !important;
}

.col-box {
  background: var(--overview-card-bg-color) !important;
}

.services_kanban {
  background: var(--inner-card-bg-color) !important;
}

.service_kanban {
  background: var(--inner-card-bg-color) !important;
}

.date {
  background: var(--count) !important;
}

.priority {
  background: var(--count) !important;
}

.count {
  background: var(--count) !important;
}

.therapy-accordion {
  background: var(--inner-card-bg-color) !important;
}

.icons-color {
  fill: var(--main-heading) !important;
}

.dayVal .icons-color > path {
  fill: var(--main-heading) !important;
}

.icons-box {
  color: var(--main-heading) !important;
}

.bottomActionBar_wrapper {
  color: var(--main-heading) !important;
  border: 1px solid var(--container-border);
  box-shadow: 0px 4px 40px var(--bottomwrapper-boxshadow);
}

.tabs_text,
.tabs_text_alt {
  color: var(--main-heading) !important;
}

.overview-boxes {
  background: var(--bg-clr) !important;
}

.services_list.active .service_item_title {
  color: var(--h2-color) !important;
  font-weight: 700;
}

.dose-drugs {
  background: var(--global-bg-color) !important;
}

.info-box {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.travel-info-box {
  background-color: var(--timeline-notes-content-bg) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.info_input {
  background: var(--bg-clr) !important;
}

.nms-tabs-container {
  background: var(--grey-222) !important;
}

.action-footer {
  background: var(--bg-clr) !important;
}

.inline-info {
  background: var(--global-bg-color) !important;
}

/* .tasks_tab {
  background: var(--global-bg-color) !important;
} */
/* .services_sidebar_container .services_list:hover {
  background-color: var(--drug-card) !important;
  transition: 0.2s linear;
} */
.services_list.active {
  background-color: var(--drug-card) !important;
}

.custom-label {
  color: #999;
}

/* .wrapper {
  background: var(--bg-clr) !important;
} */
.content {
  background: var(--bg-clr) !important;
}

.wrapper-alt {
  background: var(--bg-clr) !important;
}

.appointment {
  background: var(--bg-clr) !important;
}

.top-tabs {
  background: var(--grey-222) !important;
  border-radius: unset;
}

.service_title {
  color: var(--h2-color) !important;
}

.services_kanban-patientName {
  color: var(--h2-color) !important;
}

.service_kanban-patientName {
  color: var(--h2-color) !important;
}

.para_color {
  color: var(--h2-color) !important;
}

.tab-disable {
  color: #b4b0b0 !important;
}

.breadCrumb_title {
  color: var(--h2-color) !important;
}

.patient_service_name {
  color: var(--h2-color) !important;
}

.services_listview {
  background: var(--inner-card-bg-color) !important;
}

/* .service_item_sidebar {
  background: var(--bg-clr) !important ;
} */
.pharmacy_header_name {
  color: var(--h2-color) !important;
}

.live-feed-text {
  color: var(--h2-color) !important;
}

.pharmacy_header_address {
  color: var(--h2-color) !important;
}

.dose {
  background: var(--map-dropdown) !important;
}

.cc-dropdown-class {
  background: var(--map-dropdown) !important;
}

.switch-content {
  background: var(--map-dropdown) !important;
}

.switch-content-reports {
  background: var(--grey-222) !important;
}

.radio-text-box {
  background: var(--map-dropdown) !important;
}

.radio-text-box-outer {
  background: var(--outer-item) !important;
}

.quantity {
  background: var(--map-dropdown) !important;
}

.endorsement-box {
  border-radius: 20px;
  background: var(--drug-card);
}

.template-confirmation-box {
  border-radius: 20px;
  background: var(--drug-card);
}

@media screen and (max-width: 1400px) {
  .endorsement-box {
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
  }

  /* width */
  .endorsement-box::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .endorsement-box::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .endorsement-box::-webkit-scrollbar-thumb {
    background: #eee;
    transition: 0.2s linear;
  }

  /* Handle on hover */
  .endorsement-box::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: 0.2s linear;
  }
}

.actionIconGroup_iconBox {
  background: var(--label-btn) !important;
}

.services_details_main_wrapper .drawer_wrapper {
  background: var(--grey-222) !important;
}

.services_details_main_wrapper {
  background: var(--grey-111) !important;
}

.completed_services_drawer .MuiPaper-root {
  background: var(--grey-111) !important;
}

.completed_services_drawer .timeline_accordion {
  background: transparent !important;
}

.card-heading {
  color: var(--h2-color);
  font-size: 1.5em;
  font-weight: 400;
  display: flex;
  align-items: center;
  /* margin-top: -45px; */
  padding: 10px;
  gap: 10px;
  text-transform: capitalize;
}

.medicine-card-heading {
  color: var(--h2-color);
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: start;
  padding: 10px;
  gap: 10px;
  text-transform: capitalize;
}

.medicine-card-heading-dispensed {
  color: var(--h2-color);
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: start;
  padding: 0 !important;
  margin: 0 !important;
  gap: 10px;
  text-transform: capitalize;
  text-decoration: line-through;
}

i {
  color: var(--h2-color);
}

.p-message-warn-orange {
  /* border: solid var(--warn-color);
  background: var(--warn-color); */
  border: solid var(--warn-new-bg);
  background: var(--warn-new-bg);
}

.p-group-warn-orange {
  border: solid var(--warn-new-bg);
  background: var(--warn-new-bg) !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1) !important;
}

.p-message-warn-risk {
  border: solid #e76f7b;
  background: #e5959d;
}

.p-group-warn-orange-checked {
  border: 1px solid var(--p-warn-bg-color);
  background: var(--p-warn-border-color) !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1) !important;
}

.p-message-warn-orange-checked {
  border: 1px solid var(--p-warn-bg-color);
  background: var(--p-warn-border-color);
}

.p-group-warn-risk {
  border: 1px solid #e76f7b !important;
  background: #e5959d !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1) !important;
}

.p-group-warn-risk-checked {
  border: 1px solid var(--p-warn-bg-color);
  background: var(--p-warn-border-color) !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1) !important;
}

.p-message-warn-risk-checked {
  border: 1px solid var(--p-warn-bg-color);
  background: var(--p-warn-border-color);
}

.warn-button-orange-checked {
  color: var(--warn-button-checked-color);
}

.warn-button-risk-checked {
  color: var(--warn-button-checked-color);
}

.warn-button-orange {
  /* border: 1px solid #000;
  background-color: var(--warn-btn-clr); */
  background-color: #ffffff;
  border: 1px solid #de9a5e;
  color: #de9a5e;
}

.warn-button-risk {
  background-color: #ffffff;
  border: 1px solid #a4132f;
  color: #a4132f;
}
.do-not-dispense-tags {
  background-color: #ddd3d3;
  border: 1px solid #a4132f;
  color: #a4132f;
  font-weight: bold;
  display: flex;
}
.do-not-dispense-container {
  display: flex;
  gap: 10px;
}
.issue-button {
  white-space: nowrap;
  color: var(--para-clr);
  margin-left: 20px;
  font-size: 14px;
  text-decoration: underline;
}

.issue-button-active {
  font-weight: 800;
  white-space: nowrap;
  color: var(--para-clr);
  margin-left: 20px;
  font-size: 14px;
  text-decoration: underline;
}

.menu-box {
  background-color: var(--menu-btn);
  border-color: var(--menu-btn);
  width: 35px;
  height: 35px;
  border-radius: 6px;
  text-align: center;
  margin: 6px 1px 1px 8px;
}

.p-galleria-thumbnail-wrapper {
  display: none;
}

.image-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  vertical-align: middle;
  height: inherit;
  width: -webkit-fill-available;
  width: -moz-available;
}

.btn-class:disabled,
.btn-class[disabled] {
  background-color: #cccccc !important;
  opacity: 0.6;
  cursor: not-allowed !important;
}

.label-btn {
  background-color: var(--label-btn);
  border: 2px solid var(--label-border-color);
}

.label-btn:hover {
  /* background-color: var(--label-btn-hover); */
  cursor: pointer;
}

.team-add,
.addTeam_icon {
  border: 2px dotted var(--label-border-color);
}

.map_dd {
  background-color: var(--map-dropdown);
}

.message-custom-bg {
  background-color: var(--message-box-bg);
}

.icon-div {
  height: 30px;
  margin-right: 15px;
  padding-bottom: 1px;
}

.card-inner-boxes {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.card-inner-boxes > div:not(.add-item) {
  background-color: var(--inner-card-bg-color);
  border: 2px solid var(--label-border-color);
}

.enable {
  background-color: var(--inner-card-bg-color);
}

.card-inner-value {
  color: var(--card-inner-value);
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
}

.note-box {
  background: var(--outer-item) !important;
}

.input-field {
  background: var(--inner-card-bg-color) !important;
  color: var(--h2-color) !important;
}

.edit-medicine-icon {
  color: var(--edit-medicine-icon-color);
  font-size: 25px;
}

.popup-styles {
  background-color: var(--popup-bg-color);
  border: 1px solid var(--popup-border-color);
}

.oval-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-message {
  color: rgb(221, 82, 82);
  font-size: 14px;
}

/*Invite New Modal*/

.modal-content {
  font-family: "SF-Pro Regular", sans-serif !important;
  border: none !important;
}

.modal-body .customcard {
  box-shadow: none;
}

.inviteModal .modal-title {
  font-size: 14px !important;
}

.inviteModal .modal-body {
  color: var(--h2-color) !important;
}

.inviteModal .error-message {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
}

.inviteList-email {
  background-color: var(--bg-clr);
  border: var(--modal-border-outline) !important;
}

.custom-input {
  background-color: var(--bg-clr);
  border: 1px solid var(--bg-clr);
  width: 100% !important;
  color: var(--h2-color);
}

.custom-endorsement-input {
  background-color: var(--bg-clr);
  /* border: 1px solid var(--bg-clr); */
  width: 50% !important;
  color: var(--h2-color);
}

.p-dropdown {
  background-color: var(--bg-clr) !important;
  border: var(--modal-border-outline) !important;
}

.inviteList-owner {
  border: var(--modal-border-outline);
}

/* .invite-buttons button:first-child {
  background: var(--invite-field-copy-button-bg);
  border: var(--invite-field-copy-button-border);
  color: var(--h2-color);
} */

.invite-buttons button {
  background-color: var(--grey-111);
}

/*Add New Modal*/

.addNew-input-container {
  border: var(--modal-border-outline);
  background-color: var(--bg-clr);
}

.addNew-input-container input {
  color: var(--para-clr);
}

.pharmacy-search-box {
  border: var(--modal-border-outline);
  background-color: var(--bg-clr);
}

.pharmacy-search-box input {
  color: var(--para-clr);
}

.addNew-uploadBox {
  background-color: var(--addNew-uploadBox);
}

.addNew-uploadBox span {
  color: var(--addNew-uploadBox-span);
}

.addNew-uploadBox p {
  color: var(--light-999-dark-fff);
}

.addNewPharmacy-heading {
  color: var(--para-clr);
}

.pharmacy-title {
  color: var(--selectPharmacy-pharmacy-name);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.cluster-btn:hover {
  cursor: pointer !important;
}

.clinical-accordion {
  background: var(--inner-card-bg-color) !important;
}

.patientform_accordion {
  background: var(--bg-clr) !important;
}

/*NMS Screen*/
.drugs-data {
  background: var(--drug-card);
}

.MuiTabs-indicator {
  background-color: var(--h2-color) !important;
}

.team-img-text {
  color: var(--h2-color) !important;
}

.selected_team {
  border-color: var(--h2-color) !important;
}

.MuiInputLabel-root {
  color: var(--h2-color) !important;
}

.settings_page .MuiOutlinedInput-input {
  border-radius: 15px !important;
  background-color: var(--settings-fields) !important;
}

.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #9e9b9e !important;
}

.template_input {
  background: var(--inner-card-bg-color) !important;
}

.template_input_inner {
  background: var(--map-dropdown) !important;
}

/* .accordion-boxes {
  background: var(--outer-item) !important;
} */
.custom-accordion {
  background: var(--inner-card-bg-color) !important;
  border: 2px solid var(--label-border-color) !important;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px !important;
}

.custom-accordion:first-of-type {
  margin-top: 15px !important;
}

.switch-content-reports-outer {
  background: var(--outer-item) !important;
  border: 2px solid var(--timeline-inner-notes-content-border);
}

.multi_select_outer {
  background: var(--outer-item) !important;
}

.outer-item-white {
  background: var(--bg-clr) !important;
}

.outer-item-white-text {
  background: var(--bg-clr) !important;
}

.drugs-data p {
  font-size: 18px;
  font-weight: 600;
  color: var(--h2-color);
}

/* .drugs-data .label {
  font-size: 20px;
  font-weight: 600;
  color: var(--h2-color);
} */
.nms .card-actions {
  margin-top: -40px;
}

.nms .vertical-stepper {
  border-right: none;
}

.nms .MuiAccordion-root {
  box-shadow: none;
}

.nms .medicine-card {
  font-size: 14px;
}

.not-disp-reason {
  height: auto !important;
}

.therapy-level .MuiSelect-select,
.mds-preparation .MuiSelect-select,
.mds-colour .MuiSelect-select {
  padding: 0px 0px 0px 5px;
  font-size: 12px;
}

.therapy-level .MuiInputBase-root,
.mds-preparation .MuiInputBase-root,
.mds-colour .MuiInputBase-root {
  border-radius: 11px;
}

.buttons-bar,
button.issue-button {
  font-size: 12px;
}

.p-message-warn-orange .issue-button {
  color: #0a0a0a;
  font-weight: 600;
}

.p-message-inner .issue-button {
  color: #0a0a0a;
  font-weight: 600;
}

.customcard .radio-form .MuiTypography-root {
  font-size: 12px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.inputDiv {
  border: 1px solid var(--main-heading);
}

.modal-height {
  background-color: var(--card-bg-color) !important;
}

.searchPatient {
  color: var(--main-heading);
}

.suffix-button {
  color: var(--main-heading);
  border: 1px solid var(--main-heading);
}

.patientListHeading {
  color: var(--main-heading);
}

.patientName {
  color: var(--main-heading);
}

.patientList .date {
  background-color: var(--patient-list-date);
}

.prescriptionsHeading {
  color: var(--main-heading);
}

.prescriptionName {
  color: var(--main-heading);
}

.patientDetailsName {
  color: var(--main-heading);
}

.rectangle {
  background-color: var(--patient-list-date);
}

.nominations {
  background-color: var(--patient-list-date);
}

.tags {
  background-color: var(--patient-list-date);
}

.responsible-pharmacist .wrapper {
  background-color: var(--bg-clr);
}

.allergies p,
.in-store-tabs-wrapper .medicine-box,
.card-shaped-content .inner_container,
.appleHealth_container .last_updated {
  background-color: var(--allergy-card);
}

.col-box.patient-detail-box:hover .allergies p {
  color: var(--allergy-card-hover) !important;
}

.patient-detail-appointments .appointment {
  background-color: var(--card-bg-color) !important;
}

.patient-detail-appointments .wrapper::before {
  border: 1px solid var(--appointment-border-line);
}

.lifetimeValue_attributes .box,
.lifetimeValue_chart,
.card-shaped-box,
.card-shaped-content .inner_container .input_wrapper .top > div,
.card-shaped-content .inner_container .input_wrapper .bottom > div {
  background-color: var(--card-bg-color);
}

.card-shaped-content .inner_container .pain,
.card-shaped-content .inner_container .calender,
.card-shaped-content .description,
.in-store-tabs-wrapper .day-date,
.in-store-tabs-wrapper .medicine-box .medicine-type,
.card-shaped-content .inner_container .last-dispensed {
  color: var(--patient-dashboard-desc-color);
}

.contact-detail-box {
  background-color: var(--bg-clr);
}

.serviceClaimStatus h3 {
  color: var(--main-heading);
}

.serviceClaimStatus_wrapper {
  background-color: var(--grey-111);
}

.document-container .card-content {
  background-color: var(--grey-222);
  border: 1px solid var(--container-border);
}

.innerServices .createdByName {
  color: var(--main-heading) !important;
}

.modalHeadings .serviceName {
  color: var(--modal-service-heading) !important;
}

/* .modal-content {
  background-color: var(--global-bg-color) !important;
} */
.clinical_modal {
  background-color: var(--grey-222) !important;
  border-radius: 10px;
  font-family: "SF-Pro Regular", sans-serif !important;
  padding: 6px !important;
}

.stepIndicators.active {
  background-color: var(--indicators-color) !important;
}

.stepIndicators.inactive {
  background-color: var(--indicators-dark-inactive) !important;
}

.Questionheading {
  color: var(--main-heading) !important;
}

.choiceBox p {
  color: var(--main-heading) !important;
}

.navBtnfwd {
  border: var(--steps-btn-fwd) !important;
}

.holiday-accordian {
  background-color: var(--disabled-accordian-bg) !important;
}

.pharmacyName {
  color: var(--main-heading) !important;
}

.checkIcon {
  border: var(--check-icon-border) !important;
  color: var(--check-icon-color) !important;
  background-color: var(--check-icon-bg) !important;
}

.analytics-tab-date-picker {
  background: var(--global-bg-color) !important;
}

.analytics-tab-date-picker input {
  background: var(--inner-card-bg-color) !important;
}

.analytics-tab-date-picker input {
  color: var(--main-heading) !important;
}

.analytics-tab-date-picker .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  background: var(--inner-card-bg-color) !important;
}

.patient_tab {
  background: var(--bg-clr) !important;
}

.patient_tab .patient_tab_side_filters {
  background: var(--bg-clr) !important;
}

.patient_tab .patient_tab_patient_list {
  background: var(--outer-item) !important;
}

.patient_tab .patient_tab_control_button {
  background: var(--bg-clr) !important;
}

.patient_tab .patient_tab_button_span {
  color: var(--patient-tab-control-button-color) !important;
}

.patient_tab_side_filters_container .patient_tab_side_filters_box {
  background: var(--global-bg-color) !important;
}

.patient_tab_side_filters_container .patient_tab_side_filters_box {
  border: 1px solid var(--appointment-border-line) !important;
}

.p-checkbox .p-checkbox-box {
  background: var(--bg-clr) !important;
}

.patient_tab .ag-ltr .ag-cell {
  color: var(--patient-tab-grid-list-cells) !important;
}

.patient-tag-container .custom_tag_succes {
  background: var(--linear-gradient) !important;
}

.patient-tag-container .custom_tag_warning {
  background: var(--linear-gradient-patient-nominated) !important;
}

.patient_tab .ag-header {
  background: var(--outer-item) !important;
}

.patient_tab .ag-body {
  background: var(--bg-clr) !important;
}

.patient_tab .ag-root-wrapper {
  background: var(--outer-item) !important;
}

.overview_mapbox::after {
  background: var(--bg-clr);
}

.date-range-picker-container .p-button {
  background: var(--global-bg-color) !important;
}

.date-range-picker-container .p-icon {
  color: var(--patient-tab-grid-list-cells) !important;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-clr) !important;
}

.sendbird-channel-list__body {
  background: var(--bg-clr) !important;
}

.sendbird-channel-preview {
  background: var(--sendBird-chanels-body) !important;
}

/* .date-range-picker-container .p-datepicker .p-datepicker-header{
  background: var(--global-bg-color) !important;
}

.date-range-picker-container .p-datepicker{
  background-color: var(--global-bg-color) !important;
}--label-border-color

.date-range-picker-container .p-component{
  color: var(--patient-tab-grid-list-cells) !important;
  } */

.pharmacy_listview {
  background-color: var(--card-bg-color);
}

.pharmacy_listview-btn .count {
  background-color: var(--label-border-color);
}

.pharmacy_listview-btn {
  background-color: var(--global-bg-color);
}

.percentage-marker {
  background-color: var(--global-bg-color);
}

.tooltip-container {
  background-color: var(--bg-clr);
}

.tooltip-progressbar-wrapper .progress-bar {
  background-color: var(--tooltip-progress-bar);
}

.custom-modal-width .modal-content {
  background-color: var(--card-bg-color);
}

.patientList .rowPatient:hover {
  background-color: var(--patient-search-row-hover-bg);
}

.prescriptionList .rowPrescription:hover {
  background-color: var(--patient-search-row-hover-bg);
}

.detailDigits .digit {
  color: var(--main-heading) !important;
}

.campaign_list_container {
  background-color: var(--outer-item) !important;
}

.campaign_list_container .innerServices .campaign_list_serviceCard .myCard {
  background-color: var(--bg-clr) !important;
}

.campaign_list_container .card_service_main_wrapper {
  background-color: var(--bg-clr) !important;
}

.cluster-marker {
  font-family: "SF-Pro Regular", sans-serif !important;
  background: #000000;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  padding: 20px;

  transition: 0.2s linear;
}

.pharmacy-patient-wrapper {
  height: 96vh;
}

.patientview_container-content-area {
  height: 90vh;
}

.service_item_sidebar {
  background-color: var(--serviceList-sidebar);
}

.service_item_sidebar:hover {
  background-color: var(--serviceList-sidebar-hover);
}

.service_item_sidebar:hover .addPatientIcon {
  color: #000;
}

.service_item_sidebar.active .addPatientIcon {
  color: #000;
}

.services_sidebar_skeleton_container > div:not(:last-child) {
  border: 1px solid var(--container-border);
  background-color: var(--grey-111) !important;
  border-radius: 10px;
}

.services_sidebar_container > div:not(:last-child) {
  border: 1px solid var(--container-border);
  background-color: var(--grey-111) !important;
}

.services_sidebar_container > div:last-child {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #666;
  position: relative;
}

.services_sidebar_container > div:last-child button {
  display: block;
  margin: 0 auto;
}

.sidebar_skeleton_btn {
  display: flex;
  justify-content: center;
}

.services_tab {
  background: var(--grey-222) !important;
}

.service_item_sidebar:hover .service_item_title {
  color: #000;
}

.services_content-bg {
  background-color: var(--services-content-bg) !important;
}

.service_content-bg {
  background-color: var(--services-content-bg) !important;
}

.service_content-bg .modal-content-container {
  background: var(--grey-111) !important;
}

.service_content-bg .filters_fields {
  background: var(--footer-color) !important;
}

.service_content-bg
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: var(--grey-222) !important;
}

.service_content-bg .MuiOutlinedInput-input {
  background: var(--grey-222) !important;
}

.service_item {
  background-color: var(--grey-222) !important;
}

.services_listview .data-box-container {
  background: var(--grey-222) !important;
}

.services_listview svg {
  fill: var(--main-heading);
}

/* .services_listview .service_item {
  background: var(--grey-222) !important;
} */

.newClinicsBtn {
  background: var(--new-clinic-button) !important;
}

.service_item_sidebar.active {
  background-color: var(--serviceitem-active);
}

.service_item_sidebar.active .service_item_title {
  color: #000;
}

.service_analytics {
  background: var(--grey-111) !important;
}

.ag-cell,
.ag-cell-label-container {
  font-family: "SF-Pro Regular", sans-serif !important;
}

.ag-ltr .ag-header-select-all {
  margin-right: 5px !important;
}

.Toastify__toast-body {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.Toastify__toast-container--top-center {
  top: 3em;
  width: 400px;
}

.switch-content-reports-outer span.MuiSwitch-track {
  background: var(--toggleSwitch-bg);
}

.question_ok {
  background-color: #222222 !important;
  color: var(--btn-text-clr) !important;
  min-width: 100px;
  margin: 3px;
  border-radius: 10px;
  text-align: center;
  padding: 12px 16px;
  border: 1px solid #ccc;
}

.question_ok:hover {
  background-color: #00a3da !important;
  color: var(--btn-text-clr) !important;
}

.question_cancel {
  background-color: #222222 !important;
  color: var(--btn-text-clr) !important;
  min-width: 100px;
  margin: 3px;
  border-radius: 10px;
  text-align: center;
  padding: 12px 16px;
  border: 1px solid #ccc;
}

.question_cancel:hover {
  background-color: #d74e4e !important;

  color: var(--btn-text-clr) !important;
}

.switch-content-reports-outer
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: rgb(24, 144, 255);
}

.settings_content_area,
.servicetypes_content_wrapper {
  background-color: var(--settings-card-bg);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
}

.email_sms_wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.allEmails_wrapper {
  background-color: var(--settings-card-bg);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px !important;
  padding: 20px !important;
  margin-top: 10px !important;
}

.icons-color-sidebar > svg {
  color: var(--sidebar-icons-color);
}

.accordion-group .MuiAccordion-rounded {
  background-color: var(--drug-card);
}

.settings_content_area .MuiTypography-root,
.settings_content_area .MuiButtonBase-root,
.settings_content_area .MuiFormLabel-root,
.settings_content_area .MuiInputBase-root {
  font-family: "SF-Pro Regular", sans-serif !important;
}

.settings_heading h1 {
  color: var(--h2-color);
}

.settings_content_area h3 {
  color: var(--h2-color);
}

.addNew_cta {
  color: var(--h2-color);
  border-bottom: 1px solid var(--h2-color);
}

.locations_list_area .pharmacies-box-container {
  background-color: var(--grey-222);
}

.locations_list_area .room_name {
  color: var(--h2-color);
}

.settings_content_area .MuiFormLabel-root.Mui-focused {
  color: var(--black-666) !important;
}

.settings_content_area
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.settings_content_area .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--black-666) !important;
}

.settings_content_area .MuiButton-root {
  color: var(--black-444) !important;
  font-size: 20px;
  text-transform: capitalize;
  text-decoration: underline;
}

.settings_content_area .MuiSvgIcon-root {
  color: var(--black-666) !important;
}

.settings_content_area .MuiTypography-root {
  color: var(--h2-color);
}

.allView_container {
  background-color: var(--inner-card-bg-color);
}

.allEmails_wrapper .allView_container:hover {
  background-color: var(--email-templates-bg-hover);
  transition: 0.1s linear;
}

.settings_content_area .MuiFormLabel-root {
  /* background: var(--addMemberbox_bg); */
  background: transparent !important;
}

.AddNew_sectionbox {
  background: var(--settings-card-bg) !important;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  padding: 10px;
}

.upload__image-wrapper {
  background-color: var(--uploadImage_wrapper);
}

.settings_content_area .MuiAccordion-root {
  background-color: var(--card-bg-color);
}

.service-color {
  border: 2px solid var(--h2-color);
}

.settings_sidebar > .ps-sidebar-container {
  background-color: transparent;
}

.settings_sidebar > .ps-sidebar-container a {
  color: var(--light-999-dark-fff) !important;
}

.settings_sidebar .ps-submenu-content .ps-menuitem-root a,
.settings_sidebar > .ps-sidebar-container .no-submenu a {
  color: var(--h2-color) !important;
}

.settings-sidebar-selected,
.ps-menu-button:hover {
  background-color: var(--sidebar-hover-color) !important;
  border-radius: 10px;
}

.ps-submenu-content {
  background-color: transparent !important;
}

.settings_sidebar .submenu-title > .ps-menu-button:hover {
  background-color: transparent !important;
}

.sidebar-hamburger > svg {
  color: var(--main-heading);
}

.settings_page .MuiButtonBase-root {
  color: var(--h2-color);
}

.settings_page .MuiAccordion-root {
  background-color: var(--inner-card-bg-color) !important;
  border: 2px solid var(--label-border-color);
}

.orbit_box {
  background-color: var(--orbit-box-bg) !important;
  border: 2px solid var(--orbit-box-border);
}

input[type="range"] {
  accent-color: var(--h2-color);
}

.monthlyReport_boxes div,
.orbit_box .accordions_wrapper .accordion {
  background-color: var(--orbit-round-boxes);
}

.orbit_actionGroup_wrapper {
  background-color: var(--global-bg-color);
  border: 2px solid var(--orbit-box-border);
}

.orbit_successDialog .MuiDialog-paper {
  background-color: var(--grey-222);
}

.orbit_successDialog .content_wrapper {
  background-color: var(--orbit-round-boxes);
}

.orbit_successDialog .content_box {
  background-color: var(--grey-222);
}

.orbit_successDialog .audit_btn {
  background-color: var(--orbit-audit-btn);
}

.orbit_successDialog .withdraw_btn {
  background-color: #0a0a0a;
  color: #fff;
}

.orbit_successDialog .withdraw_btn p {
  color: var(--global-bg-color);
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
  background-color: var(--bg-clr) !important;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  background-color: var(--bg-clr) !important;
}

.fc-timegrid-axis {
  background-color: var(--bg-clr) !important;
}

.fc-col-header-cell {
  background-color: var(--bg-clr) !important;
}

.p-datepicker:not(.p-datepicker-inline) {
  background-color: var(--global-bg-color) !important;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background-color: var(--global-bg-color) !important;
  border: var(--bg-clr) !important;
}

.pharmacy_select_button {
  background-color: transparent !important;
  border: 0 !important;
  color: var(--h2-color) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  min-width: 200px;
  padding-right: 40px !important;
  border-bottom: 2px solid var(--h2-color) !important;
  border-radius: 0 !important;
  padding-bottom: 5px !important;
}

.pharmacy_select_button::after {
  position: absolute !important;
  right: 0px;
  top: 15px;
  border-top: 0.5em solid !important;
  border-right: 0.5em solid transparent !important;
  border-left: 0.5em solid transparent !important;
}

.pharmacy_select_menu.dropdown-menu.show {
  width: 100%;
}

a.pharmacy_select_item.dropdown-item {
  display: flex;
  gap: 5px;
}

.nms .tabs_box {
  padding: unset;
  height: 30px;
  margin-top: 5px;
}

span.services_kanban-date,
.patient-tag-container .custom_tag_subscription {
  color: black;
}

span.service_kanban-date,
.patient-tag-container .custom_tag_subscription {
  color: black;
}

.ChatAppContainer {
  position: absolute;
  top: calc(100% - 577px);
  height: 60%;
  width: auto;
  transition: 0.8s ease-in-out;
  border-radius: 20px;
  background-color: transparent;
  right: 0;
}

.ChatAppContainer.hidden {
  transition: 0.8s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}

.mapboxgl-ctrl-bottom-left {
  z-index: 0 !important;
}

.pharmacy-icon-counts {
  color: #222;
}

.sendbird-app__channellist-wrap {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  border: 1px solid #d6d6d6;
  box-shadow: 1px -1px 11px 7px rgb(0 0 0 / 12%);
}

.sendbird-channel-list {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  width: 498px !important;
}

.sendbird-app__conversation-wrap {
  border-top-right-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
  border: 1px solid #d6d6d6;
  box-shadow: 1px -1px 11px 7px rgb(0 0 0 / 12%);
}

.sendbird-chat-header {
  border-top-right-radius: 17px !important;
}

.sendbird-channel-list__header {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
}

.sendbird-channel-header {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
  width: 498px !important;
}

.sendbird-message-input-wrapper__message-input {
  margin: 0px !important;
  background: none !important;
}

.sendbird-conversation__footer {
  padding-bottom: 0px !important;
}

.sendbird-message-input-wrapper__message-input {
  padding: 5px 25px 35px 25px !important;
  border: none !important;
  box-shadow: none !important;
}

.sendbird-channel-settings {
  border-top-right-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
  margin-bottom: 16px !important;
  margin-right: 37px !important;
  height: 286px !important;
  border: 1px solid #d6d6d6 !important;
  box-shadow: 1px -1px 11px 7px rgba(199, 199, 199, 0.12);
}

@media (min-width: 1300px) {
  .sendbird-channel-settings {
    height: 466px !important;
  }
}

/* ================= New design of chat================================= */

.sendbird-channel-preview--active {
  background-color: #e0f1fd !important;
  border-left: none !important;
}

.sendbird-channel-preview {
  border-bottom: none !important;
  border-radius: 8px !important;
  margin-top: 2px !important;
  margin: 2px !important;
  height: 63px !important;
}

.sendbird-text-message-item-body.outgoing {
  background-color: #2f80ed !important;
}

.sendbird-text-message-item-body.outgoing {
  background-color: #2f80ed !important;
}

.sendbird-conversation__footer {
  height: 70px !important;
}

.sendbird-message-input .sendbird-message-input--textarea {
  overflow: hidden !important;
  border-radius: 20px !important;
}

.sendbird-label--color-oncontent-1 {
  color: #fff !important;
}

.sendbird-avatar .sendbird-avatar-img--default {
  background-color: #bdbdbd !important;
}

.sendbird-label--color-onbackground-2,
.sendbird-label--color-onbackground-3 {
  color: #a5a1a1 !important;
}

.sendbird-icon-color--content [class*="fill"] {
  fill: white !important;
}

.sendbird-message-input .sendbird-message-input--textarea:focus {
  border: 1px solid rgb(29, 29, 29) !important;
}

.sendbird-message-input .sendbird-message-input--textarea:focus {
  border: 1px solid rgb(83, 83, 83) !important;
}

.sendbird-channel-preview--active
  .sendbird-channel-preview__content__upper__header__channel-name {
  color: #1b1a57 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.sendbird-channel-preview--active
  .sendbird-channel-preview__content__upper__header__channel-name {
  color: #0f55b1 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.ChatAppContainer {
  border-radius: 15px;
  height: 50%;
  margin-top: 300px;
  z-index: 3;
}

@media (min-width: 1200px) {
  .ChatAppContainer {
    margin-top: 135px;
  }
}

.sendbird-icon-color--primary [class*="fill"] {
  fill: #2f80ed !important;
}

.sendbird-icon-color--primary [class*="fill"] {
  fill: #2f80ed !important;
}

.sendbird-label--color-primary {
  color: #2f80ed !important;
}

.sendbird-label--color-primary {
  color: #2f80ed !important;
}

.sendbird-channel-settings__members .sendbird-badge {
  background-color: #2f80ed !important;
}

.sendbird-channel-settings__members .sendbird-badge {
  background-color: #2f80ed !important;
}

.sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: #fff !important;
}

.sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: #fff !important;
}

.sendbird-admin-message {
  justify-content: flex-start !important;
}

.sendbird-message-input .sendbird-message-input--attach:hover {
  background-color: transparent !important;
}

.sendbird-label--color-onbackground-3 {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.sendbird-button--primary {
  background-color: #2f80ed !important;
  border: 1px solid #266cc9 !important;
}

.sendbird-modal__content {
  border-radius: 15px !important;
}

.sendbird-button--secondary {
  border: 1px solid #266cc9 !important;
}

.sendbird-channel-header {
  border: none !important;
}

.sendbird-channel-header
  .sendbird-channel-header__title
  .sendbird-channel-header__title__right__name {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.sendbird-channel-header .sendbird-channel-header__title :focus {
  border: 1px solid rgb(83, 83, 83) !important;
}

.sendbird-label--h-2 {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.sendbird-channel-preview--active {
  border-left: none !important;
  border-radius: 10px !important;
}

/* ====================WhatsApp view ================================ */

/* Channel list container */
.sendbird-app__channellist-wrap {
  width: 500px !important;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  border-radius: 15px !important;
}

/* Conversation container */
.sendbird-app__conversation-wrap {
  height: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px !important;
}

/* Back button */
.back-button {
  border: none;
  padding: 10px;

  position: absolute;
  z-index: 2;
  margin-top: 13px;
}

.back-button span {
  cursor: pointer;
}

/* Settings panel */
.sendbird-app__settingspanel-wrap {
  height: auto;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  margin-top: -100px !important;
}

.mobile-view__wrap {
  width: 500px;
  height: 450px;
  border-radius: 20px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  transform: translateX(-8%);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    border-radius: 10px;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

.sendbird-channel-list__body {
  border-radius: 10px !important;
}

.sendbird-channel-list__body:hover {
  background-color: #f5f5f5;
}

.sendbird-conversation {
  overflow: auto !important;
}

.sendbird-channel-preview {
  width: 99% !important;
}

.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__lower,
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper {
  width: unset !important;
}

.sendbird-badge {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  background-color: #266cc9 !important;
}

.general-inner-boxes .MuiPaper-root {
  background: var(--timeline-notes-content) !important;
}

.general-inner-boxes .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: var(--patient-dashboard-desc-color) !important;
}

.description_container textarea {
  background: var(--bg-clr) !important;
  color: var(--patient-dashboard-desc-color) !important;
}

@media screen and (max-width: 600px) {
  .pharmacy_listview {
    width: 320px !important;
  }

  .services_container-tabs {
    padding-top: 10px !important;
  }

  .nms .vertical-stepper {
    margin-left: 7px !important;
  }
}

@media screen and (max-width: 768px) {
  .teamName_container {
    left: 75px !important;
  }

  .services_container_wrapper .tabs_box {
    width: 100%;
  }

  .services_container_wrapper {
    margin-bottom: 0px;
  }

  .services_sidebar_container {
    margin-top: 35px;
  }

  .date-range-picker-container {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 600px) {
  .pharmacy_select_menu {
    z-index: 999999 !important;
  }

  .ag-paging-description {
    display: flex;
    gap: 10px;
  }

  .sendbird-channel-preview__avatar {
    width: 25px !important;
    height: 25px !important;
  }

  .sendbird-app__channellist-wrap {
    width: unset !important;
    height: 100% !important;
    overflow-y: auto !important;
    border-radius: 15px !important;
  }

  .sendbird-chat-header--avatar--group-channel {
    width: 30px !important;
    height: 30px !important;
    margin-left: 10px !important;
  }

  .sendbird-channel-preview {
    padding: 12px 16px 12px 5px;
  }

  .sendbird-channel-header {
    width: unset !important;
  }

  .sendbird-channel-preview
    .sendbird-channel-preview__content
    .sendbird-channel-preview__content__upper
    .sendbird-channel-preview__content__upper__header
    .sendbird-channel-preview__content__upper__header__channel-name {
    font-size: 10px !important;
    margin-left: 10px !important;
  }

  .sendbird-channel-preview
    .sendbird-channel-preview__content
    .sendbird-channel-preview__content__upper
    .sendbird-channel-preview__content__upper__header
    .sendbird-channel-preview__content__upper__header__total-members {
    font-size: 10px !important;
  }

  .sendbird-channel-preview
    .sendbird-channel-preview__content
    .sendbird-channel-preview__content__lower
    .sendbird-channel-preview__content__lower__last-message {
    font-size: 10px !important;
    margin-left: 10px !important;
  }

  .sendbird-channel-preview
    .sendbird-channel-preview__content
    .sendbird-channel-preview__content__upper
    .sendbird-channel-preview__content__upper__last-message-at {
    font-size: 10px !important;
  }

  .mobile-view__wrap {
    max-width: 300px !important;
  }

  .sendbird-channel-list {
    width: unset !important;
  }

  .team_container {
    padding-left: 10px !important;
  }

  .teamName_container {
    left: 60px !important;
  }

  .services_container_wrapper .tabs_box {
    width: 100%;
  }

  .services_container_wrapper {
    margin-bottom: 0px;
  }

  .services_sidebar_container {
    margin-top: 35px;
  }

  .services_kanban .service_item {
    width: 75% !important;
  }

  .services_kanban-serviceName {
    font-size: 15px !important;
  }

  .service_kanban .service_item {
    width: 75% !important;
  }

  .service_kanban-serviceName {
    font-size: 15px !important;
  }

  .service_analytics {
    min-width: unset !important;
  }
}

.call_intro.call_box,
.call_intro.call_box_alt > div {
  background-color: var(--inner-card-bg-color);
  border: 2px solid var(--label-border-color);
}

.call_intro.call_box .call_intro_focus {
  color: var(--h2-color);
}

.pharmacy_select_menu.dropdown-menu.show {
  background-color: var(--global-bg-color);
  width: max-content;
}

.caller_control {
  background-color: var(--calling-bg);
  border: 2px solid #00cc6a;
}

.nms_info_accordion {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
  box-shadow: none !important;
}

.search_transcript_input,
.search_transcript_input input {
  background-color: transparent;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.search_transcript_input input {
  border: 2px solid var(--search-transcript-border);
  color: var(--global-bg-color);
}

.search_transcript_input input:hover {
  border: 2px solid var(--search-transcript-border);
}

.search_transcript_input .p-inputtext:enabled:hover {
  border-color: var(--search-transcript-border);
}

.search_transcript_input .p-inputtext:enabled:focus {
  border-color: var(--search-transcript-border);
  box-shadow: unset;
}

.nms_info_accordion .list_box {
  background-color: var(--grey-222);
}

a.pharmacy_select_item.dropdown-item:active {
  background-color: #f2f2f2;
}

.nms_call_reminder {
  background-color: var(--grey-111) !important;
  border: 2px solid var(--label-border-color) !important;
}

a.pharmacy_select_item:hover,
a.pharmacy_select_item:active {
  background-color: var(--grey-222) !important;
}

.bottomBar_image-container {
  background: var(--bottomBar_image-bg);
  border: 3px solid var(--bottomBar_image-border);
}

.custom-tooltip p {
  color: #fff !important;
}

.orbit_session_contaier .popup {
  background-color: var(--bg-clr) !important;
}

.orbit_session_main_container {
  background-color: var(--outer-item) !important;
}

.orbit_info_container .orbit_info {
  background-color: var(--bg-clr) !important;
}

.orbit_end_time_container .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--h2-color) !important;
}

.MuiList-root-MuiMenu-list {
  background-color: var(--card-bg-color) !important;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  background-color: var(--card-bg-color) !important;
} */

.nms_email_template {
  background-color: var(--timeline-email-content);
  border: 2px solid var(--timeline-email-content-border);
}

.nms_signature_template {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.nms_note_cont {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.nms_notes_box {
  background-color: var(--timeline-inner-notes-content) !important;
  border: 2px solid var(--timeline-inner-notes-content-border) !important;
}

.nms_grouped_notes_box {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.calendar_flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar_flex .react-datepicker-wrapper {
  width: 100%;
}

.calendar_flex .calender_icon {
  position: absolute;
  top: 0;
  right: 5px;
}

.services_content-bg .modal-content-container {
  background: var(--grey-111) !important;
}

.services_content-bg .filters_fields {
  background: var(--footer-color) !important;
}

.services_content-bg
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: var(--grey-222) !important;
}

.services_content-bg .MuiOutlinedInput-input {
  background: var(--grey-222) !important;
}

.x_mark {
  color: var(--h2-color) !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: var(--h2-color) !important;
}

.AddNew_sectionbox_Accreditations {
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.uploadedLabel {
  color: green;
}

.notUploadedLabel {
  color: red;
}

.inline-not {
  background-color: var(--inner-card-bg-color);
  border: 2px solid var(--label-border-color);
}

.outer-item {
  background-color: var(--inner-card-bg-color) !important;
  border: 2px solid var(--label-border-color) !important;
}

.textfield,
.textfield .textarea {
  background-color: var(--grey-222) !important;
  border-radius: 10px;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.textfield textarea {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 14px;
}

.textfield {
  margin-top: 15px !important;
}

fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #ddd !important;
  border-width: 1px !important;
}

.patient-tab .MuiBox-root {
  border-bottom: 1px solid var(--global-bg-color);
}

.nms .patient-tab .MuiBox-root {
  border-bottom: none;
}

.completed_services_drawer .patient-tab .MuiBox-root {
  border-bottom: none;
}

.card-step-number h2 {
  color: #0a0a0a;
  position: relative;
  left: -1px;
  font-weight: 600;
}

.MuiInputBase-multiline {
  color: var(--h2-color) !important;
}

div#demo-simple-select-filled {
  font-family: "SF-Pro Regular", sans-serif !important;
}

.MuiOutlinedInput-input {
  background-color: var(--bg-clr) !important;
  color: var(--h2-color) !important;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  color: var(--h2-color) !important;
  font-family: "SF-Pro Regular";
}

p.view-contents {
  font-size: 10px;
  color: #999;
  text-transform: none !important;
}

.activity_feed_stepper .MuiTimelineItem-root:first-child {
  height: 40px !important;
  min-height: 40px;
}

.ps-timeline-wrapper .activity_feed_stepper .MuiTimelineItem-root:first-child {
  height: unset !important;
  min-height: unset;
}

.list_box .textfield {
  width: 100%;
  font-family: "SF-Pro Regular", sans-serif !important;
  border: 1px solid #1c3b2c;
  margin: 0 !important;
  margin-top: 6px !important;
  background-color: var(--grey-111) !important;
}

.list_box .textfield fieldset {
  display: none;
}

.list_box.list_box_alt {
  display: block;
}

.list_box.list_box_alt .d-flex {
  gap: 6px;
  margin-top: 3px;
}

.list_box.list_box_alt .d-flex p {
  color: #00ca69;
}

.list_box textarea {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 11px;
}

.list_box .textfield > div {
  padding: 10px;
}

.list_box.transcript,
.list_box.transcript .MuiOutlinedInput-input {
  background-color: unset !important;
  border: unset !important;
}

.list_box.transcript .textfield > div {
  background-color: var(--grey-111) !important;
  border: 2px solid var(--label-border-color) !important;
}

.list_box.transcript .textfield {
  border: unset !important;
}

.patient-tab .MuiTabs-flexContainer {
  border-bottom: 1px solid var(--label-border-color);
}

.info-box > span > label,
.inline-info > span > label {
  color: var(--info-box-label);
}

.travel-info-box > span > label,
.inline-info > span > label {
  color: var(--info-box-label);
}

.allergies-box {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.services_container-tabs {
  background: transparent !important;
}

.MuiTab-root .tabs_text {
  color: #999 !important;
}

.patient-tab .MuiTab-root .tabs_text_alt,
.orbit-agency-tab .MuiTab-root .tabs_text_alt {
  color: #999 !important;
  text-transform: capitalize;
}

.MuiTab-root.Mui-selected .tabs_text {
  color: var(--h2-color) !important;
}

.patient-tab .MuiTab-root.Mui-selected .tabs_text_alt,
.orbit-agency-tab .MuiTab-root.Mui-selected .tabs_text_alt {
  color: var(--h2-color) !important;
}

.MuiTab-root .icons-color {
  fill: #999 !important;
}

.MuiTab-root.Mui-selected .icons-color {
  fill: var(--h2-color) !important;
}

.data-box-container .MuiButtonBase-root.Mui-expanded {
  min-height: 20px !important;
}

.data-box-container .MuiAccordionSummary-content {
  margin-top: 10px !important;
}

.services_listview .stage .MuiAccordionSummary-content {
  margin: 0 !important;
}

.services_listview .stage .MuiAccordionSummary-root {
  min-height: 35px;
}

.service-title > p {
  margin: 0;
}

.service-title {
  position: relative;
  top: -3px;
}

span.card-title.icons-box {
  font-size: 12px;
}

.activity_feed_stepper .MuiTimelineConnector-root {
  width: 0;
  background-color: transparent;
  border-left: 2px dashed #666;
}

.services_content-bg .oval-loading {
  left: 50%;
  top: 50%;
}

.MuiPopover-paper {
  background: var(--grey-111) !important;
}

p.service_duedate_title {
  color: var(--h2-color) !important;
}

.settings_formFields p {
  margin: 0;
}

.settings_heading h5 {
  font-size: 18px;
}

.label_tag {
  font-size: 15px;
  margin-bottom: 10px;
}

.label_tag_data {
  font-size: 12px !important;
  color: #999;
}

.settings_content_wrapper {
  border-radius: 10px;
  padding-top: 20px;

  overflow-x: hidden !important;
  overflow: scroll;
}

.settings_content_wrapper .MuiFormControl-root {
  width: 100% !important;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.settings_content_wrapper .MuiInputBase-root {
  font-family: "SF-Pro Regular", sans-serif !important;
  background-color: transparent;
  border-radius: 10px;
  width: 100% !important;
}

.settings_content_wrapper fieldset.MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--label-border-color) !important;
}

.back_to_SettingsListView p {
  margin: 0;
}

.color_pick_section p {
  font-size: 12px;
  color: #999;
}

.settings_content_wrapper .services_list span,
.settings_content_wrapper .services_list span input {
  font-size: 12px !important;
}

.settings_content_wrapper .services_list span svg {
  width: 20px;
  height: 20px;
}

.settings_content_wrapper .services_list .MuiListItemIcon-root {
  min-width: 20px;
}

.checkAll_btnBox {
  width: fit-content;
  border-radius: 10px;
  background: var(--global-bg-color);
}

.checkAll_btnBox button {
  font-size: 12px !important;
  text-transform: capitalize;
  padding: 5px 10px;
}

.checkAll_btnBox button:hover {
  background-color: transparent;
}

.settings_content_wrapper .settings_box {
  margin-top: 10px;
  background-color: var(--inner-card-bg-color);
  border: 2px solid var(--label-border-color);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: relative;
}

.settings_box .d-flex {
  width: 100%;
  justify-content: space-between;
  align-items: start;
}

.multiple-checks > div:not(:first-child) {
  margin-top: 20px;
}

.multiple-checks .MuiButtonBase-root {
  width: fit-content;
}

.multiple-checks .services_list {
  width: fit-content;
}

.color_pick_section_type h5 {
  font-size: 15px;
}

.temp_head h4 {
  font-size: 16px;
}

.temp_body .text_input_field .MuiOutlinedInput-input {
  height: unset !important;
}

.temp_content p.sec_line {
  font-size: 12px;
  color: #999;
}

.settings_page .MuiOutlinedInput-input,
.selected-color-p {
  color: var(--h2-color);
}

button.filter_action {
  background-color: #222222 !important;
  color: var(--btn-text-clr) !important;
  min-width: 90px;
  margin: 3px;
  border-radius: 10px;
  text-align: center;
  padding: 6px 6px;
  border: 1px solid #ccc;
}

.note_text {
  font-size: 12px !important;
  color: #999 !important;
  padding-top: 4px;
}

.service_contact_no {
  background-color: var(--patient-tag);
}

.service_contact_no p {
  color: var(--h2-color);
}

input.p-inputtext.p-component {
  background-color: var(--grey-222);
  border: 3px solid var(--label-border-color);
  color: var(--h2-color) !important;
  font-family: "SF-Pro Regular", sans-serif !important;
}

.select__control {
  border-radius: 10px !important;
}

.select__control,
.select__menu {
  background-color: var(--inner-card-bg-color) !important;
  border: 2px solid var(--label-border-color) !important;
}

.select__menu {
  z-index: 5 !important;
}

.select__menu > div {
  color: var(--h2-color) !important;
}

.select__placeholder,
.select__single-value {
  color: var(--h2-color) !important;
}

.recharts-default-tooltip {
  background-color: var(--global-bg-color) !important;
}

span.MuiSwitch-thumb {
  color: #fff;
}

.analytics_tab_list_footer {
  background-color: var(--grey-111);
}

.analytics_tab_list_footer button {
  background-color: var(--analytics-btn);
}

.ov_page_wrapper {
  background-color: var(--ov-bg);
}

.ov_sidebar .steps .step_wrapper,
.ov_page_wrapper .main_content {
  background-color: var(--ov-fff-222);
}

.ov_sidebar .steps .step_wrapper.active {
  border: 1px solid var(--h2-color);
}

.ov_inner_wrapper .MuiInputBase-root,
.ov_your_details_wrapper .upload__image-wrapper {
  background-color: var(--inner-card-bg-color);
  border-radius: 10px;
}

.call_icon {
  border-radius: 50%;
  padding: 0px;
  width: 25px;
  min-width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.call_icon.phone-fill {
  background-color: #d0021b;
}

.optionsDialog .MuiPaper-elevation {
  background-color: var(--calling-bg);
  border: 1px solid #00cc6a;
  border-radius: 29px;
  padding: 13px 16px;

  min-width: 500px;
}

.options_dialog_wrapper p {
  margin: 0;
  font-size: 12px;
}

.options_dialog_wrapper .option {
  background-color: var(--calling-option);
  padding: 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options_dialog_wrapper .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.options_dialog_wrapper .option .icon_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--h2-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.options_dialog_wrapper .option .play_btn {
  background-color: white;
}

.options_dialog_wrapper .option .call_btn {
  background-color: #00cc6a;
}

.options_dialog_wrapper .option .action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #00cc6a;
  border-radius: 100px;
  min-width: 50px;
  text-align: center;
  padding: 6px;

  cursor: pointer;
  transition: 0.2s linear;
}

.options_dialog_wrapper .option .action.skip_nms {
  background-color: #d0021b;
}

.options_dialog_wrapper .option .action p {
  font-size: 10px;
  transition: 0.2s linear;
}

p.option-title {
  margin-top: 10px;
  margin-bottom: 5px;
}

p.label-two {
  color: #999;
  font-size: 9px;
  margin-top: -2px;
}

.options_dialog_wrapper .option .action:hover {
  background: #00924b;
  transition: 0.2s linear;
}

.options_dialog_wrapper .option .action.skip_nms:hover {
  background: #900011;
  transition: 0.2s linear;
}

.options_dialog_wrapper .option .tag {
  background-color: var(--h2-color);
  border-radius: 30px;
  min-width: 80px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.options_dialog_wrapper .option .tag p {
  font-size: 10px !important;
  color: var(--global-bg-color);
}

.options-wrapper .option.rescheduling {
  cursor: pointer;
}

.options-wrapper .option.rescheduling.selected {
  border: 1px solid var(--h2-color);
}

.options_dialog_wrapper .skip {
  margin-top: 15px;
}

.callScreenDialog {
  background-color: var(--calling-bg) !important;
  border: 2px solid #00cc6a;
  border-radius: 29px !important;
  padding: 13px 16px;
  overflow: visible;
  max-width: 330px;
  width: 100%;
  min-height: 200px;
  max-height: 500px;
  height: fit-content;
  position: absolute;
  z-index: 1000;
  top: 25%;
  left: 40%;
}

.callScreenDialog .top-bar {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-bottom: 20px;
}

.collapse-icon {
  cursor: pointer;
}

.callScreenDialog .body {
  max-width: 300px;
  color: #fff;
  text-align: center;
}

.callScreenDialog .body .profile-picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.callScreenDialog .body .profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #00ca69;
  margin: 0 auto;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.callScreenDialog .body .profile-picture::before,
.callScreenDialog .body .profile-picture::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 202, 105, 0.3);
  /* Adjust the color and opacity */
  animation: ripple 4s ease-out infinite;
  /* Adjust the animation duration */
  pointer-events: none;
}

.callScreenDialog .body .profile-picture::after {
  animation-delay: 2s;
  /* Adjust the delay for the second ripple */
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.3;
    /* Adjust the starting opacity */
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
    /* Adjust the ending opacity */
  }
}

.callScreen_dialog_wrapper .oval-loading {
  width: 40%;
}

.callScreenDialog .body h4 {
  font-size: 16px;
  margin: 0;
  margin-top: 30px;
}

.callScreenDialog .body .phone-number {
  font-size: 12px;
  color: #999;
  font-weight: 200;
}

.callScreenDialog .body .status-box {
  background-color: #002513;
  padding: 5px 15px;
  border-radius: 30px;
  width: fit-content;
  margin: 0 auto;
}

.callScreenDialog .body .status-box p {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

.callScreenDialog .body .d-flex {
  align-items: center;
  margin: 15px auto;
  width: fit-content;
  gap: 20px;
}

.callScreenDialog .body .d-flex button {
  padding: 10px;
  border-radius: 30px;
  font-weight: 600;
}

.callScreenDialog .body .d-flex p {
  font-size: 12px;
  margin: 0;
  font-weight: 800;
}

.callScreenDialog .body .cancel {
  background-color: #999;
}

.callScreenDialog .body .end-call {
  background-color: #d0021b;
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog .body .end-call p {
  color: #fff;
}

.callScreenDialog .patient-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #00cc6a;
  overflow: hidden;
}

.callScreenDialog .patient-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.callScreen_dialog_wrapper .answered {
  max-height: 400px;
  overflow-y: scroll;
}

.callScreenDialog .ringing .d-flex p {
  color: #0a0a0a;
}

.callScreenDialog .answered .top-bar {
  justify-content: space-between;
  align-items: center;
}

.callScreenDialog .answered .top-bar .patient-img img {
  margin-top: -6px;
}

.callScreenDialog .answered .top-bar p {
  font-size: 12px;
  margin: 0;
}

.callScreenDialog .answered .top-bar .patient-info {
  display: block;
  text-align: center;
}

.callScreenDialog .answered .top-bar .patient-info .d-flex {
  align-items: center;
  gap: 5px;
}

.callScreenDialog .answered .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.callScreenDialog .answered .body .chat-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .answered .body .chat-box {
  text-align: left;
  padding: 10px 15px;
  background-color: var(--calling-option);
  border-radius: 10px;
}

.callScreenDialog .answered .body .chat-box span {
  color: var(--h2-color);
}

.callScreenDialog .answered .body .chat-box p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.callScreenDialog .answered .body .chat-box .emphasis {
  color: var(--h2-color);
}

.callScreenDialog .answered .body .calling-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.callScreenDialog .answered .body .calling-footer p {
  margin: 0;
  color: #0a0a0a;
}

.callScreenDialog .answered .body .calling-footer .timer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #002915;
  padding: 10px;
  border-radius: 30px;
  width: 80px;
}

.callScreenDialog .answered .body .calling-footer .timer-box p {
  color: #fff;
}

.callScreenDialog .answered .body .calling-footer .action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #111;
  border-radius: 100px;
  min-width: 70px;
  text-align: center;
  padding: 6px;

  cursor: pointer;
  transition: 0.2s linear;
}

.callScreenDialog .ended .body .option .action.nms-completed {
  background-color: #00cc6a !important;
}

.callScreenDialog .ended .body .option .action.nms-incomplete {
  background-color: #d0021b !important;
}

.callScreenDialog .ended .body .call-textarea {
  background-color: var(--calling-option);
  border: none;
  font-size: 12px;
  border-radius: 16px;
  padding: 15px;
  resize: none;
  width: 100%;
}

.callScreenDialog .rescheduling .body .call-textarea {
  background-color: var(--calling-option);
  border: none;
  font-size: 12px;
  border-radius: 16px;
  padding: 15px;
  resize: none;
  width: 100%;
}

.callScreenDialog .patient-declined .body .call-textarea {
  background-color: var(--calling-option);
  border: none;
  font-size: 12px;
  border-radius: 16px;
  padding: 15px;
  resize: none;
  width: 100%;
  color: var(--h2-color);
}

.callScreenDialog .unanswered .body .call-textarea {
  background-color: var(--calling-option);
  border: none;
  font-size: 12px;
  border-radius: 16px;
  padding: 15px;
  resize: none;
  width: 100%;
}

.callScreenDialog .go-back {
  cursor: pointer;
}

.callScreenDialog .answered .body .calling-footer .action p {
  font-size: 9px;
  margin: 0;
  color: #fff;
}

.call-widget {
  width: 40px;
  height: 40px;
}

.callScreenDialog .answered .body .calling-footer .end-call {
  border-radius: 30px;
  height: 40px;
  font-weight: 800;
  font-size: 12px;
  width: fit-content;
}

.callScreenDialog .ended .top-bar {
  justify-content: space-between;
  align-items: center;
}

.callScreenDialog .rescheduling .top-bar {
  justify-content: space-between;
  align-items: center;
}

.callScreenDialog .unanswered .top-bar {
  justify-content: space-between;
  align-items: center;
}

.callScreenDialog .patient-declined .top-bar {
  justify-content: space-between;
  align-items: center;
}

.callScreenDialog .ended .top-bar .patient-img img {
  margin-top: -6px;
}

.callScreenDialog .rescheduling .top-bar .patient-img img {
  margin-top: -6px;
}

.callScreenDialog .patient-declined .top-bar .patient-img img {
  margin-top: -6px;
}

.callScreenDialog .unanswered .top-bar .patient-img img {
  margin-top: -6px;
}

.callScreenDialog .ended .top-bar p {
  font-size: 12px;
  margin: 0;
}

.callScreenDialog .rescheduling .top-bar p {
  font-size: 12px;
  margin: 0;
}

.callScreenDialog .patient-declined .top-bar p {
  font-size: 12px;
  margin: 0;
}

.callScreenDialog .unanswered .top-bar p {
  font-size: 12px;
  margin: 0;
}

.callScreenDialog .ended .top-bar .patient-info {
  display: block;
  align-items: center;
}

.callScreenDialog .rescheduling .top-bar .patient-info {
  display: block;
  align-items: center;
}

.callScreenDialog .unanswered .top-bar .patient-info {
  display: block;
  align-items: center;
}

.callScreenDialog .patient-declined .top-bar .patient-info {
  display: block;
  align-items: center;
}

.callScreenDialog .unanswered .top-bar .patient-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.callScreenDialog .unanswered .body .desc .patientName {
  font-size: 16px;
  margin-top: 6px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--h2-color);
}

.callScreenDialog .unanswered .body .desc .message {
  font-weight: 300;
  font-size: 15px;
  line-height: 16px;
  margin-top: 10px;
  text-align: center;
  color: #999;
}

.callScreenDialog .ended .top-bar .patient-info .d-flex {
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.callScreenDialog .rescheduling .top-bar .patient-info .d-flex {
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.callScreenDialog .unanswered .top-bar .patient-info .d-flex {
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.callScreenDialog .patient-declined .top-bar .patient-info .d-flex {
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.callScreenDialog .ended .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.callScreenDialog .rescheduling .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.callScreenDialog .unanswered .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.callScreenDialog .patient-declined .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.callScreenDialog .ended .body .desc p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #999;
}

.callScreenDialog .rescheduling .body .desc p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #999;
}

.callScreenDialog .unanswered .body .desc p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #999;
}

.callScreenDialog .patient-declined .body .desc p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #999;
}

.callScreenDialog .ended .body .options-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .rescheduling .body .options-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .unanswered .body .options-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .patient-declined .body .options-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .ended .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.callScreenDialog .rescheduling .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.callScreenDialog .unanswered .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.callScreenDialog .patient-declined .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.callScreenDialog .ended .body .option .react-datepicker {
  display: flex;
}

.callScreenDialog .rescheduling .body .option .react-datepicker {
  display: flex;
}

.callScreenDialog .ended .body .option p {
  margin: 0;
  font-size: 12px;
  margin-right: 8px;
}

.callScreenDialog .rescheduling .body .option p {
  margin: 0;
  font-size: 12px;
  margin-right: 8px;
}

.callScreenDialog .unanswered .body .option p {
  margin: 0;
  font-size: 12px;
  margin-right: 8px;
}

.callScreenDialog .patient-declined .body .option p {
  margin: 0;
  font-size: 12px;
  margin-right: 8px;
}

.callScreenDialog .ended .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog .rescheduling .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog .unanswered .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog .patient-declined .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog .ended .body .option .action {
  background-color: var(--calling-bg);
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.callScreenDialog .rescheduling .body .option .action {
  background-color: var(--calling-bg);
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.callScreenDialog .unanswered .body .option .action {
  background-color: var(--calling-bg);
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.callScreenDialog .patient-declined .body .option .action {
  background-color: var(--calling-bg);
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.callScreenDialog .ended .body .option .action p {
  font-size: 9px;
}

.callScreenDialog .rescheduling .body .option .action p {
  font-size: 9px;
}

.callScreenDialog .unanswered .body .option .action p {
  font-size: 9px;
}

.callScreenDialog .patient-declined .body .option .action p {
  font-size: 9px;
}

.callScreenDialog .ended .body .additional-comments {
  text-align: left;
}

.callScreenDialog .rescheduling .body .additional-comments {
  text-align: left;
}

.callScreenDialog .unanswered .body .additional-comments {
  text-align: left;
}

.callScreenDialog .patient-declined .body .additional-comments {
  text-align: left;
}

.callScreenDialog .ended .body .additional-comments p {
  margin: 0;
  color: #b9b9b9;
  font-size: 12px;
}

.callScreenDialog .rescheduling .body .additional-comments p {
  margin: 0;
  color: #b9b9b9;
  font-size: 12px;
}

.callScreenDialog .unanswered .body .additional-comments p {
  margin: 0;
  color: #b9b9b9;
  font-size: 12px;
}

.callScreenDialog .patient-declined .body .additional-comments p {
  margin: 0;
  color: #b9b9b9;
  font-size: 12px;
}

.callScreenDialog .ended .body .additional-comments input {
  width: 100%;
  font-size: 12px;
  background-color: var(--calling-option);
  color: var(--h2-color);
  border: none;
  border-radius: 10px;
  padding: 14px;
}

.callScreenDialog .rescheduling .body .additional-comments input {
  width: 100%;
  font-size: 12px;
  background-color: var(--calling-option);
  color: var(--h2-color);
  border: none;
  border-radius: 10px;
  padding: 14px;
}

.callScreenDialog .unanswered .body .additional-comments input {
  width: 100%;
  font-size: 12px;
  background-color: var(--calling-option);
  color: var(--h2-color);
  border: none;
  border-radius: 10px;
  padding: 14px;
}

.callScreenDialog .patient-declined .body .additional-comments input {
  width: 100%;
  font-size: 12px;
  background-color: var(--calling-option);
  color: var(--h2-color);
  border: none;
  border-radius: 10px;
  padding: 14px;
}

.callScreenDialog .ended .body .additional-comments input:focus-visible {
  outline: #000;
}

.callScreenDialog .rescheduling .body .additional-comments input:focus-visible {
  outline: #000;
}

.callScreenDialog .unanswered .body .additional-comments input:focus-visible {
  outline: #000;
}

.callScreenDialog
  .patient-declined
  .body
  .additional-comments
  input:focus-visible {
  outline: #000;
}

.callScreenDialog .body .calling-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.callScreenDialog .body .calling-footer p {
  font-weight: 600;
}

.callScreenDialog .ended .body .calling-footer p {
  margin: 0;
  color: #0a0a0a;
}

.callScreenDialog .rescheduling .body .calling-footer p {
  margin: 0;
  color: #0a0a0a;
}

.callScreenDialog .unanswered .body .calling-footer p {
  margin: 0;
  color: #0a0a0a;
}

.callScreenDialog .patient-declined .body .calling-footer p {
  margin: 0;
  color: #0a0a0a;
}

.callScreenDialog .body .calling-footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 12px;
  font-size: 12px;
  border-radius: 100px;
  min-width: 80px;
}

.callScreenDialog .body .calling-footer .call-back {
  background-color: #00cc6a;
}

.calling-footer button:disabled {
  opacity: 0.5;
}

.callScreenDialog .body .calling-footer .done {
  background-color: #999;
}

.cancelled-text h4 {
  font-size: 16px;
  margin-top: 10px !important;
}

.cancelled-text p {
  font-size: 12px;
  margin-top: 10px;
  color: #999;
}

.inpersonDialog {
  background-color: var(--calling-bg) !important;
  border: 2px solid #00cc6a;
  border-radius: 29px !important;
  padding: 13px 16px;
  overflow: visible;
  max-width: 330px;
  width: 100%;
  min-height: 120px;
  height: fit-content;
  position: absolute;
  z-index: 1000;
  top: 30%;
  left: 40%;
}

.inpersonDialog .top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 20px;
}

.inpersonDialog img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #00cc6a;
  overflow: hidden;
}

.inpersonDialog img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.inpersonDialog .top-bar {
  justify-content: space-between;
  align-items: center;
}

.inpersonDialog .top-bar .patient-img img {
  margin-top: -6px;
}

.inpersonDialog .top-bar p {
  font-size: 12px;
  margin: 0;
}

.inpersonDialog .top-bar .patient-info {
  display: block;
  text-align: center;
}

.inpersonDialog .top-bar .patient-info .d-flex {
  align-items: center;
  gap: 5px;
}

.inpersonDialog .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.inpersonDialog .body h4 {
  font-size: 28px;
  text-align: center;
}

.inpersonDialog .body .calling-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inpersonDialog .body .calling-footer p {
  font-weight: 600;
}

.inpersonDialog .ended .body .calling-footer p {
  margin: 0;
}

.inpersonDialog .body .calling-footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 12px;
  font-size: 12px;
  border-radius: 100px;
  min-width: 80px;
}

.inpersonDialog .body .calling-footer button p {
  margin: 0;
}

.inpersonDialog .body .calling-footer .cancel {
  background-color: #999;
}

.inpersonDialog .body .calling-footer .stop {
  background-color: #d0021b;
}

.inpersonDialog .body .calling-footer .stop p {
  color: #fff;
}

.inpersonDialog .ended .top-bar {
  justify-content: center;
  align-items: center;
}

.inpersonDialog .ended .top-bar .patient-img img {
  margin-top: -6px;
}

.inpersonDialog .ended .top-bar p {
  font-size: 12px;
  margin: 0;
}

.inpersonDialog .ended .top-bar .patient-info {
  display: block;
  align-items: center;
}

.inpersonDialog .ended .top-bar .patient-info .d-flex {
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.inpersonDialog .ended .top-bar .patient-info .phone-number {
  font-size: 9px;
  color: #999;
  margin-top: -3px;
}

.inpersonDialog .ended .body .desc p {
  text-align: center;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #999;
}

.inpersonDialog .ended .body .options-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inpersonDialog .ended .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.inpersonDialog .ended .body .option p {
  margin: 0;
  font-size: 12px;
}

.inpersonDialog .ended .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inpersonDialog .ended .body .option .action {
  background-color: var(--calling-bg);
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.inpersonDialog .ended .body .option .action p {
  font-size: 9px;
}

.inpersonDialog .ended .body .additional-comments {
  text-align: left;
}

.inpersonDialog .ended .body .additional-comments p {
  margin: 0;
  color: #b9b9b9;
  font-size: 12px;
}

.inpersonDialog .ended .body .additional-comments input {
  width: 100%;
  font-size: 12px;
  background-color: var(--calling-option);
  border: none;
  border-radius: 10px;
  padding: 14px;
}

.inpersonDialog .ended .body .additional-comments input:focus-visible {
  outline: #000;
}

.inpersonDialog .ended .continue-session {
  background-color: #00cc6a;
}

.inpersonDialog .ended .done {
  background-color: #999;
}

.wavesurfer-wrapper {
  display: block;
  padding: 10px;
  border-radius: 12px;
  background-color: var(--call-recorder-bg);
}

.wavesurfer-wrapper p {
  margin: 0;
  font-size: 12px;
}

.wavesurfer-wrapper .header {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: baseline;
  margin-bottom: -10px;
}

.stop-label {
  background-color: #d0021b;
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.stop-label p {
  color: #fff;
  text-transform: lowercase;
  margin: 0;
  font-weight: 800;
}

.callScreenDialog .date-picker,
.inpersonDialog .date-picker {
  position: absolute;
  left: 95%;
  top: 40%;
}

.callScreenDialog .date-picker .react-datepicker,
.inpersonDialog .react-datepicker {
  display: flex;
}

.inpersonDialog .option .action:hover {
  border: 1px solid #00ca69;
  transition: 0.2s linear;
  cursor: pointer;
}

.inpersonDialog .option .action:hover p {
  color: #00ca69;
  transition: 0.2s linear;
}

.inpersonDialog .option .action:hover svg {
  fill: #00ca69;
  transition: 0.2s linear;
}

input.widgetDatePicker {
  text-align: right;
  background: none;
  border: none;
  font-family: "SF-Pro Regular";
  font-size: 12px;
  font-weight: 100;
  margin: 0;
  cursor: pointer;
  background: var(--calling-bg);
  border-radius: 30px;
  padding: 5px;
  color: var(--h2-color);
}

input.widgetDatePicker:focus-visible {
  border: none;
  outline: none;
}

.widgetDatePicker .react-datepicker__input-container {
  display: flex;
}

.inviteMembers_wrapper {
  position: relative;
}

.inviteMembers_mapbox {
  position: absolute;
  z-index: -1;
}

.sms_sent_text p {
  font-size: 12px;
  font-weight: 400;
}

.checkbox_name > div {
  margin-left: 10px;
}

.calender_div .fc-prev-button.fc-button.fc-button-primary span,
.calender_div .fc-next-button.fc-button.fc-button-primary span,
.calender_div .fc-today-button.fc-button.fc-button-primary {
  color: var(--h2-color) !important;
}

.scheduleDropdown .p-dropdown-label {
  color: var(--h2-color) !important;
}

.icons-container > svg {
  fill: var(--h2-color) !important;
}

.data-box-container .MuiAccordionSummary-content > div {
  width: 100%;
}

.service_item_skeleton {
  margin-bottom: 15px;
}

.service_filter_skeleton {
  width: 300px;
  margin-top: 15px;
  margin-left: 20px;
}

.services_listview .stage .MuiAccordionSummary-content > div {
  width: 100%;
  display: flex;
}

.services_listview .service_title {
  width: 100%;
}

.services_listview .MuiButtonBase-root {
  padding: 0 !important;
}

.NMS-modal .modal-content {
  background-color: var(--grey-222) !important;
}

.activity_feed_stepper .MuiAccordionSummary-root.view-content-heading {
  min-height: 25px !important;
  margin-bottom: -10px;
}

.activity_feed_stepper .MuiAccordionSummary-root > div,
.activity_feed_stepper .MuiAccordionSummary-root > div > p {
  margin: 0;
}

.activity_feed_stepper .MuiAccordionSummary-root.heading {
  min-height: 35px !important;
}

.loading-services-accordion {
  max-height: 300px;
  height: 200px;
  border-radius: 20px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading-kanban {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.loading-kanban-columns {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  padding: 25px 20px;
}

.loading-kanban-columns > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-disable {
  background-color: #999595 !important;
}

.MuiSelect-filled {
  color: var(--h2-color) !important;
}

.bottom-bar {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  right: 0;
  width: 100%;
  text-align: right;
  background: var(--bottom-bar-bg);
  padding-top: 2px;
  height: 45px !important;
  overflow: hidden;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
    /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
    /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
}

.bottom-bar button {
  font-size: 12px;
  padding: 0 !important;
  height: 35px;
}

.services_listview .MuiAccordion-root {
  padding: 0;
}

.sorting-popover {
  font-size: 12px;
}

.sorting-popover > div {
  border-radius: 16px;
}

.sorting-popover > div > p {
  margin: 0;
  padding: 5px 10px;
  line-height: 2em;
  cursor: pointer;
  background-color: var(--serviceList-sidebar);
}

.sorting-popover > div > p:hover {
  background-color: var(--grey-111);
  font-weight: 600;
}

.light-text {
  color: #000000 !important;
}

.dark-text {
  color: #fff !important;
}

.loading-component {
  margin-left: -40px !important;
}

.loading-component.nms .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px 10px;
}

.startNMS-loading {
  margin-left: 100px;
}

.loading-component-first {
  padding: 30px 35px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loading-component-inner {
  gap: 50px;
}

.loading-card-one {
  padding: 30px 35px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.loading-card-one .flex {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.patient-searchModal .inputDiv {
  align-items: center;
  padding: 10px;
}

.color-tag {
  padding: 0 8px;
  border-radius: 30px;
  text-transform: capitalize;
}

.color-tag > span > span {
  font-size: 10px !important;
}

span.services_listview-date {
  background-color: var(--kanban-date-bg);
}

.service_item .text {
  margin: 10px 0;
}

.service_item .attempts {
  font-size: 12px;
  font-weight: 400;
}

.service_item .medicines {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 10px 0;
}

.service_item .medicines .med-box {
  background-color: var(--med-box-bg);
  border-radius: 5px;
  padding: 8px;
}

.service_item .medicines .med-box p {
  font-size: 10px;
  color: #aaa;
  white-space: initial;
  text-wrap: wrap;
}

.service_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.listview-accordion-right .service_contact_no {
  margin-bottom: 0 !important;
}

.services_iconboxService svg {
  fill: none !important;
}

.medicine-card .MuiAccordionSummary-root.Mui-expanded,
.general-inner-boxes .MuiAccordionSummary-root.Mui-expanded {
  margin-bottom: -25px;
}

.notes_container {
  background-color: var(--inner-card-bg-color);
  border: 2px solid var(--label-border-color);
  border-radius: 10px;
  padding: 20px;
}

.mapped_notes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.mapped_notes .note {
  width: 100%;
  background-color: var(--nms-notes-bg);
  border: 1px solid var(--nms-notes-border);
  border-radius: 10px;
  padding: 10px;
}

.mapped_notes .note .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.mapped_notes .note .header p {
  margin: 0;
  font-size: 10px;
  color: #aaa;
}

.mapped_notes .note .header .end {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.write_note {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--global-bg-color);
  border: 1px solid --nms-notes-border;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.write_note textarea {
  width: 100%;
  border: none;
  background-color: transparent;
  resize: none;
  height: 25px;
  color: var(--h2-color);
}

.write_note input:focus-within {
  outline: none !important;
}

.post_btn {
  width: 25px;
  height: 25px;
  background-color: var(--h2-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nms-title.notes .medicine-card-heading {
  text-transform: unset;
}

.max-word-limit {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.date-time-select {
  transition: 0.2s linear;
}

.date-time-select:hover {
  transition: 0.2s linear;
  border: 1px solid var(--date-time-border);
}

.date-time-select input.widgetDatePicker {
  background: none;
  text-align: left;
  padding: 0;
}

.services_iconboxService
  .react-datepicker
  .react-datepicker__time-list-item:hover {
  width: 99%;
}

.services_iconboxService .react-datepicker span {
  overflow: unset;
}

.documents-upload-skeleton {
  position: absolute;
  left: 42%;
  top: 4%;
}

.toggle-only,
.toggle-only label {
  cursor: pointer;
}

.width-100 {
  width: 100%;
}

.skeleton-loading-boxes {
  gap: 0 !important;
  margin: 0;
}

.add-new-member-skeleton {
  display: flex;
  align-items: end;
  justify-content: end;
}

.members-loader .d-flex {
  gap: 15px;
}

.note-text p {
  word-break: break-all;
}

.top-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.top-block svg {
  width: 45%;
}

/* width */
.MuiPopover-paper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.MuiPopover-paper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.MuiPopover-paper::-webkit-scrollbar-thumb {
  background: #222;
  transition: 0.2s linear;
}

/* Handle on hover */
.MuiPopover-paper::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.2s linear;
}

.nms_percentage_completed_wrapper {
  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.nms_percentage_completed_wrapper .value {
  font-weight: 600;
}

.nms_percentage_completed_wrapper .text {
  font-weight: 200;
  font-size: 9px;
  text-align: center;
}

.service_header_percentage_progress {
  border: 4px solid var(--services-header-progress-border);
  border-radius: 50%;
}

.service_header_percentage_progress p {
  font-size: 10px;
}

.bottom-stacked {
  background-color: var(--grey-111);
  box-shadow: 0px 2px 1px -1px var(--kanban-stack-border),
    0px 1px 1px 0px var(--kanban-stack-border-3),
    0px 1px 3px 0px var(--kanban-stack-border-2);
  -webkit-box-shadow: 0px 2px 1px -1px var(--kanban-stack-border),
    0px 1px 1px 0px var(--kanban-stack-border-3),
    0px 1px 3px 0px var(--kanban-stack-border-2);
  -moz-box-shadow: 0px 2px 1px -1px var(--kanban-stack-border),
    0px 1px 1px 0px var(--kanban-stack-border-3),
    0px 1px 3px 0px var(--kanban-stack-border-2);
}

.complete-button {
  width: 200px !important;
}

/********USER SIGNUP***********/

.user_signup_wrapper {
  background: url("https://titanpmr.com/media/bxenrudz/pexels-nicole-avagliano-2706654.jpg")
    no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.user_signup_wrapper .logo {
  width: 200px;
}

.user_signup_wrapper p {
  margin: 0;
  color: #00000099;
  font-size: 16px;
  letter-spacing: -1px;
}

.user_signup_wrapper strong {
  color: var(--h2-color);
}

.user_signup_wrapper .mt-10 {
  margin-top: 10px;
}

.user_signup_wrapper .user_signup_container {
  background-color: var(--grey-222);
  border-radius: 16px;
  padding: 35px;
  width: 1000px;
  margin-top: 20px;
  box-shadow: 0px 0px 15px 5px rgba(69, 69, 69, 0.08);
  -webkit-box-shadow: 0px 0px 15px 5px rgba(69, 69, 69, 0.08);
  -moz-box-shadow: 0px 0px 15px 5px rgba(69, 69, 69, 0.08);
}

.user_signup_wrapper h4 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
}

.user_signup_wrapper .user_signup_container .signup_form .form_fields {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  align-items: center;
}

.user_signup_wrapper .user_signup_container .signup_form .form_fields label {
  font-weight: 600;
}

.user_signup_wrapper .user_signup_container .signup_form .form_fields input {
  margin-top: 3px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2c6f7;
  padding: 10px 15px;
  font-weight: 200;
}

.user_signup_wrapper .user_signup_container .signup_form .error {
  color: rgb(243, 27, 27);
  margin-top: 5px;
  font-size: 12px;
  position: absolute;
}

.user_signup_wrapper .user_signup_container .signup_form .form_fields .d-flex {
  display: flex;
  gap: 15px;
}

.user_signup_wrapper
  .user_signup_container
  .signup_form
  .form_fields
  .consent-check
  .p-checkbox-box.p-highlight {
  background-color: #a677c9 !important;
}

.user_signup_wrapper .user_signup_container .signup_form .form_fields .color {
  color: #a677c9;
  text-decoration: underline;
}

.user_signup_wrapper
  .user_signup_container
  .signup_form
  .form_fields
  .f-weight {
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  margin-top: 5px;
  color: #82868d;
}

.user_signup_wrapper .validation {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.user_signup_wrapper .validation:before {
  content: "*";
}

.user_signup_wrapper .validations {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user_signup_wrapper .validations .d-flex {
  gap: 10px;
  align-items: center;
}

.user_signup_wrapper .signup-footer {
  margin-top: 50px;
  display: block;
  background-color: unset;
}

.user_signup_wrapper .btn_success {
  background-color: #299fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  transition: 0.2s linear;
  cursor: pointer;
  padding: 20px 5px;
}

.user_signup_wrapper .btn_success:hover {
  transition: 0.2s linear;
  background-color: #1f7cc7;
}

.user_signup_wrapper .btn_success p {
  margin: 0;
  color: #fff;
}

.user_signup .tools_grid {
  display: flex;
  gap: 20px;
}

.user_signup .tools_grid .tool {
  border: 1px solid #eee;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.05085784313725494) 100%
  );
  padding: 16px;
  width: 50%;
}

.user_signup .tools_grid .tool .d-flex {
  gap: 10px;
  align-items: center;
}

.user_signup .tools_grid .tool .label {
  font-weight: 500;
  color: var(--h2-color);
  font-size: 16px;
  margin: 0;
}

.user_signup .tools_grid .tool p {
  font-size: 14px;
  margin-top: 8px;
}

.user_signup .tools_grid.alt .tool .d-flex {
  justify-content: space-between;
}

.user_signup .tools_grid.alt .tool .d-flex div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user_signup .tools_grid.alt .tool > div {
  display: flex;
}

.user_signup .back_btn {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.user_signup .back_btn p {
  color: var(--h2-color);
}

.user_signup .m-20 {
  margin: 20px 0;
}

.processing_app {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.05085784313725494) 100%
  );
  border: 1px solid #eee;
  border-radius: 16px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.processing_app .text_wrapper {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.user_signup_wrapper h3 {
  font-size: 22px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #67717e;
}

.select_role_wrapper {
  text-align: center;
}

.select_role_wrapper .logo {
  width: 500px;
  margin: 15px 0;
}

.success_tick {
  margin: 15px 0;
}

.select_role_wrapper .role_selection {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #9e40e3cc;
}

.select_role_wrapper .role_selection h4 {
  text-align: left;
}

.select_role_wrapper .role_selection .roles_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.roles_grid .role {
  border-radius: 16px;
  padding: 16px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.022846638655462215) 100%
  );
  border: 1px solid #eee;
  text-align: left;
  cursor: pointer;
  opacity: 0.6;

  transition: 0.2s linear;
}

.roles_grid .role.active {
  transition: 0.2s linear;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.10407913165266103) 100%
  );
  opacity: 1;
}

.roles_grid .role:hover {
  transition: 0.2s linear;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.10407913165266103) 100%
  );
  opacity: 1;
}

.select_role_wrapper .role_selection .roles_grid .role .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_role_wrapper .role_selection .roles_grid .role .header .d-flex {
  gap: 10px;
  align-items: center;
}

.select_role_wrapper .role_selection .roles_grid .role .header .d-flex p {
  font-size: 16px;
  color: var(--h2-color);
}

.select_role_wrapper .role_selection .roles_grid .role .content {
  margin-top: 15px;
  background-color: unset !important;
}

.orbit_invitation_wrapper .top {
  text-align: center;
}

.orbit_logos {
  margin: 0 auto;
}

.powered_by_colored {
  margin-top: 20px;
}

.orbit_invitation_wrapper .circle {
  width: 300px;
  height: 300px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

.orbit_invitation_wrapper .circle2 {
  width: 300px;
  height: 300px;
  border: 1px solid #d4aaf3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbit_invitation_wrapper .circle3 {
  width: 200px;
  height: 200px;
  border: 1px solid #d4aaf3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbit_invitation_wrapper .circle4 {
  position: absolute;
}

.orbit_invitation_wrapper .border {
  margin: 20px 0;
  border: 2px solid #9e40e3cc;
}

.orbit_invitation_wrapper .content-cont {
  margin-top: 10px;
  text-align: center;
  position: relative;
}

.orbit_invitation_wrapper .d-flex {
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 15px;
}

.orbit_invitation_wrapper .d-flex button,
.orbit_invitation_wrapper button {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
}

.orbit_invitation_wrapper .reject {
  border: 2px solid #be4ed9;
  color: #be4ed9;
}

.orbit_invitation_wrapper .success {
  margin: unset;
  justify-content: space-between;
  color: #fff;
  background: linear-gradient(90deg, #ea60cb 0%, #9e40e3 100%);
}

.orbit_invitation_wrapper .gphc_container {
  margin-top: 20px;
  width: 100%;
}

.orbit_invitation_wrapper .orbit_gphc_container .validations {
  margin-bottom: 20px;
}

.orbit_invitation_wrapper .gphc_container label {
  font-weight: 600;
  margin-bottom: 5px;
}

.orbit_invitation_wrapper .gphc_container input {
  width: 100%;
}

.orbit_invitation_wrapper .validations {
  margin: 0;
  gap: 0;
}

.orbit_invitation_wrapper .gphc_checking_container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.orbit_invitation_wrapper .gphc_checking_container .cont {
  width: 33%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(158, 64, 227, 0.022846638655462215) 100%
  );
  border: 2px solid #f3e9fc;
  padding: 16px;
  border-radius: 16px;
}

.orbit_invitation_wrapper .gphc_checking_container .cont .label {
  font-weight: 600;
  color: var(--h2-color);
  margin: 5px 0;
}

.orbit_invitation_wrapper .orbit_gphc_container .success {
  margin-top: 30px;
}

@media only screen and (max-width: 1400px) {
  .user_signup_wrapper {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .user_signup_wrapper {
    min-height: 100vh;
    height: 100%;
  }

  .user_signup_wrapper .user_signup_container {
    width: 100%;
    padding: 20px;
  }

  .user_signup_wrapper .user_signup_container .signup_form .form_fields {
    display: flex;
    flex-direction: column;
  }

  .user_signup_wrapper .user_signup_container .signup_form .form_fields div {
    width: 100%;
  }

  .user_signup_wrapper
    .user_signup_container
    .signup_form
    .form_fields
    .p-checkbox {
    width: 20px;
  }

  .tools_grid {
    display: flex;
    flex-direction: column;
  }

  .tools_grid .tool {
    width: 100% !important;
  }

  .select_role_wrapper {
    width: 100%;
  }

  .select_role_wrapper .logo {
    width: 80%;
  }

  .roles_grid {
    display: flex !important;
    flex-direction: column;
  }

  .roles_grid .role {
    width: 100%;
  }
}

.grouped_patient_container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 5px;
}

/* width */
.grouped_patient_container::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.grouped_patient_container::-webkit-scrollbar-track {
  border: 1px solid #999;
  border-radius: 16px;
  margin-top: 4px;
}

/* Handle */
.grouped_patient_container::-webkit-scrollbar-thumb {
  background: var(--light-999-dark-fff);
  transition: 0.2s linear;
  cursor: pointer;
}

.grouped_patient_wrapper p {
  margin: 0;
}

.grouped_patient_wrapper .grouped_patient {
  border: 2px solid var(--grouped-patients-border);
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.1s linear;
}

.grouped_patient_wrapper .grouped_patient.active {
  border-color: var(--grouped-patients-border-hover);
}

.grouped_patient_wrapper .grouped_patient.active .name {
  font-weight: 600;
}

.grouped_patient_wrapper .grouped_patient:hover {
  transition: 0.1s linear;
  border-color: var(--grouped-patients-border-hover);
}

.grouped_patient_wrapper .label,
.grouped_patient_wrapper .grouped_patient .label {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
}

.grouped_patient_wrapper .grouped_patient .name {
  font-size: 14px;
  white-space: nowrap;
}

.inviteModal .modal-content,
.addNewModal .modal-content,
.addNewPharmacySelection .modal-content {
  background-color: var(--map-modal-bg) !important;
}

.inviteModal .modal-content .p-dropdown,
.p-dropdown-panel {
  background-color: var(--grey-111) !important;
  border: 1px solid #d4d4d4 !important;
}

.p-dropdown-panel {
  color: var(--h2-color) !important;
}

.p-dropdown-panel .p-highlight {
  background-color: var(--map-modal-button-hover) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: var(--h2-color);
  opacity: 0.8;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--h2-color);
  opacity: 1;
  background: var(--map-modal-button-hover);
}

.invite-buttons button {
  border: 1px solid var(--global-bg-color) !important;
  transition: 0.2s linear;
}

.invite-buttons button:hover {
  background-color: #000 !important;
  transition: 0.2s linear;
}

.inviteModal .modal-content .p-dropdown-label {
  color: var(--map-dropdown-label) !important;
}

.user_signup .email-field {
  display: flex;
  flex-direction: column;
}

.email_verification_wrapper {
  display: grid;
  place-items: center;
  height: 100vh;
}

.email_verification_wrapper {
  .text {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: bold;
    border: 3px solid var(--container-border);
    padding: 2em;
    border-radius: 30px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    text-align: center;
  }
}

.rotating_cog {
  animation: cogSpinner 2.5s linear infinite;
}

@keyframes cogSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.email_verifying_container,
.reset_password_container {
  padding: 20px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.email_verifying_container .inner,
.reset_password_container .inner {
  background: linear-gradient(
    286.03deg,
    rgba(158, 64, 227, 0.1) -85.68%,
    rgba(158, 64, 227, 0) 74.54%
  );
  border: 2px solid #f7effd;
  border-radius: 16px;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  padding: 20px;
}

.email_verifying_container .inner div {
  width: 400px;
  margin: 0 auto;
}

.email_verifying_container .inner h2,
.reset_password_container .inner h2 {
  margin-top: 20px;
  letter-spacing: 0;
  font-weight: 600;
  font-size: 24px;
}

.reset_password_container .inner h2 {
  text-align: center;
}

.email_verifying_container .inner p {
  color: #706f70;
}

.email_verifying_container .inner strong {
  letter-spacing: 0.3px;
}

.email_verifying_container button,
.reset_password_container button {
  padding: 16px;
  border-radius: 12px;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  border: 2px solid #be4ed9;
  color: #be4ed9;
  margin: 20px auto;

  width: 100%;
}

p.loading-dots {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 110px;
  display: flex;
}

.reset-pw-icon {
  text-align: center;
}

.reset_password_container .inner {
  text-align: unset;
}

.reset_password_container .inner .form_fields {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.reset_password_container .inner .form_fields label {
  font-weight: 600;
}

.reset_password_container .inner .form_fields input {
  margin-top: 3px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2c6f7;
  padding: 10px 15px;
  font-weight: 200;
}

.reset_password_container .inner button {
  margin-top: 30px;
}

.user_signup_wrapper .success_container {
  text-align: center;
  width: 600px;
  margin: 0 auto;
}

.user_signup_wrapper .success_container .confetti {
  width: 50%;
  margin: 0 auto;
}

.user_signup_wrapper .success_container .btn_success {
  margin-top: 30px;
  height: 50px;
  color: #fff;
  background: linear-gradient(90deg, #ea60cb 0%, #9e40e3 100%);
  text-align: center;
}

.user_signup_wrapper .success_container .btn_success p {
  margin: 0 auto;
}

.addNewModal label {
  color: var(--h2-color);
}

.addNewModal .initials {
  font-size: 20px;
  font-weight: 600;
  color: var(--h2-color);
}

.btn-close {
  box-shadow: none !important;
}

.edit-pencil {
  fill: var(--h2-color);
}

.team_members_list {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.team_members_list::-webkit-scrollbar {
  width: 4px;
}

.team_members_list::-webkit-scrollbar-track {
  background: var(--invite-scroll-track) !important;
}

.team_members_list::-webkit-scrollbar-thumb {
  background: var(--invite-scroll-thumb);
  border-radius: 20px;
  transition: 0.2s linear;
}

.custom-dropdown-option.remove p {
  margin: 0;
  font-size: 13px;
}

.custom-dropdown-option.remove:before {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #aaa;
  position: absolute;
  left: 0;
  top: 0;
}

.follow_up_service {
  border-radius: 10px;
  padding: 10px;
  background-color: var(--orbit-round-boxes);
  margin-bottom: 10px;
}

.team_member_name p {
  margin: 0;
  font-size: 14px;
}

.loading-card-orbit {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.different_organization_page_wrapper {
  background-image: url(./../src/assets/images/galaxy.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.different_organization_wrapper {
  position: absolute;
  background-color: var(--grey-222);
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.different_organization_wrapper h5 {
  font-weight: 600;
  margin: 10px 0;
}

.different_organization_wrapper .btn_footer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;

  margin-top: 40px;
}

.different_organization_wrapper .btn_footer button {
  display: flex;
  border-radius: 12px;
  padding: 6px 16px;
  text-transform: uppercase;
  color: #fff;
}

.different_organization_wrapper .btn_footer button p {
  margin: 0;
  color: #fff;
  font-weight: 600;
}

.different_organization_wrapper .btn_footer .cancel {
  background-color: #e0d2dd;
}

.different_organization_wrapper .btn_footer .go {
  background: linear-gradient(180deg, #ff65dd 0%, #b752ff 100%);
  gap: 10px;
  align-items: center;
}

.pharmacy-container {
  border: 1px solid var(--pharmacy-selection-border);
}

.pharmacy-selected {
  border: 1px solid #000;
}

.orbit_active_services .MuiTab-root {
  text-transform: capitalize;
  color: #999 !important;
}

.orbit_active_services .MuiTab-root.Mui-selected {
  color: var(--main-heading) !important;
  background: var(--active-services-tab-label-bg);
  border-radius: 16px;
}

.phone_child {
  background-color: var(--grey-222) !important;
  border: 2px solid var(--label-border-color) !important;
}

.orbit_earning_potential {
  background: var(--orbit-background-box) no-repeat;
  background-size: cover;
}

.orbit_earning_potential p {
  color: var(--para-clr);
}

.move_to_intervention {
  width: fit-content;
}

.call-skip-options .top-bar {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
}

.startCall-NMS-Modal .top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.startCall-NMS-Modal .top-bar svg {
  cursor: pointer;
}

.startCall-NMS-Modal textarea {
  background-color: var(--calling-option);
  border: none;
  font-size: 12px;
  border-radius: 16px;
  padding: 15px;
  resize: none;
  width: 100%;
  margin-top: 10px;
}

.startCall-NMS-Modal .calling-footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 12px;
  font-size: 12px;
  border-radius: 100px;
  min-width: 80px;
  background-color: #999;
  margin: 10px auto;
}

.startCall-NMS-Modal .preferred {
  margin: 10px 0;
  color: #999;
  font-weight: 300;
}

.startCall-NMS-Modal .multiple-numbers {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 12px;
  max-height: 350px;
  overflow-y: auto;

  padding-right: 5px;
}

.startCall-NMS-Modal .multiple-numbers::-webkit-scrollbar {
  width: 4px;
}

.startCall-NMS-Modal .multiple-numbers::-webkit-scrollbar-track {
  background: var(--invite-scroll-track) !important;
}

.startCall-NMS-Modal .multiple-numbers::-webkit-scrollbar-thumb {
  background: var(--invite-scroll-thumb);
  border-radius: 20px;
  transition: 0.2s linear;
}

.multiple-patients .body .desc strong {
  color: var(--h2-color);
}

.multiple-patients .body .options-wrapper .tag {
  font-size: 9px !important;
  color: #0a0a0a;
  background-color: #00ca69;
  padding: 3px 8px;
  border-radius: 30px;
}

.multiple-numbers hr {
  margin: 5px 0;
  border-color: #686868;
}

.multiple-numbers hr {
  margin: 5px 0;
  border-color: #686868;
}

.callScreenDialog .answered .body .multiple-patients-nms-option {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callScreenDialog .answered .body .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--calling-option);
  padding: 6px;
  border-radius: 100px;
}

.callScreenDialog .answered .body .option p {
  margin: 0;
  font-size: 12px;
  margin-right: 8px;
}

.callScreenDialog .answered .body .option .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.callScreenDialog
  .answered
  .body
  .multiple-patients-nms-option
  .option
  .action {
  background-color: #00ca69;
  border-radius: 100px;
  padding: 4px;
  gap: 5px;
}

.callScreenDialog .answered .body .option .action p {
  font-size: 9px;
}

.orbit_active_services .wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 240px;
  overflow-y: scroll;
}

.active-service-box {
  background-color: var(--orbit-round-boxes);
  border-radius: 10px;
  padding: 12px 15px;
}

.grouped-patients-dialog .MuiPaper-root {
  padding: 15px !important;
  border-radius: 16px !important;
  background-color: var(--grey-222);
}

.grouped-patients-dialog .cross {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  cursor: pointer;
}

.grouped-patients-dialog .flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grouped-patients-dialog .header,
.grouped-patients-dialog .body {
  text-align: center;
  width: 100%;
}

.grouped-patients-dialog .header h4 {
  margin-top: 5px;
}

.grouped-patients-dialog .lower {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.grouped-patients-dialog .lower .button-group {
  width: 100%;
  text-align: center;
}

.grouped-patients-dialog .lower .button-group p {
  color: #999;
  font-size: 10px;
  line-height: 11px;
  font-weight: 500;
  max-width: 85%;
  margin: 6px auto;
}

.orbit_testMode_dialog .input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.orbit_testMode_dialog .input_wrapper input {
  border-radius: 12px;
  border: 2px solid var(--label-border-color);
  background-color: var(--orbit-round-boxes);
  padding: 14px 10px;
  font-family: "SF-Pro Regular";
  font-size: 14px;
  color: var(--h2-color);
}

.orbit_testMode_dialog .input_wrapper input:focus-visible {
  outline: none;
  box-shadow: none;
  border: none;
}

.orbit_testMode_dialog .input_wrapper input:focus {
  border: 2px solid var(--label-border-color);
}

.orbit_testMode_dialog .input_wrapper .input_field {
  width: 100%;
  position: relative;
  padding: 2px 0;
}

.orbit_testMode_dialog .input_wrapper .input_field input {
  width: 100%;
}

.orbit_testMode_dialog .title {
  padding: 0;
  margin-bottom: 10px;
}

.orbit_testMode_dialog .error {
  color: rgb(243, 27, 27);
  margin-top: 5px;
  font-size: 12px;
  display: block;
  margin: 0;
  text-align: left;
  margin-top: 3px;
}

.orbit_page_wrapper .bottomBar_countContainer {
  display: none;
}

.test-mode-active {
  border: 1px solid var(--h2-color);
}

.generate_btn {
  position: relative;
  min-height: 50px;
}

.generate_btn .oval-loading {
  width: 8%;
  position: absolute;
  fill: white;
  color: white;
}

.generate_btn .oval-loading svg {
  stroke: white;
}

.orbit_page_wrapper .bottomBar_countContainer {
  display: none;
}

.ag-center-cols-viewport::-webkit-scrollbar {
  height: 4px !important;
}

.patient_consented_text p {
  font-size: 12px;
  font-weight: 400;
}

.required_accordion {
  border: 1px solid red !important;
}

.skip-textarea {
  color: var(--h2-color);
}

.therapy_dispensed_date {
  display: flex;
  align-items: center;
  gap: 5px;
}

.therapy_name_date {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.billing-container {
  display: flex !important;
  flex-direction: column;
}

.billing-container h6 {
  margin: 0;
}

.billing-container .footer_btn {
  text-align: left;
}

.billing-fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.width-20 {
  width: 20%;
}

.billing-mt-30 {
  margin-top: 30px;
}

.invoice_wrapper {
  max-height: 350px;
  height: 100%;
  overflow-y: auto;
}

.invoice_wrapper::-webkit-scrollbar {
  width: 4px;
}

.invoice_wrapper::-webkit-scrollbar-track {
  background: var(--invite-scroll-track) !important;
}

.invoice_wrapper::-webkit-scrollbar-thumb {
  background: var(--invite-scroll-thumb);
  border-radius: 20px;
  transition: 0.2s linear;
}

.invoice_container {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebe3e5;
  padding: 10px 0;
}

.invoice_container:last-child {
  border-bottom: none;
}

.invoice_container div {
  flex: 1;
  text-align: left;
}

.invoice_container .d-flex {
  flex-direction: column;
}

.invoice_container .d-flex p {
  letter-spacing: -1px;
}

.invoice_container p {
  font-size: 14px;
  color: #000000;
}

.invoice-link p {
  cursor: pointer;
}

.invoice_container .invoice-link p {
  color: rgb(24, 144, 255) !important;
  font-size: 12px;
  text-decoration: underline;
  width: fit-content;
}

.invoice_header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.invoice_header p {
  color: #333333;
  font-size: 14px;
  font-weight: 300;
}

.date-col {
  flex: 1;
  min-width: 120px;
  max-width: 150px;
}

.invoice-col {
  flex: 1;
  min-width: 360px;
}

.status-col {
  flex: 1;
  min-width: 120px;
  max-width: 200px;
}

.amount-due-col {
  flex: 1;
  min-width: 120px;
  max-width: 200px;
}

.listview-grid.ag-theme-quartz {
  /* Changes the color of the grid text */
  --ag-foreground-color: #fff;
  /* Changes the color of the grid background */
  --ag-background-color: #fff !important;
  /* Changes the header color of the top row */
  --ag-header-background-color: #f2f2f2;
  /* Changes the hover color of the row*/
  --ag-row-hover-color: #f2f2f2;

  --ag-checkbox-background-color: rgba(51, 51, 51, 0.3);
  --ag-checkbox-checked-color: rgba(51, 51, 51, 1);
}

.listview-grid.ag-theme-quartz-dark {
  /* Changes the color of the grid text */
  --ag-foreground-color: #000;
  /* Changes the color of the grid background */
  --ag-background-color: #222 !important;
  /* Changes the header color of the top row */
  --ag-header-background-color: #333;
  /* Changes the hover color of the row*/
  --ag-row-hover-color: #333;

  --ag-checkbox-background-color: rgba(242, 242, 242, 0.3);
  --ag-checkbox-checked-color: rgba(242, 242, 242, 1);
}

.listview-grid.ag-theme-quartz .ag-row {
  border-bottom: 1px solid #f2f2f2;
  color: var(--ag-row-color);
}

.listview-grid.ag-theme-quartz .ag-header-cell-resize::after {
  background-color: var(--ag-row-color);
  opacity: 0.4;
}

.listview-grid.ag-theme-quartz-dark .ag-row {
  border-bottom: 1px solid #333;
  color: var(--ag-row-color);
}

.listview-grid.ag-theme-quartz-dark .ag-header-cell-resize::after {
  background-color: var(--ag-row-color);
  opacity: 0.4;
}

.listview-grid.ag-theme-quartz .ag-row-odd {
  background-color: var(--grey-222);
}

.listview-grid.ag-theme-quartz .ag-row-selected,
.listview-grid.ag-theme-quartz .ag-row-selected::before {
  background-color: #f2f2f2 !important;
}

.listview-grid.ag-theme-quartz-dark .ag-row-selected,
.listview-grid.ag-theme-quartz-dark .ag-row-selected::before {
  background-color: #333;
}

.listview-grid .ag-root-wrapper {
  border: 1px solid var(--listview-grid-border) !important;
}

.listview-grid .ag-has-focus {
  border: none;
  box-shadow: none;
}

.listview-grid .ag-icon {
  font-family: "agGridAlpine" !important;
  color: var(--h2-color);
}

.listview-grid .ag-cell-focus,
.ag-cell {
  border: none !important;
}

.listview-patient-tag {
  background-color: aqua;
  color: #0a0a0a;
  padding: 2px 6px;
  border-radius: 30px;
  font-size: 10px;
}

.listview-due-tag {
  padding: 1px 6px;
  height: 30px;
  border-radius: 25px;
  background-color: var(--kanban-date-bg);
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  margin-top: 5px;
}

.listview-grid .ag-checkbox-input-wrapper:focus-within,
.listview-grid .ag-checkbox-input-wrapper:active {
  box-shadow: none;
}

.listview-grid .ag-row-level-0 .ag-cell:nth-child(2) {
  display: none;
}

.listview-skeleton-grid .ag-row-level-0 .ag-cell:nth-child(2) {
  display: unset;
}

.listview-grid {
  position: relative;
  overflow: hidden;
}

.listview-grid .patientName {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.listview-grid .ag-floating-filter {
  padding: 0;
  padding-left: 10px;
}

.listview-grid button.ag-button.ag-floating-filter-button-button {
  display: none;
}

.listview-grid .ag-floating-filter-input input {
  border-color: #6e6e6e !important;
  box-shadow: none !important;
}

.listview-grid .ag-floating-filter-input {
  border: unset;
  border-radius: unset;
  padding: unset;
  font-size: unset;
}

.listview-grid .ag-floating-filter-input input {
  color: var(--h2-color);
}

.listview-grid .ag-center-cols-container {
  padding-left: 10px;
}

.listview-grid .custom-group-row p {
  font-size: 16px;
  font-weight: 600;
}

.listview-grid .patient-tags {
  overflow: hidden;
  display: flex;
  margin-top: 10px;
  gap: 2px;
}

.listview-grid .custom-grid-label-wrapper p {
  font-size: 16px;
}

.listview-grid .custom-grid-label-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.listview-grid .custom-grid-label-wrapper span {
  margin-left: 0px;
}

.listview-grid .ag-row-selected::before {
  position: unset;
}

/* .listview-grid .ag-row-level-0 .d-flex .MuiButtonBase-root{
  display: none;
} */

.listview-grid .ag-row-level-0 .d-flex .label {
  margin-left: -10px;
  font-size: 16px;
  font-weight: 600;
}

.listview-grid .ag-row-level-0 .d-flex .age {
  display: none;
}

.listview-grid .ag-group-child-count {
  display: none !important;
}

.listview-grid .ag-row-level-0 .ag-group-child-count {
  display: block !important;
}

.listview-grid .ag-group-value,
.listview-grid .patient-renderer {
  width: 100%;
}

.listview-grid .patient-renderer .d-flex {
  margin-left: 10px;
}

.listview-grid .patient-renderer .label svg {
  margin-left: 10px;
}

.pharmacy-info-container .card-inner-value {
  margin-top: -6px !important;
}

.pharmacy-info-container .map-container {
  background-color: var(--timeline-notes-content);
  border: 2px solid var(--timeline-notes-content-border);
  overflow: hidden;
  border-radius: 11px;
  width: 100%;
  padding: 6px 15px;
  min-height: 300px;
}

.pharmacy-info-container .map-container .pharmacy-name {
  position: absolute;
  text-wrap: nowrap;
  left: 25px;
  top: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.med_tags {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.med_tags p {
  text-wrap: nowrap;
  width: 100%;
}

.med_tag {
  text-align: right;
}

.missing_bank_info {
  background: #e22424 !important;
}

.calling-warning-counter {
  background: #e22424;
  color: #fafafa;
  width: fit-content;
  padding: 2px 14px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ag-watermark {
  display: none !important;
}

/* width */
.ag-body::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.ag-body::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.ag-body::-webkit-scrollbar-thumb {
  background: #222 !important;
  transition: 0.2s linear !important;
}

/* Handle on hover */
.ag-body::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  transition: 0.2s linear !important;
}

.ag-body {
  margin-bottom: 30px;
}

.automation-button {
  background-color: var(--main-heading) !important;
}

.automation-button svg path {
  fill: var(--global-bg-color);
}

.service_title svg {
  margin-left: 5px;
}

.service_title svg path {
  fill: var(--main-heading);
}

.automate-btn {
  cursor: pointer;
  display: inline-block;
}

.menuBox {
  background-color: var(--global-bg-color);
}

.orbit-badge {
  display: flex;
  align-items: center;
  gap: 10px;
}

.orbit-badge.with-tag {
  align-items: flex-end;
}

.orbit-badge span {
  background-color: #d0021b;
  color: #fff;
  height: 20px;
  justify-content: center;
}

.orbit-badge.with-tag span {
  position: relative;
  top: 3px;
}

.orbit-badge p {
  font-weight: unset;
}

.orbit-badge.without-tag p {
  position: relative;
  top: 12px;
}

span.ag-icon {
  font-family: "agGridAlpine" !important;
}

.listview-grid .tagFilter {
  background-color: var(--grey-222);
  padding: 2px 2px 2px 8px;
  min-height: 33px;
  border: 1px solid #6e6e6e;
  border-radius: 5px;
  margin-top: 6px;
  margin-left: -5px;
  width: 100%;
}

.listview-grid .tagFilter:focus {
  outline: none;
}

.rescheduleDialog {
  border: 2px solid #6e6e6e;
}

.orbit_grid_filter {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.orbit_grid_filter label {
  display: flex;
  gap: 8px;
}

.tobeClaimed-grid button.ag-button.ag-floating-filter-button-button {
  display: none;
}

.services-container-footer {
  width: 100%;
  height: unset !important;
  padding: 6px 10px;
  background-color: var(--services-container-footer-bg);
  border-top: 1px solid #6e6e6e;

  display: flex;
  justify-content: space-between;
}

.services-container-footer .d-flex {
  display: flex;
  gap: 15px;
}

.services-container-footer .task-selected {
  border: 1px dashed #6e6e6e;
  color: var(--h2-color);
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px;
  border-radius: 5px;

  cursor: pointer;
  transition: 0.1s linear;
}

.services-container-footer .task-selected:hover {
  transition: 0.1s linear;
  background-color: var(--grey-111);
}

.service-toolbar-button {
  font-size: 12px;
  color: #6e6e6e;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  border-radius: 5px;
  transition: 0.1s linear;
  position: relative;
}

.service-toolbar-button:hover {
  background-color: #6e6e6e;
  color: var(--global-bg-color);
  transition: 0.1s linear;
}

.service-toolbar-button:hover svg.spinner-anim {
  stroke: var(--global-bg-color);
}

svg.spinner-anim {
  width: 20px;
}

.kanban-success-border {
  border: 1px solid #00ca69;
  transition: 5s linear;
}

.pds-check {
  color: #00ca69 !important;
}

.services-toast .Toastify__toast-body > * {
  color: var(--main-heading) !important;
  font-weight: 300;
}

.followup_modal_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.followup-modal .modal-body {
  margin: 0;
}

.followup-modal .card-content {
  padding: 20px 0 0 0;
}

.clinicalchecks .outer-item .card-inner-value {
  background: none;
  border: 1px solid var(--pres-border);
  border-radius: 11px;
  padding: 0px 10px;
}

.clinicalchecks .outer-item .custom-label:nth-child(2) {
  color: var(--h2-color);
}

.clinicalchecks .custom-select .MuiSelect-select {
  /* padding: 0 !important; */
  font-size: 14px;
  padding: 0px 5px 0px 5px !important;
}

.ccSkeleton {
  display: flex;
  align-items: start;
  width: 100%;
  padding: 40px;
  gap: 40px;
}

.ccSkeleton .left span {
  width: 30vw;
}

.ccSkeleton .right {
  width: 100%;
  border-left: 2px solid #00cc6a;
  padding-left: 60px;
}

.max-z {
  z-index: 99999999 !important;
}

.drawer_wrapper .edit-contact .edit-pencil {
  display: none;
}

.loading-patient-tab .header {
  display: flex;
  justify-content: space-between;
}

.loading-patient-tab .grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
}

.loading-patient-tab .single {
  width: 100%;
}

.loading-notes {
  margin-left: 51px;
}

.privateservices_two_layout input,
.privateservices_one_layout input {
  color: #888 !important;
  font-size: 14px;
}

/* -----------------------Email template---------------------------------------------- */

.email_template_content_wrapper .placeholder-buttons button {
  background-color: var(--inner-card-bg-color) !important;
  border-radius: 10px;
  color: var(--h2-color) !important;
  text-transform: none !important;
}

.email_template_content_wrapper .placeholder-buttons button:hover {
  background-color: var(--placeholder-hover-bg-color) !important;
}

.email_template_content_wrapper textarea {
  background-color: var(--inner-card-bg-color) !important;
  color: var(--h2-color) !important;
}

.private-service-activity .error-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  padding: 30px;
}

.private-service-activity .name {
  text-transform: capitalize;
}

.private-service-travel-info .general .travel-info-box {
  height: unset;
}

.general-info.grids-2.general.map .travel-info-box img {
  width: 100%;
  object-fit: cover;
}

.private-service-travel-info {
  max-height: 78vh;
  overflow-y: scroll;
}

.private-service-travel-info::-webkit-scrollbar {
  width: 4px;
}

.private-service-travel-info::-webkit-scrollbar-track {
  background-color: var(--ps-tab-scroll) !important;
}

.private-service-travel-info::-webkit-scrollbar-thumb {
  background: #999;
  transition: 0.2s linear;
}

.private-service-travel-info::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  transition: 0.2s linear;
}

.container-separate {
  margin-top: 20px;
}

.private-service-travel-info .MuiAccordionSummary-root > div,
.private-service-travel-info .MuiAccordionSummary-root > div > p {
  margin: 0;
}

.private-service-travel-info .acc-content {
  margin: 10px;
  padding: 10px;
  background-color: var(--grey-222);
  border-radius: 10px;
}

.private-service-travel-info .tag-row .header {
  width: 100%;
  justify-content: space-between;
}

.private-service-travel-info .tag-row .header .risk-level {
  text-transform: uppercase;
  background-color: #f30f0f;
  padding: 3px 10px;
  border-radius: 30px;
  color: #fff !important;
  font-size: 9px;
  font-weight: 600;
}

.private-service-travel-info .lbl {
  font-weight: 600;
  margin-bottom: 10px;
}

.private-service-activity .custom-label,
.private-service-travel-info .custom-label {
  color: #999 !important;
}

.travel-info-footer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

/* Tooltip CSS Classes */

.nmstooltip-container {
  width: 100%;
  height: 100%;
}

.nmstooltip-title {
  padding: 10px;
}

.nmstooltip-title-text {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.nmstooltip-divider {
  height: 1px;
  background-color: lightgrey;
}

.nmstooltip-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.nmstooltip-item {
  width: 48%;
}

.nmstooltip-caption {
  color: black;
  font-weight: bold;
}

.nmstooltip-description {
  color: grey;
  font-weight: bold;
}

/* Dark Mode Classes */

.nmstooltip-dark-container {
  background-color: #111111;
}

.nmstooltip-dark-title-text {
  color: white;
}

.nmstooltip-dark-divider {
  background-color: #444444;
  /* Darker divider for dark mode */
}

.nmstooltip-dark-caption {
  color: white;
}

.nmstooltip-dark-description {
  color: lightgrey;
}

.ps-content-area .card-content {
  padding: 20px 15px;
}

.ps-content-area .ps-add-template .outer-boxes {
  margin: 15px;
}

.ps-input-field,
.ps-input-field .MuiInputBase-root {
  border-radius: 10px !important;
}

.textfield.ps-input-field {
  margin-top: 0 !important;
}

.ps-input-field .MuiInputBase-root,
.ps-textfield .MuiInputBase-root,
.ps-template-control,
.ps-map-box {
  background-color: var(--inner-card-bg-color) !important;
  border: 2px solid var(--label-border-color) !important;
}

.ps-input-field input,
.ps-input-field textarea {
  background-color: transparent !important;
  font-size: 12px;
}

.ps-input-field label,
.ps-template-control label {
  color: #999 !important;
  font-size: 12px;
}

.ps-input-field fieldset {
  display: none;
}

.ps-switch-content-reports-outer {
  border: unset !important;
  padding: 10px !important;
}

.ps-radio-text-box-outer,
.ps-inner-switch-content {
  border: 2px solid var(--label-border-color) !important;
}

.ps-add-item .outer-item {
  padding: 6px 8px;
}

.ps-add-item .nms-title {
  width: 100% !important;
  justify-content: space-between;
}

.ps-add-item .nms-title .d-flex {
  gap: 10px;
}

.ps-add-item .nms-title .d-flex svg path {
  fill: var(--h2-color);
}

.ps-add-item .card-inner-value {
  background-color: var(--grey-222) !important;
  border: none !important;
  /* padding: 4px; */
  border-radius: 6px;
}

.ps-add-item .search_dropdown input {
  background-color: var(--grey-222) !important;
  border: none !important;
  font-size: 12px;
  border-radius: 6px;
  color: var(--h2-color);
}

.ps-add-item .search_dropdown .options {
  font-size: 12px;
  background-color: var(--grey-111) !important;
  border: 1px solid var(--label-border-color) !important;
}

.ps-add-item .search_dropdown .options .option {
  color: var(--h2-color) !important;
}

.ps-add-item .search_dropdown .option.selected,
.search_dropdown .option:hover {
  background-color: var(--ps-add-item-dropdown) !important;
  color: #999 !important;
}

.ps-template-control,
.ps-map-box {
  padding: 10px !important;
  border-radius: 10px !important;
}

.ps-map-box {
  margin: 5px;
}

.ps-btn-green {
  background-color: #10d48e !important;
  border: none !important;
  color: #0a0a0a !important;
  transition: 0.1s linear;
  font-weight: 600;
  white-space: nowrap;
}

.ps-btn-green:hover {
  transition: 0.1s linear;
  background-color: #099563 !important;
  color: #0a0a0a !important;
}

.ps-btn-grey {
  background-color: #ffffff !important;
  border: none !important;
  color: #0a0a0a !important;
  transition: 0.1s linear;
  font-weight: 600;
  white-space: nowrap;
}

.ps-btn-grey:hover {
  transition: 0.1s linear;
  background-color: #f2f2f2 !important;
  color: #0a0a0a !important;
}

.ps-btn-blue {
  background-color: #00a3da !important;
  border: none !important;
  color: #0a0a0a !important;
  transition: 0.1s linear;
  font-weight: 600;
  white-space: nowrap;
  width: fit-content;
}

.ps-btn-blue:hover {
  transition: 0.1s linear;
  background-color: #007399 !important;
  color: #0a0a0a !important;
}

.age-presc {
  background-color: #6f4b6b;

  padding: 10px 15px;
  border-radius: 10px;
  margin: 15px;
  font-size: 12px;
}

.age-presc .title {
  color: #999 !important;
  display: unset !important;
}

.age-presc .d-flex {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.age-presc .description {
  color: #fff;
}

.about-presc {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  margin: 15px;
}

.about-presc .about {
  border: 2px solid var(--label-border-color);
  padding: 10px;
  border-radius: 10px;
}

.about-presc .about .d-flex {
  gap: 5px;
  align-items: center;
}

.about-presc .about .title {
  color: #999 !important;
  display: unset !important;
  padding: 0 !important;
}

.revert_back_action_button {
  background-color: var(--services-container-footer-bg);
  border-radius: 10px;
  margin-top: 6px;
  padding: 15px 10px;
  height: 25px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.revert_back_action_button :hover {
  z-index: 9999;
}

/* Send to Orbit modal*/
.send_to_orbit_modal_wrapper .price-card p {
  text-align: center !important;
}

.send_to_orbit_modal_wrapper .price-card span {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

.send_to_orbit_modal_wrapper .radio-s-orbit {
  margin-top: 20px;
}

.send_to_orbit_modal_wrapper .custom-pricing,
.space-agent-options {
  padding-left: 20px;
  margin-top: 10px;
}

.send_to_orbit_modal_wrapper .price-card {
  width: 43% I !important;
  display: flex;
  justify-content: space-between;
  margin: 5px !important;
}

.send_to_orbit_modal_wrapper .price-card .price-card-content {
  padding-right: 15px !important;
}

.send_to_orbit_modal_wrapper .spaceAgent_wrapper {
  display: flex;
  justify-content: space-evenly;
}

.send_to_orbit_modal_wrapper .nms-option-orbit.selected {
  border: 1px solid var(--main-heading) !important;
}

.send_to_orbit_modal_wrapper .nms-option-orbit {
  padding: 10px;
  background-color: var(--services-content-bg);
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.send_to_orbit_modal_wrapper .nms-option-orbit:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.send_to_orbit_modal_wrapper .nms-title-orbit {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.send_to_orbit_modal_wrapper .nms-description-orbit {
  width: 57% !important;
  font-size: 14px !important;
  color: #818181 !important;
  margin-top: 5px;
}

.send_to_orbit_modal_wrapper .price-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.send_to_orbit_modal_wrapper .consented-price,
.unconsented-price {
  text-align: center;
  width: 40%;
  background-color: var(--services-content-bg);
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.send_to_orbit_modal_wrapper .consented-price:hover,
.unconsented-price:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.send_to_orbit_modal_wrapper .consented-price.selected,
.unconsented-price.selected {
  border: 1px solid var(--main-heading) !important;
}

.orbit-ag {
  flex: 1 1;
}

.orbit-ag .ag-root-wrapper {
  border-radius: 10px;
}

.orbit-ag .orbit_label_alt {
  margin-bottom: 15px;
  font-size: 16px;
}

.agency_monthly_report {
  padding: 0;
}

.agency_monthly_report-container {
  padding: 12px 16px;
  height: unset !important;
}

.agency_monthlyReport_boxes {
  margin-bottom: unset;
}

.agency_monthly_report .lower {
  margin-top: 50px;
}

.agency_max-height {
  max-height: 100%;
  height: 100%;
}

/* @media screen and (max-width: 1400px){
  .agency_max-height {
    height: unset;
  }

} */

.orbit-ag-grid {
  max-height: 350px;
  overflow-y: auto;
}

.orbit-ag-grid .ag-body {
  margin-bottom: unset;
}

.orbit-ag-grid::-webkit-scrollbar {
  width: 5px !important;
}

.orbit-ag-grid::-webkit-scrollbar-track {
  background: var(--ps-tab-scroll) !important;
}

.orbit-ag-grid::-webkit-scrollbar-thumb {
  background: #eee !important;
  transition: 0.2s linear !important;
}

.orbit-ag-grid::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
  transition: 0.2s linear !important;
}

.orbit-ag .orbit_bottomNote.t-center {
  margin-top: 150px;
}

.orbit_agency-ag {
  --ag-borders: none;
  margin-left: -15px;
  margin-top: -15px;
}

.orbit_agency-ag .ag-row,
.orbit_agency-ag .ag-header,
.orbit_agency-ag .ag-root-wrapper-body {
  background: var(--orbit-box-bg);
  border: none;
}

.orbit_agency-ag .ag-header-cell-text {
  color: #999;
}

.orbit_agency-ag .ag-body-horizontal-scroll-viewport {
  display: none;
}

.orbit_workload .tabs_text_alt {
  text-transform: capitalize;
}

#orbit-tabpanel-0 .MuiBox-root,
#orbit-tabpanel-1 .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

#orbit-tabpanel-0 {
  height: 100%;
}

#orbit-tabpanel-0 .MuiBox-root:first-child {
  height: 100%;
  padding-bottom: 50px;
}

.orbit-agency-work-planner-wrapper {
  height: 100%;
  display: flex;
  align-items: start;
  gap: 20px;
}

.orbit-agency-work-planner-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 15%;
  width: 100%;
}

.orbit-agency-work-planner-info-wrapper .info-wrapper {
  background-color: var(--orbit-box-bg);
  border: 2px solid var(--orbit-box-border);
  width: 100%;
  padding: 5px 5px 10px 5px;
  border-radius: 10px;
}

.orbit-agency-work-planner-info-wrapper .info-wrapper .MuiPaper-root {
  box-shadow: none !important;
}

.orbit-agency-work-planner-info-wrapper .info-wrapper .accordion {
  background-color: transparent !important;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .accordion
  .MuiAccordionSummary-content {
  margin: 0;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .accordion
  .MuiButtonBase-root {
  min-height: 26px !important;
  padding: 0 !important;
  margin-bottom: 5px;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .accordion
  .MuiAccordionDetails-root {
  padding: 0 !important;
}

.orbit-agency-work-planner-info-wrapper .info-wrapper .info-wrapper-label-box {
  display: flex;
  gap: 5px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 10px;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-label-box
  p {
  color: #999;
  margin: 0;
}

.orbit-agency-work-planner-info-wrapper .info-wrapper .info-wrapper-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 3px;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list::-webkit-scrollbar {
  width: 5px !important;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list::-webkit-scrollbar-track {
  background: var(--orbit-scrolltrack) !important;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list::-webkit-scrollbar-thumb {
  background: var(--orbit-scrollthumb) !important;
  transition: 0.2s linear !important;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
  transition: 0.2s linear !important;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list
  .info-wrapper-list-box {
  background-color: var(--info-wrapper-list-bg);
  border-radius: 10px;
  padding: 4px 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  gap: 10px;

  font-size: 14px;
  text-transform: capitalize;
}

.orbit-agency-work-planner-info-wrapper
  .info-wrapper
  .info-wrapper-list
  .info-wrapper-list-box.pharmanauts {
  justify-content: unset;
  align-items: center;
}

.info-wrapper-list-box .avatar-box {
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;

  width: 30px;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-wrapper-list-box .avatar-box > div {
  color: var(--h2-color);
}

.orbit-agency-work-planner-calendar-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  gap: 20px;
  padding-right: 10px;
  width: 100%;
  height: 60vh;
  overflow-y: auto;
}

.orbit-agency-work-planner-calendar-wrapper::-webkit-scrollbar {
  width: 5px !important;
}

.orbit-agency-work-planner-calendar-wrapper::-webkit-scrollbar-track {
  background: var(--orbit-scrolltrack) !important;
}

.orbit-agency-work-planner-calendar-wrapper::-webkit-scrollbar-thumb {
  background: var(--orbit-scrollthumb) !important;
  transition: 0.2s linear !important;
}

.orbit-agency-work-planner-calendar-wrapper::-webkit-scrollbar-thumb:hover {
  background: #aaa !important;
  transition: 0.2s linear !important;
}

.orbit-agency-work-planner-calendar-wrapper .orbit-no-data-note {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
}

.orbit-agency-work-planner-calendar-wrapper .t-center {
  color: #999;
  font-size: 10px;
}

.orbit-agency-work-planner-date-box {
  padding: 10px;
  border: 1px solid #606674;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  min-height: 180px;
}

.orbit-agency-work-planner-date-box .date {
  background-color: unset !important;
  border-radius: unset !important;
  padding: 0;
  font-size: 14px;
  color: #999;
}

.orbit-agency-work-planner-date-box .day {
  font-size: 12px;
  color: #999;
}

.orbit-agency-work-planner-date-box p {
  margin: 0;
}

.orbit-agency-work-planner-calendar-wrapper .services-wrapper {
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.orbit-agency-work-planner-calendar-wrapper .services-wrapper .service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff5a5a;
  padding: 5px;
  border-radius: 4px;
}

.orbit-agency-work-planner-calendar-wrapper .services-wrapper .service .name {
  font-size: 10px;
}

.orbit-agency-work-planner-calendar-wrapper .pharmanauts-wrapper {
  display: flex;
  gap: 10px;
}

.orbit-agency-work-planner-calendar-wrapper .pharmanauts-wrapper .pharmanaut {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;

  font-size: 9px;
  overflow: hidden;
}

.orbit-agency-work-planner-calendar-wrapper
  .pharmanauts-wrapper
  .pharmanaut
  img {
  object-fit: cover;
}

.pharmanauts-daydate-wrapper {
  gap: 0 !important;
  transition: 0.2s linear;
}

.info-wrapper-list-box.pharmanauts.pharmanaut-daydate {
  position: relative;
  margin-right: -5px;
  transition: 0.2s linear;
}

.pharmanaut-name {
  display: none;
  position: absolute;
  top: 30px;
  left: -10px;
  width: fit-content;
  text-wrap: nowrap;
  background-color: var(--global-bg-color);
  padding: 5px;
  border-radius: 3px;
  font-size: 9px;
}

.pharmanauts-daydate-wrapper:hover
  .info-wrapper-list-box.pharmanauts.pharmanaut-daydate {
  transition: 0.2s linear;
  margin-right: 3px;
}

.pharmanaut-daydate .avatar-box {
  background: var(--wp-pharmanaut-inbox-bg);
  cursor: default;
}

.pharmanaut-daydate .avatar-box:hover .pharmanaut-name {
  display: block;
}

.pharmanaut-length {
  background: var(--wp-pharmanaut-inbox-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;

  font-size: 12px;
  overflow: hidden;
}

.defaultGridHeaderCol {
  display: none !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

@media screen and (max-width: 1400px) {
  .orbit-agency-work-planner-info-wrapper {
    max-width: 20%;
  }

  .orbit-agency-work-planner-calendar-wrapper {
    grid-template-columns: repeat(3, 1fr);
    height: 65vh;
  }

  .orbit-agency-work-planner-info-wrapper .info-wrapper .info-wrapper-list {
    max-height: 120px;
  }
}

@media screen and (max-width: 800px) {
  .orbit-agency-work-planner-wrapper {
    flex-direction: column;
  }

  .orbit-agency-work-planner-info-wrapper {
    max-width: 100%;
  }
}

.ordering-tooltip-wrapper {
  background-color: var(--order-tooltip-bg);
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  position: absolute;
  top: -220px;
  right: 30px;
  z-index: 1000 !important;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.ordering-tooltip-wrapper-question {
  background-color: var(--order-tooltip-bg);
  min-width: 300px;
  max-width: 300px;
  position: absolute;
  top: -220px;
  right: 30px;
  z-index: 1000 !important;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  height: 500px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
}


.ordering-tooltip-wrapper::before {
  content: "";
  position: absolute;
  bottom: 255px;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--order-tooltip-bg) transparent transparent transparent;
  transform: rotate(270deg);
}

.ordering-tooltip-wrapper-question::before {
  content: "";
  position: absolute;
  bottom: 255px;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--order-tooltip-bg) transparent transparent transparent;
  transform: rotate(270deg);
}

.ordering-tooltip-loader {
  background-color: var(--order-tooltip-bg);
  width: 200px;
  height: 200px;
  position: absolute;
  top: -100px;
  right: 30px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}



.ordering-tooltip-loader::before {
  content: "";
  position: absolute;
  bottom: 80px;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--order-tooltip-bg) transparent transparent transparent;
  transform: rotate(270deg);
}

.ordering-tooltip-loader .oval-loading svg {
  width: 100px;
  height: 100px;
}

.ordering-tooltip-wrapper p {
  margin: 0;
}

.ordering-tooltip-wrapper-question p {
  margin: 0;
}

.ordering-tooltip-icon {
  cursor: pointer;
  /* color: #b9b9b98f; */
}

.ordering-tooltip-header h5 {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}
.ordering-tooltip-Flavourheader h5
{
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.ordering-tooltip-Flavourheader 
{
 display:flex;
gap:15px;
}

.ordering-tooltip-Flavourheader p
{
  margin: 0;
  color: #888;
  font-weight: 100;
  font-size: 12px;
}
.ordering-tooltip-header p {
  margin: 0;
  color: #888;
  font-weight: 100;
  font-size: 12px;
}

.ordering-tooltip-wrapper hr {
  border-color: #888;
}

.ordering-tooltip-wrapper-question hr {
  border-color: #888;
}

.ordering-tooltip-order-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.ordering-tooltip-order-details .label,
.ordering-tooltip-brand-details .label {
  color: var(--h2-color);
  font-size: 12px;
}

.ordering-tooltip-order-details .value,
.trolley-detail .value {
  color: #888;
}

.ordering-tooltip-wrapper .dropdown {
  width: 100%;
  margin-top: 5px;
}

.ordering-tooltip-wrapper-question .dropdown {
  width: 100%;
  margin-top: 5px;
}

.ordering-tooltip-wrapper .dropdown:focus-visible {
  outline: none !important;
}

.ordering-tooltip-wrapper-question .dropdown:focus-visible {
  outline: none !important;
}

.ordering-tooltip-wrapper .dropdown .p-dropdown-label {
  color: #888 !important;
  font-size: 12px !important;
}

.ordering-tooltip-wrapper-question .dropdown .p-dropdown-label {
  color: #888 !important;
  font-size: 12px !important;
}

.ordering-tooltip-wrapper .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.ordering-tooltip-wrapper-question .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-dropdown-panel {
  font-family: "SF-Pro Regular", sans-serif !important;
  font-size: 12px !important;
  border: 0 !important;
}

.ordering-tooltip-wrapper .checkbox {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.ordering-tooltip-wrapper-question .checkbox {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.ordering-tooltip-wrapper .checkbox label {
  color: #888;
  font-size: 12px;
}

.ordering-tooltip-wrapper-question .checkbox label {
  color: #888;
  font-size: 12px;
}

.ordering-tooltip-wrapper .input {
  margin-top: 5px;
}

.ordering-tooltip-wrapper-question .input {
  margin-top: 5px;
}

.ordering-tooltip-wrapper .p-checkbox .p-checkbox-box {
  border: var(--modal-border-outline) !important;
}

.ordering-tooltip-wrapper-question .p-checkbox .p-checkbox-box {
  border: var(--modal-border-outline) !important;
}

/* .ordering-tooltip-wrapper .p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--global-bg-color) !important;
} */

.ordering-tooltip-wrapper
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.ordering-tooltip-wrapper-question
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.trolley-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.trolley-detail .d-flex {
  justify-content: space-between;
}

.ordering-tooltip-brand-details .d-flex {
  margin-top: 10px;
  gap: 5px;
}

.ordering-tooltip-brand-details-question .d-flex {
  margin-top: 10px;
  gap: 25px;
}


.ordering-tooltip-brand-details .d-flex .p-inputtext {
  width: 100%;
}

.ordering-tooltip-brand-details-question .d-flex .p-inputtext {
  width: 100%;
}

.ordering-tooltip-brand-details .ot-input {
  height: 36px;
  margin-top: 5px;
  border-radius: 8px;
  border: var(--modal-border-outline) !important;
}

.ordering-tooltip-brand-details-question .ot-input {
  height: 36px;
  margin-top: 5px;
  border-radius: 8px;
  border: var(--modal-border-outline) !important;
}

.ordering-tooltip-brand-details .ot-input:enabled:focus {
  box-shadow: none !important;
}

.ordering-tooltip-brand-details-question .ot-input:enabled:focus {
  box-shadow: none !important;
}

.ordering-tooltip-action-btn {
  width: 100%;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  background-color: #2d74ff;
  border-radius: 8px;
  margin-top: 20px;
  transition: 0.2s linear;
}

.ordering-tooltip-action-btn:hover {
  cursor: pointer;
  transition: 0.2s linear;
  background-color: #2464e3;
}

.order-basket-icon .attr {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  left: -4px;
  top: 0px;
}
.order-basket-icon {
  position: relative;
}

.bucket-dropdown-class .p-dropdown-panel {
  font-family: "SF-Pro Regular", sans-serif !important;
}

.bucket-dropdown-class .p-dropdown-label {
  color: var(--h2-color) !important;
  font-size: 14px;
}

.bucket-dropdown-class .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

/* ---------------------Consultation page--------------------------------------------------------------- */

.user_details_main_wrapper {
  background-color: var(--bg-clr) !important;
  overflow: auto;
  padding: 0 15px;
  height: 100%;
  border-radius: 20px;
  /* margin: 30px; */
  /* border-radius: 20px; */
  /* border: 1px solid #888; */
  /* box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 31%); */
}

.user_details_main_wrapper .login_button {
  text-align: center !important;
  background-color: #222;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 20px;
}

.user_details_main_wrapper .login_button:hover {
  background-color: #000;
}

.user_details_main_wrapper .MuiOutlinedInput-root {
  background: var(--bg-clr) !important;
  border-radius: 10px !important;
}

.user_control_wrapper {
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 31%);
  background-color: var(--services-content-bg);
  border-radius: 10px;
}

.user_control_wrapper .MuiFormGroup-root {
  margin-left: 15px !important;
}

.user_control_wrapper input {
  background: var(--bg-clr) !important;
}

.user_control_wrapper label {
  color: var(--h2-color) !important;
}

.user_control_wrapper .switch-content-reports-outer {
  width: 100%;
  border-radius: 11px;
  background: var(--bg-clr) !important;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.consultation_header {
  display: flex;
  justify-content: end;
}

.consultation_header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.close_button {
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: var(--h2-color) !important;
  cursor: pointer;
  outline: none;
  width: 35px;
  border-radius: 6px;
}

.close_button:hover {
  background: var(--services-content-bg);
}

.patient-consultation-wrapper {
  background: var(--consultationPage-bg-color) !important;
}

/* For small screens (mobile devices) */
@media (max-width: 767px) {
  .user_details_main_wrapper {
    padding: 2px !important;
  }

  .form-group {
    text-align: start !important;
  }

  .user_control_wrapper .switch-content-reports-outer {
    padding: 10px 8px !important;
  }

  .user_control_wrapper {
    padding: 5px !important;
  }
}

.travel-info-switch {
  background-color: var(--timeline-notes-content) !important;
  border: 2px solid var(--timeline-notes-content-border) !important;
}

.travel-info-switch {
  width: 100%;
  border-radius: 11px;
  height: 53px;
  padding: 6px 15px;
  display: flex;
  grid-column: span 2;
  align-items: center;
  justify-content: space-between;
}

.add_vaccine_icon {
  color: var(--h2-color) !important;
}

.infinite-scroll-component {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.parent_template:last-child {
  border: none !important;
}
.bucket-dropdown-bg-image-cc-header-today {
  background-color: #2d74ff !important;
}
.bucket-dropdown-bg-image-cc-header-waiting {
  background-color: #eeb416 !important;
}

.form_fields .p-checkbox-box > svg > path
{
  fill:black !important;
}